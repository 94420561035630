import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

import maintanence from "../../assets/images/coming-soon.svg"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
import * as moment from 'moment';

import { Button, Card, CardBody, Badge,Row,Col,Container } from "reactstrap"

import {
  getOrders,
  addNewOrder,
  updateOrder,
  deleteOrder
} from "store/actions"

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

class TodayReturns extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currency:"",
      viewmodal: false,
      modal: false,
      reservations: [],
      ReservationColumns: [

        /* {
          dataField: "code",
          text: this.props.t("Code"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className={!row.r_infos && row.r_infos !== undefined && row.status === "Done" || !row.paid ? "text-danger fw-bold" : "text-body fw-bold"}>

              {row.code}
            </Link>
          ),
        }, */
        {
          dataField: "vehicle",
          text: this.props.t("Vehicle"),
          sort: true,
        },

        /* {
          dataField: "pickup_date",
          text: this.props.t("Pickup date"),
          sort: true,
        },*/
        {
          dataField: "return_date",
          text: this.props.t("Return date"),
          sort: true,
        }, 
        
        {
          dataField: "client",
          text: this.props.t("Client"),
          sort: true,

        },
        {
          dataField: "days",
          text: this.props.t("Days"),
          sort: true,

        },
        {
          dataField: "total_amount",
          text: this.props.t("Total amount"),
          sort: true,
          formatter: (cellContent, row) => (
            <>

              {" "}
              {row.total_amount} {this.state.currency || ""}
            </>
          ),
        },
       /*  {
          dataField: "status",
          isDummyField: true,
          text: this.props.t("Status"),
          sort: true,
          formatter: (cellContent, row) => (

            <Badge
              className={(row.status === 'Done' || row.status === 'Created') ? "font-size-12 badge-soft-success" : (row.status === "Rental") ? "font-size-12 badge-soft-warning" : "font-size-12 badge-soft-danger"}
              color={row.status}
              pill
            >
              {row.status}
            </Badge>
          ),
        }, */

       
      ]
    }
    this.toggle = this.toggle.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
  }

  toLowerCase1(str) {
    return str.toLowerCase();
  }

  componentDidMount() {
    this.getReservation()
    this.getCurrency()
  }
async getCurrency() {
    try {
      this.setState({ p_loaded: false })
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const userId = user.uid;
          // Reference to the user document
          const userDocRef = firebase.firestore().collection("users").doc(userId);

          // Fetch the user document
          const userSnapshot = await userDocRef.get();

          if (!userSnapshot.exists) {
            /* this.setState({ p_loaded: true })
           //console..log("User document does not exist."); */
            return null; // Return null if user document doesn't exist
          }

          const userData = userSnapshot.data();

          // Check if the user is an admin
          if (userData.isAdmin) {
            /* this.setState({ p_loaded: true }) */
            //console.log("User is an admin; permissions are not restricted.");
            return null; // Return null or handle differently for admins
          } else {
            const userD = await firebase.firestore().collection("users").doc(userData.id).get();
           ////console..log(userD.data())
            this.setState({ currency: userD.data().currency })
          }


        } else {/* 
            this.setState({ p_loaded: true })
           //console..log("Not authenthicated") */
        }
      })

    } catch (error) {/* 
        this.setState({ p_loaded: true })
       //console..error("Error fetching client permissions:", error); */
      throw error;
    }
  }
  getReservation = async () => {

    this.setState({ loading: true });
    const branch = JSON.parse(localStorage.getItem("branch"));
  
    if (!branch) {
     //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
    
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            this.setState({ currency: userDoc.data().currency })
            const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations").where("branchId","==",branch)
            var CurrentDate = moment().unix()
            var lyoum=moment.unix(CurrentDate).format("DD/MM/YYYY");
          
            this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
              let kra = []
              var kkk=""
              snapshot.docs.forEach(e => {
                kkk=moment.unix(e.data().rd/1000).format("DD/MM/YYYY");
                if(kkk===lyoum && e.data().status!=="canceled"){
                  //console.log('ghassa')
                  kra.push(e.data())
                }
                
              });

              this.setState({
                loading: false,
                reservations: kra,
              })
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              reservations: []
            });
          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          reservations: []
        });
      }
    });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }


  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }
  render() {
    const { currency,reservations } = this.state

    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: reservations.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }];
    const emptyDataMessage = () => {
      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    {/* <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div> */}
                  </Col>
                </Row>
                <h4 className="mb-2">{this.props.t("No Returns today")}</h4>
                <p className="text-muted">
                  {/* {this.props.t("Start adding your reservations by clicking ")}<h5 className="text-primary"><Link onClick={this.handleReservationClicks} >{this.props.t("New Reservation")}</Link></h5> */}
                </p>
              </div>
            }
          </Col>
        </Row>
      </Container>


        ;
    }
    

    return (
      <React.Fragment>
        <EcommerceOrdersModal
          isOpen={this.state.viewmodal}
          toggle={this.toggleViewModal}
        />
        <Card>
          <CardBody>
            <div className="mb-4 h4 card-title">{this.props.t("Today Returns")}</div>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={this.state.ReservationColumns}
              data={reservations}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={reservations}
                  columns={this.state.ReservationColumns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          responsive
                          noDataIndication={emptyDataMessage}
                          defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                      
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

TodayReturns.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  orders: state.ecommerce.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getOrders()),
  onAddNewOrder: order => dispatch(addNewOrder(order)),
  onUpdateOrder: order => dispatch(updateOrder(order)),
  onDeleteOrder: order => dispatch(deleteOrder(order)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(TodayReturns)))