import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, CardTitle, Label, FormGroup } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
//Import Vertical list
import LiVerticalTimeline from "./li-vertical-timeline"
import moment from "moment";
import noRequests from "../../assets/images/error-img.png"
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types'

class PagesTimeline extends Component {
  constructor() {
    super();
    this.state = {
      branch: "",
      selectedUserName: "All users",
      selectedUser: 1,
      users: [],
      isAdmin: false,
      timeline: [],
      selectedOption: "all",
      selectedDate: new Date(),
      responsive: {
        576: {
          items: 2,
        },
        768: {
          items: 3,
        },
        992: {
          items: 4,
        },
      },

      step1: true,
      step2: false,

    }
    this.onValueChange = this.onValueChange.bind(this)
  }
  componentDidMount() {
    const { timeline } = this.state
    /* const branch = JSON.parse(localStorage.getItem("branch"));
   //console..log(localStorage.getItem("branch"))
   //console..log(JSON.parse(localStorage.getItem("branch")))
    if (branch !== undefined && branch !== "") {
     //console..log("Active branch:", branch);
      this.setState({ branch: branch })
    } else {
     //console..log("No active branch found.");
      //this.getBranch()
    } */
    //this.getBranch()
    if (timeline && !timeline.length) {
      //onGetStaff()
      this.getTimeline()

    }
    this.getUsers()
    this.setState({ timeline })

    // this.migrateToBranches().then(() =>//console..log('Migration complete!')).catch((error) =>//console..log('error : ', error));
  }

  /* migrateToBranches = async () => {
    const usersSnapshot = await firebase.firestore().collection("users").get();

    for (const userDoc of usersSnapshot.docs) {
      const userId = userDoc.id;

      // Step 1: Get the branches collection for the user
      const branchesSnapshot = await userDoc.ref.collection('branches').where('id', '==', 'branches').get();

      // Step 2: Delete each branch document that matches the condition
      branchesSnapshot.forEach(async (branchDoc) => {
        await branchDoc.ref.delete();
       //console..log(`Deleted branch with ID: ${branchDoc.id} for user: ${userId}`);
      });
    }

   //console..log('Deletion complete!');
  }; */


  getUsers = async () => {


    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeStaffs) {
      this.unsubscribeStaffs();
    }

    this.unsubscribeStaffs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userStaffsCollectionRef = usersCollection.doc(userId).collection("staffs");
            if (userDoc.data().isAdmin) {
              this.setState({
                isAdmin: true
              })
              this.unsubscribeStaffs = userStaffsCollectionRef.onSnapshot(snapshot => {
                let t = [];
                snapshot.docs.forEach(doc => {
                  if (doc.data().isUser) {
                    t.push(doc.data());
                  }

                });

                this.setState({
                  users: t,
                  isAdmin: true
                });
              });
            } else {
              this.setState({
                isAdmin: false,

              })
            }


          } else {
            this.setState({
              users: []
            });
          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        this.setState({
          users: []
        });
      }
    });
  }

  getTimeline = async () => {
    this.setState({ loading: true });
    const branch = JSON.parse(localStorage.getItem("branch"));

    if (branch !== undefined && branch !== "") {
      //console.log("Active branch:", branch);
      // Unsubscribe previous listeners to prevent memory leaks
      if (this.unsubscribeTimeline) {
        this.unsubscribeTimeline();
      }

      this.unsubscribeTimeline = firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const userId = user.uid;
          const usersCollection = firebase.firestore().collection("users")
          const userDocRef = usersCollection.doc(userId);

          try {
            const userDoc = await userDocRef.get();
            if (userDoc.exists) {
              const userId = userDoc.data().id;
              const userTimelineCollectionRef = usersCollection.doc(userId).collection("timeline").where("branchId", "==", branch).orderBy("time", "desc");

              this.unsubscribeTimeline = userTimelineCollectionRef.onSnapshot(snapshot => {
                let n = [];
                snapshot.docs.forEach(doc => {
                  n.push(doc.data());
                });

                this.setState({
                  loading: false,
                  timeline: n
                });
              });

              //localStorage.setItem("authUser", JSON.stringify(user));
            } else {
              // User document not found
              localStorage.removeItem("authUser");

              this.setState({
                loading: false,
                timeline: []
              });

            }
          } catch (error) {
           //console..error("Error fetching user document:", error);
            // Handle error
          }
        } else {
          localStorage.removeItem("authUser");
          // Clear the reservations if user is not authenticated
          this.setState({
            loading: false,
            timeline: []
          });
        }
      });
    } else {
     //console..log("No active branch found.");
      //this.getBranch()
    }


  }

  getBranch = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeBranch) {
      this.unsubscribeBranch();
    }

    this.unsubscribeBranch = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users");
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userBranchCollectionRef = usersCollection
              .doc(userId)
              .collection("branches")
              .where("isActive", "==", true);

            this.unsubscribeBranch = userBranchCollectionRef.onSnapshot(snapshot => {
              if (!snapshot.empty) {
                // Fetch the first branch document
                const branchDoc = snapshot.docs[0].data();

                // Set branch in both state and localStorage
                this.setState({
                  loading: false,
                  branch: branchDoc
                });

                localStorage.setItem("branch", JSON.stringify(branchDoc)); // Save branch to localStorage
              } else {
                this.setState({
                  loading: false,
                  branch: null
                });

                localStorage.removeItem("branch"); // Remove branch if no active branches found
              }
            });

            //localStorage.setItem("authUser", JSON.stringify(user)); // Save user to localStorage
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            localStorage.removeItem("branch");

            this.setState({
              loading: false
            });
          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        // Clear localStorage and state if user is not authenticated
        localStorage.removeItem("authUser");
        localStorage.removeItem("branch");

        this.setState({
          loading: false
        });
      }
    });
  };


  handleSelectUser = (selectedUser, value) => {
    const { users } = this.state
    /*  let kra = selectedVehicle.target.value
     let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")")) */
    //console.log(value)
    if (value === 'All users') {
      this.setState({

        selectedUserName: "All users",
        selectedUser: 1
      })
    } else {
      //console.log(value)
      this.setState({
        selectedUser: value
      })
    }

  }
  onValueChange(event) {
    const { timeline } = this.state
    this.setState({
      selectedOption: event.target.id
    });
    //console.log(event.target.id)


  }
  dateChanged = dt => {

    // //console.log(dt.target.value)
    this.setState({
      selectedOption: "dt",
      selectedDate: dt.target.value
    });
  }

  render() {
    const { branch, selectedUser, isAdmin, users, timeline, selectedOption, selectedDate } = this.state
    /*  timeline.sort(function (a, b) {
       return b.time - a.time;
     }); */
    //console.log(branch)
    var usList = []
    usList.push({ label: "All users", value: "All" })
    usList.push({ label: "Admin", value: "Admin" })
    users.map(function (item, i) {

      usList.push({ id: item.first_name + " " + item.last_name, label: item.first_name + " " + item.last_name, value: item.first_name + " " + item.last_name })

    })
    var l = []
    timeline.map(function (item) {
      l.push(item)
    });
    //console.log(selectedOption)
    var tl = []
    switch (selectedOption) {
      case 'all':
        var l = []
        timeline.map(function (item) {
          if (selectedUser === 1) {
            l.push(item)
          }
          if (item.user === selectedUser) {
            l.push(item)
          }
        });

        tl = l
        break;
      case 'today':

        var l = []
        timeline.map(function (item) {
          var sd = moment(new Date()).format('MM-DD-YYYY')
          var ds = moment(item.time).format("MM-DD-YYYY");
          var t = moment(ds)
          var s = moment(sd)
          if (s.isSame(t, 'day') && selectedUser === 1) {
            l.push(item)
          }
          if (s.isSame(t, 'day') && item.user === selectedUser) {
            l.push(item)
          }
        });


        tl = l

        break;
      case 'lw':
        var l = []
        timeline.map(function (item) {
          if (moment(new Date().getTime()).isSame(moment(item.time), 'week') && selectedUser === 1) {
            l.push(item)
          }
          if (moment(new Date().getTime()).isSame(moment(item.time), 'week') && item.user === selectedUser) {
            l.push(item)
          }
        });

        tl = l
        break;
      case 'lm':
        var l = []
        timeline.map(function (item) {
          if (moment(new Date().getTime()).isSame(moment(item.time), 'month') && selectedUser === 1) {
            l.push(item)
          }
          if (moment(new Date().getTime()).isSame(moment(item.time), 'month') && item.user === selectedUser) {
            l.push(item)
          }
        });

        tl = l
        break;
      case 'dt':
        var l = []

        timeline.map(function (item) {
          var sd = moment(selectedDate).format('MM-DD-YYYY')
          var ds = moment(item.time).format("MM-DD-YYYY");
          var t = moment(ds)
          var s = moment(sd)
          if (s.isSame(t, 'day') && selectedUser === 1) {
            l.push(item)
          }
          if (s.isSame(t, 'day') && item.user === selectedUser) {
            l.push(item)
          }
        });
        tl = l
        break;
    }

    /* timeline.sort(function(a,b){
      return b.time - a.time;
    }); */
    //console.log(tl)
    // //console.log(timeline)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("َActivity | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <div className="container-fluid">
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Administration")} breadcrumbItem={this.props.t("Activity")} />

            <Row>
              <Col lg="12">


                <Card>
                  <CardBody>

                    <Row className="mb-4" >
                      <Col dm="3">
                        <div className="mb-3 row">

                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="date"
                              onChange={this.dateChanged}
                              id="example-date-input"
                            />
                          </div>
                        </div>
                      </Col>
                      {isAdmin ? <Col dm="3">
                        <AvForm>
                          <AvField
                            name="title"
                            type="select"
                            onChange={this.handleSelectUser}
                            validate={{
                              required: { value: true },
                            }}

                          >
                            {usList.map((e, key) => {

                              return <option key={key} value={e.id}>{e.label}</option>;

                            })}</AvField>
                        </AvForm>
                      </Col> : null}

                      <Col dm="6" >

                        <div className="text-sm-end" >
                          <Col   >
                            <div className="btn-group " role="group" aria-label="Basic radio toggle button group" onChange={this.onChangeValue}>
                              <input type="radio" className="btn-check" name="btnradio" id="all" onClick={this.onValueChange} autoComplete="off" defaultChecked />
                              <label className="btn btn-primary" htmlFor="all">{this.props.t("All")}</label>

                              <input type="radio" className="btn-check" name="btnradio" id="today" onClick={this.onValueChange} autoComplete="off" />
                              <label className="btn btn-primary" htmlFor="today">{this.props.t("Today")}</label>

                              <input type="radio" className="btn-check" name="btnradio" id="lw" onClick={this.onValueChange} autoComplete="off" />
                              <label className="btn btn-primary" htmlFor="lw">{this.props.t("This week")}</label>

                              <input type="radio" className="btn-check" name="btnradio" id="lm" onClick={this.onValueChange} autoComplete="off" />
                              <label className="btn btn-primary" htmlFor="lm">{this.props.t("This month")}</label>


                            </div>

                          </Col>

                        </div>
                      </Col>
                    </Row>
                    <div className="">
                      <ul className="verti-timeline list-unstyled">
                        {/* Render Horizontal Timeline Events */}

                        {tl.length == 0 ?
                          <div className="text-center">
                            <Row className="justify-content-center mt-5">
                              <Col sm="4">
                                <div className="maintenance-img">
                                  <img
                                    src={noRequests}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <h4 className="mt-5">{this.props.t("Nothing to show!")}</h4>
                            <p className="text-muted">
                              {this.props.t("You can find recent events here")} <h5 className="text-primary"></h5>
                            </p>
                          </div>
                          : tl.map((tl, key) => (

                            <LiVerticalTimeline
                              status={tl}
                              key={"_status_" + key}
                            />
                          ))}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
PagesTimeline.propTypes = {
  t: PropTypes.any
}


export default withTranslation()(PagesTimeline);