import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, ButtonGroup, Button } from "reactstrap"; 

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types'
// Import Pricing Cards
import CardPricing from "./card-pricing";

class PagesPricing extends Component {
  state = {
    pricings: [{
      id: 4,
      title: "LifeTime Deal",
      description: "One-Time Payment",
      icon: "bx-gift",
      price: { oneTime: "997" }, // Lifetime Deal has only one price
      priceId: { oneTime:"price_1QoNPxGFbCpQEhRT8pPUpwbE" /* "price_1QoKFsGFbCpQEhRTHIDAqZ5w" */ }, // Lifetime Deal price ID
      duration: "(One-time Payment)",
      link: "",
      features: [
        { title: "5 users" },
        { title: "30 vehicles" },
        { title: "3 branches" },
        { title: "Reservation Management" },
        { title: "Client Management" },
        { title: "Maintenance Scheduling" },
        { title: "Rental Calendar" },
        { title: "Vehicle Availability Checker" },
        { title: "Priority Technical Support" },
      ],
      isLifetime: true, // Add a flag for the Lifetime Deal
    },
      {
        id: 1,
        title: "Starter",
        description: "For Small Businesses",
        icon: "bx-rocket",
        price: { monthly: "29", annual: "299" },
        priceId: { monthly: "price_1QoNLuGFbCpQEhRT16ld1V0Y" /* "price_1QoKCRGFbCpQEhRTHq8mPI5j" */, annual: "price_1QoNLuGFbCpQEhRTo1w6doyB"/* "price_1QoKCRGFbCpQEhRTLiCBwSmI" */ },
        duration: "Per month",
        link: "",
        features: [
          { title: "2 users" },
          { title: "10 vehicles" },
          { title: "1 branch" },
          { title: "Reservation Management" },
          { title: "Client Management" },
          { title: "Maintenance Scheduling" },
          { title: "Rental Calendar" },
          { title: "Vehicle Availability Checker" },
          { title: "Standard Technical Support" },
        ],
      },
      {
        id: 2,
        title: "Professional",
        description: "For Growing Teams",
        icon: "bx-briefcase",
        price: { monthly: "79", annual: "799" },
        priceId: { monthly:"price_1QoNNVGFbCpQEhRTn1khi0aa" /* "price_1QoKDlGFbCpQEhRTYBXFeCed" */, annual:"price_1QoNNVGFbCpQEhRTvmg7sy5O" /* "price_1QoKDlGFbCpQEhRTUxZY8rJL" */ },
        duration: "Per month",
        link: "",
        features: [
          { title: "5 users" },
          { title: "30 vehicles" },
          { title: "3 branches" },
          { title: "Reservation Management" },
          { title: "Client Management" },
          { title: "Maintenance Scheduling" },
          { title: "Rental Calendar" },
          { title: "Vehicle Availability Checker" },
          { title: "Priority Technical Support" },
        ],
      },
      {
        id: 3,
        title: "Unlimited",
        description: "For Large Enterprises",
        icon: "bx-infinite",
        price: { monthly: "149", annual: "1499" },
        priceId: { monthly: "price_1QoNOqGFbCpQEhRTkiWwF3wZ"/* "price_1QoKEgGFbCpQEhRTfpvRHdu9" */, annual: "price_1QoNOqGFbCpQEhRTddXhmILJ" /* "price_1QoKEgGFbCpQEhRTjPT4MaOn" */ },
        duration: "Per month",
        link: "",
        features: [
          { title: "Unlimited users" },
          { title: "Unlimited vehicles" },
          { title: "Unlimited branches" },
          { title: "Reservation Management" },
          { title: "Client Management" },
          { title: "Maintenance Scheduling" },
          { title: "Rental Calendar" },
          { title: "Vehicle Availability Checker" },
          { title: "Priority Technical Support" },
        ],
      }
      
    ],
    billingCycle: "annual", // Default billing cycle is annual
  };

  // Function to switch billing cycle
  handleBillingCycleChange = (cycle) => {
    this.setState({ billingCycle: cycle });
  };

  render() {
    const {loadingUrl, pricings, billingCycle } = this.state;

    return (
      <React.Fragment>
        
        <div className="page-content">
          <MetaTags>
            <title>Pricing | Medios - Car Rental Management System</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Upgrade")} breadcrumbItem={this.props.t("Pricing")} />

            <Row className="justify-content-center">
              <div className="text-center mb-4">
                <h4>{this.props.t("Choose your Pricing plan")}</h4>
                <p className="text-muted mt-4">
                  {this.props.t("Whether you are a small agency or a growing enterprise, we have flexible options to help you manage your fleet, reservations, and more with ease.")}
                   <br />{this.props.t("Upgrade or downgrade anytime as your business evolves.")}
                </p>

                {/* Billing Cycle Toggle */}
                <ButtonGroup className="mt-3">
                  <Button
                    color={billingCycle === "monthly" ? "primary" : "light"}
                    onClick={() => this.handleBillingCycleChange("monthly")}
                  >
                    {this.props.t("Monthly")}
                  </Button>
                  <Button
                    color={billingCycle === "annual" ? "primary" : "light"}
                    onClick={() => this.handleBillingCycleChange("annual")}
                  >
                    {this.props.t("Annual (Two months FREE)")}
                  </Button>
                </ButtonGroup>
              </div>
            </Row>

            <Row>
              {/* Render pricing cards */}
              {pricings.map((pricing, key) => (
                <CardPricing
                  pricing={pricing}
                  billingCycle={billingCycle}
                  key={"_pricing_" + key}
                />
              ))}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

PagesPricing.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(PagesPricing);