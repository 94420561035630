import React, { Component } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux"
//import { Link } from "react-router-dom"
import axios from "axios";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

/* import FloatingWhatsApp from 'react-floating-whatsapp'
import WhatsappIcon from "@material-ui/icons/WhatsApp" */

import { Fab, Action } from 'react-tiny-fab';
import style from 'react-tiny-fab/dist/styles.css';


import MessengerCustomerChat from 'react-messenger-customer-chat';
import { withTranslation } from "react-i18next"
// layouts
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { Modal, Input, ModalBody } from "reactstrap"
// Import scss
import "./assets/scss/theme.scss"
import qr from "../src/assets/images/qr.png"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"
import { Container, Button } from 'react-floating-action-button'
import ReactGA from 'react-ga4';
ReactGA.initialize('G-L59SMK92W8');
// Import fackbackend Configuration file
//import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
//fakeBackend()

// Activating fake firebase
const firebaseConfig = {
  apiKey: "AIzaSyDnrzKjhTlUkzLhA9UQ4M7YPgEdFC5dVj8",
  authDomain: "meanz-3960c.firebaseapp.com",
  databaseURL: "https://meanz-3960c-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "meanz-3960c",
  storageBucket: "meanz-3960c.appspot.com",
  messagingSenderId: "872960828688",
  appId: "1:872960828688:web:08eba134443f69d81bb195",
  measurementId: "G-L59SMK92W8",
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingUrl: false,
      reservations: [],
      vehicles: [],
      subscribed: true,
      subscribemodal: true,
      user: [],
      isAdmin: false,
      current: null,
      country: ""
    }
    this.getLayout = this.getLayout.bind(this)
  }


  /* componentDidMount() {
   const { reservations } = this.state
  
   this.subscribed()
   this.getBranch();
   ReactGA.send({
     hitType: "pageview",
     page: window.location.pathname,
   });
   const script = document.createElement('script');
   script.src = "https://www.googletagmanager.com/gtag/js?id=AW-961565114";
   script.async = true;
   document.head.appendChild(script);
   // this.getVehicles()
   window.$crisp = [];
   window.CRISP_WEBSITE_ID = "ffa3c113-0fc3-466a-87ea-839dc0b8678a";
   (function () {
     var d = document;
     var s = d.createElement("script");

     s.src = "https://client.crisp.chat/l.js";
     s.async = 1;
     d.getElementsByTagName("head")[0].appendChild(s);
   })();

   (function () {
     var d = document;
     var s = d.createElement("script");

     s.src = "https://s.skimresources.com/js/233964X1716622.skimlinks.js";
     s.async = 1;
     d.getElementsByTagName("head")[0].appendChild(s);
   })();

   window.scrollTo(0, 0)

 } */
  componentDidMount() {
    this.setState({ loading: true });

    Promise.all([this.subscribed(), this.getBranch()]).then(() => {
      this.setState({ loading: false });
    });

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });

    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-961565114";
    script.async = true;
    document.head.appendChild(script);

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "ffa3c113-0fc3-466a-87ea-839dc0b8678a";
    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://s.skimresources.com/js/233964X1716622.skimlinks.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    window.scrollTo(0, 0);
  }


  subscribed = async () => {


    // this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            this.setState({
              isAdmin: userDoc.data().isAdmin
            })
            const userUsersCollectionRef = usersCollection.doc(userId)


            this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
              let kra = snapshot.data()
              this.setState({
                user: kra,
              })
            });



            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });



  }

  getBranch = async () => {
    //  this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeBranch) {
      this.unsubscribeBranch();
    }

    this.unsubscribeBranch = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users");
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            this.setState({
              isAdmin: userDoc.data().isAdmin
            })
            if (userDoc.data().isAdmin) {
              const userBranchCollectionRef = usersCollection
                .doc(userId)
                .collection("branches")

              this.unsubscribeBranch = userBranchCollectionRef.get().then(snapshot => {
                const branches = [];
                var branchId = ""
                if (!snapshot.empty && snapshot.docs[0]) {
                  snapshot.forEach(doc => {
                    branches.push(doc.data());
                    if (doc.data().isActive) {
                      branchId = doc.data().id
                    }
                  });
                  // Fetch the first branch document
                  //const branchDoc = snapshot.docs[0].data();

                  // Set branch in both state and localStorage
                  this.setState({
                    //  loading: false,
                    branch: branchId
                  });

                  localStorage.setItem("branch", JSON.stringify(branchId)); // Save branch to localStorage
                  localStorage.setItem("branches", JSON.stringify(branches));
                  localStorage.setItem("isAdmin", JSON.stringify(true));
                } else {
                  this.setState({
                    //   loading: false,
                    branch: null
                  });

                  //  localStorage.removeItem("branch"); // Remove branch if no active branches found
                  // localStorage.removeItem("branches");
                }
              });
            } else {
              localStorage.setItem("isAdmin", JSON.stringify(false));
              const branch = userDoc.data().branchId
              if (branch !== undefined && branch !== "") {
                localStorage.setItem("branches", JSON.stringify([branch]));
                localStorage.setItem("branch", JSON.stringify(branch)); // Save branch to localStorage
              } else {
              // //console..log("makaynch hadchi")
              }


            }


            ////localStorage.setItem("authUser", JSON.stringify(user)); // Save user to localStorage
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            // localStorage.removeItem("branch");

            this.setState({
              //  loading: false
            });
          }
        } catch (error) {
         ////console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        // Clear localStorage and state if user is not authenticated
        localStorage.removeItem("authUser");
        // localStorage.removeItem("branch");

        this.setState({
          //   loading: false
        });
      }
    });
  };
  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  /*  togglesubscribemodal = () => {
     this.setState(prevState => ({
       subscribemodal: !prevState.subscribemodal,
     }))
   }
 
   whatsappLink() {
     const newWindow = window.open('https://wa.me/+2120607802166', '_blank', 'noopener,noreferrer')
     if (newWindow) newWindow.opener = null
   } */
  redirectToCheckout = async (userId) => {
    this.setState({ loadingUrl: true, modal: false, })

    const userRef = firebase.firestore().collection("users").doc(userId)
    await userRef.collection("checkout_sessions").add({
      /* customer: customer, */
      mode: "payment",
      price: "price_1Q0M6RGFbCpQEhRTjYkJFAJ8",
      success_url: `${window.location.origin}/dashboard`,
      cancel_url: `${window.location.origin}/dashboard`,
      allow_promotion_codes: true,
    }).then((docRef) => {
      docRef.onSnapshot(async (snap) => {
        const { error, url } = snap.data();

        if (error) {
          // Handle errors
          this.setState({ loadingUrl: false })
          //alert(`An error occurred:`);
        }
        if (url) {
          // Redirect to the Stripe Checkout URL
          this.setState({ loadingUrl: false })
          //console.log("west lfaunction", url)
          /* window.open(url); */
          window.location.href = url;
        }

      })
    })

    // this.toggle()
  }
  render() {
    if (this.state.loading) {
      return <div>Loading...</div>; // Replace with a spinner or skeleton loader if needed
    }
    const { country, vehicles, user, current, isAdmin } = this.state
   ////console..log(isAdmin)
    const subscribed = (user?.lifetimeAccess || (user?.subscription?.status === "active"));

    const Layout = this.getLayout()
    const pathname = window.location.pathname
    var showWhatsapp = true, isUpgradePage = false
    authProtectedRoutes.map(function (route, idx) {


      if (pathname === "/upgrade") {
        isUpgradePage = true
        //console.log(route.path, pathname)
      }
      if (route.path === pathname) {
        showWhatsapp = false
      }
    })
    //console.log(isUpgradePage)
    publicRoutes.map(function (route, idx) {

      if (route.path === pathname) {
        showWhatsapp = false
      }
    })

    return (
      <React.Fragment>
        <Modal isOpen={this.state.loadingUrl} centered={true} size="sm" >
          <ModalBody>
            <Col>
              <div>
                <div >
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
              <br />
              <h6 className='text-center'>{this.props.t("Redirecting to checkout page ...")}</h6>
            </Col>

          </ModalBody>
        </Modal>
        <Modal
          isOpen={!isUpgradePage && !subscribed && !user.validTrial && user.length !== 0}
          role="alert"
          autoFocus={true}
          data-toggle="modal"
          centered
          size="md"
        >


          <Row className="justify-content-center">
            <Col >

              <div className="p-2 mt-4 mb-0">
                <div className="text-center">
                  <div className="avatar-md mx-auto">
                    <div className="avatar-title rounded-circle bg-light">
                      <i className="mdi mdi-cart-check h1 mt-4 text-primary"></i>
                    </div>
                  </div>
                  <div className="p-2 mt-3 mb-0">
                    <h4>{this.props.t("Your free trial has expired.")}</h4>
                    <br />
                    {isAdmin?<p>


                      {this.props.t("Ready to take the next step?")}<br /> {this.props.t("Click the link below to purchase")}
                      <span className="text-body fw-bold">
                        {" Medios "}
                      </span>
                      {this.props.t("now.")}{" "}
                    </p>:null}

                  </div>
                </div>
              </div>
              {isAdmin ? <div className="mt-0 mb-4 text-center">
                {user.country == "Morocco" ? <h5 className=" text-warning  me-3 mt-4 " onClick={() => this.redirectToCheckout(user.id)} > <i className="mdi mdi-crown-outline  mdi-18px  me-1 mt-2"></i><strong>{" "}{this.props.t("Buy Now")}</strong></h5> : <Link to="/upgrade" className=" text-warning  me-3 mt-4 "  > <i className="mdi mdi-crown-outline  mdi-18px  me-1 mt-2"></i><strong>{" "}{this.props.t("Subscribe Now")}</strong></Link>}

              </div> : <div className="mt-0 mb-4 text-center"><h5 className=" text-muted  me-3 mt-4 "  > <i className="mdi mdi-crown-outline  mdi-16px  me-1 mt-2"></i>{" "}{this.props.t("Contact Admin")}</h5></div>}

            </Col>
          </Row>
        </Modal>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

          </Switch>
          {/* <FloatingWhatsApp 
          phoneNumber="+212607802166"
          accountName="Meanz support team"
        allowClickAway
        notification
        notificationDelay={60000} // 1 minute
        notificationSound/>, */}
          {/* {showWhatsapp?null:
        <Container className="d-print-none "  >

        <Button
          tooltip="Contact Meanz support!"
          rotate={false}
          styles={{ backgroundColor: "#25D366", color: "white" }}
          icon="fab fa-whatsapp font-size-24"
          onClick={this.whatsappLink} />
      </Container>} */}

          {/* <Fab 
            icon={<i className="fab fa-whatsapp font-size-24 " />}
            alwaysShowTitle={true}
            mainButtonStyles={{ color: "white", backgroundColor: "#25D366" }}
            onClick={this.whatsappLink}
            style={{ bottom: 25, right: 25 }}
            
          >
            
          </Fab> */}


        </Router>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

App.propTypes = {
  layout: PropTypes.object,
  t: PropTypes.any,
}

export default connect(mapStateToProps, null)(withTranslation()(App))
