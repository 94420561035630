import React, { Component } from "react"

import PropTypes from 'prop-types'

import { withTranslation } from "react-i18next"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import LiVerticalTimeline from "pages/Utility/li-vertical-timeline"


class ActivityComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeline:[]
    }
  }
  getTimeline = async () => {
    
    this.setState({ loading: true });
    const branch = JSON.parse(localStorage.getItem("branch"));
  
    if (!branch) {
     //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeTimeline) {
      this.unsubscribeTimeline();
    }

    this.unsubscribeTimeline = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userTimelineCollectionRef = usersCollection.doc(userId).collection("timeline").where("branchId","==",branch).orderBy("time","desc");

            this.unsubscribeTimeline = userTimelineCollectionRef.onSnapshot(snapshot => {
            
            
            let kra = []
              snapshot.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                timeline: kra,
              })
              
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              timeline: []
            });
          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
              loading: false,
              timeline: []
            });
      }
    });
  

  }
  componentDidMount() {
    const { timeline } = this.state
    if (timeline && !timeline.length) {
      //onGetStaff()
      this.getTimeline()

    }

    this.setState({ timeline })

  }
  render() {
    const {timeline}=this.state
    var size = 3;
    const modifiedArray = timeline.map(item => {
      // Destructure the item object and remove the 'user' attribute
      const { user, ...newItem } = item;
      return newItem;
  });
  //console.log(modifiedArray)
    /* timeline.sort(function(a,b){
      return b.time - a.time;
    }); */
    var k=modifiedArray.slice(0, size)
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">{this.props.t("Activity")}</CardTitle>
            {k.length!=0?<div className="">
            <div className="mt-4">
                      <ul className="verti-timeline list-unstyled ">
                        {/* Render Horizontal Timeline Events */}

                        {k.map((tl, key) => (
                          
                          <LiVerticalTimeline
                            status={tl}
                            key={"_status_" + key}
                          />
                        ))}
                      </ul>
                    </div>
            <div className="text-center mt-4">
              <Link
                to="/pages-timeline"
                className="btn btn-primary btn-sm mt-3 mb-2"
              >
                {this.props.t("View More")}
                 <i className="mdi mdi-arrow-right ms-1" />
              </Link>
            </div></div>:<div className="text-center mt-4"><div className="mb-4">
            <i className="bx bx-history text-primary display-4" style={{ lineHeight: '1' }} />
              
            </div><h4>{this.props.t("No Recent activity")}</h4>
            <p className="mb-4">{this.props.t("Your activity history is displayed here.")}</p>
            <div className="text-center mt-4">
              <Link
                to="/tariffs"
                className="btn btn-primary btn-sm mt-3"
              >
               {this.props.t("Rates")}  <i className="mdi mdi-arrow-right ms-1" />
              </Link>
            </div>
            
            </div>}
            
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
ActivityComp.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(ActivityComp);

