import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import maintanence from "../../assets/images/coming-soon.svg"
import { withTranslation } from "react-i18next"
import Moment from 'moment';
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import { v4 as uuid } from "uuid"
import imageCompression from 'browser-image-compression';


// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
import "firebase/functions";

import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import ReactSelect from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FormText, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody, Alert } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getStaff,
  addNewStaff,
  updateStaff,
  deleteStaff
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";

class Staff extends Component {
  constructor(props, firebaseConfig) {
    super(props)
    this.state = {
      isAdmin: false,
      user_email: "",
      ibadl: false,
      loading: true,
      permissionsLoading: false,
      submitLoading: false,
      isShow: false,
      changed: false,
      gender: "",
      viewmodal: false,
      permissions: false,
      modal: false,
      isDelete: false,
      isInvite: false,
      isDinvite: false,
      isCinvite: false,
      no_data: false,
      success_dlg: false,
      permissionModal: false,

      dynamic_title: '',
      dynamic_description: '',
      kra: "",
      vc: "",
      selectedGroup: "Other",
      staffs: [],
      staff: [],
      user: [],
      date_birth: new Date(),
      user_permissions: {},
      StaffColumns: [
        /*  {
           dataField: "code",
           text: "#",
           sort: true,
           formatter: (cellContent, row) => (
             <Link to="#" className="text-body fw-bold">
               MZS{row.code}
             </Link>
           ),
         }, */
        {
          dataField: "first_name",
          text: this.props.t("First name"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.first_name}
            </Link>
          ),
        },
        {
          dataField: "last_name",
          text: this.props.t("Last name"),
          sort: true,
        },
        {
          dataField: "email",
          text: this.props.t("E-mail"),
          sort: true,
          /* formatter: (cellContent, row) => (
            this.handleValidDate(row.orderdate)
          ), */
        },
        {
          dataField: "mobile",
          text: this.props.t("Phone Number"),
          sort: true,
        },
        {
          dataField: "designation",
          isDummyField: true,
          text: this.props.t("Designation"),
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <i className={
                row.designation === "Manager" ?
                  "fas fa-briefcase me-1"
                  : row.designation === "Secretary" ?
                    "fas fa-desktop me-1"
                    : (row.designation === "Driver") ? "fas fa-car me-1" : (row.designation === "Other") ? "fas fa-user me-1" : null
              } />{" "}
              {this.props.t(row.designation)}
            </>
          ),
        },

        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("Invite Users"),
          sort: true,
          formatter: (cellContent, staff) => (
            <>{staff.status === "none" ? <Button
              type="button"
              color="success"
              className="btn-sm btn-rounded"
              onClick={() => this.setState({ isInvite: true, kra: staff })}
            >
              {this.props.t("Invite")}
            </Button> : null}
              {staff.status === "user" ?
                <div className="d-flex gap-3">


                  <Button
                    type="button"
                    color="danger"
                    className="btn-sm btn-rounded"
                    onClick={() => this.setState({ isDinvite: true, kra: staff })}
                  >
                    {this.props.t("Admin")}
                  </Button> {"   "}
                  <Link to="#" className="text-primary">
                    <i className="mdi mdi-shield-account font-size-18" id="edittooltip" onClick={() => this.handlePermissionsClick(staff)} />
                  </Link>
                </div>
                : null}
              {staff.status === "pending" ? <Button
                type="button"
                color="secondary"
                className="btn-sm btn-rounded"
                onClick={() => this.setState({ isCinvite: true, kra: staff })}
              >
                {this.props.t("Pending")}
              </Button> : null}
            </>

          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, staff) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-primary">
                  <i className="mdi mdi-eye-outline font-size-18" id="edittooltip" onClick={() => this.handleViewClick(staff)} />
                </Link>
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleStaffClick(staff)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: staff })} />
                </Link>
              </div>
            </>
          ),
        },
      ], documents: {
        idCard: null
      },
      previews: {
        idCard: null
      },
      errors: {
        idCard: ''
      },
      selectedFiles: {
        idCard: null
      },
      uploadProgress: {
        idCard: 0
      }
      ,
      selectedFiles: []
    }

    this.togglePermission = this.togglePermission.bind(this)
    this.handleStaffClick = this.handleStaffClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.togglePermissions = this.togglePermissions.bind(this)
    this.handleValidStaffSubmit = this.handleValidStaffSubmit.bind(this)
    this.handleStaffClicks = this.handleStaffClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    //this.handleCheckboxChange = this.handleCheckboxChange.bind()
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    //this.handlePermissionsClick = this.handlePermissionsClick.bind(this)
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
    this.handleGenderChange = this.handleGenderChange.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)

    this.toggleInvite = this.toggleInvite.bind(this)
    this.toggleDinvite = this.toggleDinvite.bind(this)
    this.toggleCinvite = this.toggleCinvite.bind(this)
  }
  fetchUserPermissions = async (userId) => {
    try {
      // Reference to the user's permissions collection
      const permissionsCollectionRef = firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .collection("permissions");

      // Fetch all documents in the permissions collection
      const permissionsSnapshot = await permissionsCollectionRef.get();

      if (permissionsSnapshot.empty) {
       //console..error("No permissions found for this user!");
        return null;
      }

      // Convert the documents into the desired structure
      const userPermissions = {};
      permissionsSnapshot.forEach((doc) => {
        userPermissions[doc.id] = doc.data();
      });

      //console.log("Formatted User Permissions:", userPermissions);
      this.setState({
        user_permissions: userPermissions
      })
      return userPermissions;
    } catch (error) {
     //console..error("Error fetching user permissions:", error);
      return null;
    }
  };
  printStaff = () => {
    window.print()
  }
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  handleGenderChange = e => {
    const { name, value } = e.target;

    this.setState({
      gender: value
    });

  };

  toLowerCase1(str) {
    return str.toLowerCase();
  }
  handleSelectGroup = selectedGroup => {

    this.setState({ selectedGroup: selectedGroup })
    this.setState({ changed: true })
  }
  componentDidMount() {
    const { staffs, onGetStaff } = this.state
    if (staffs && !staffs.length) {
      //onGetStaff()
      this.getStaff()

    }
    this.getUser()
    this.setState({ staffs })
    //this.fetchUserPermissions()
  }

  async updateUserPermissions() {
    try {
      // Reference to the user's permissions collection
      this.setState({ permissionsLoading: true });
      ////console..log(this.state.staff.userId)
      const userDocRef = firebase.firestore().collection("users").doc(this.state.staff.userId);
      const permissionsCollectionRef = userDocRef.collection("permissions");

      // Loop through the permissions and update each attribute as a document
      const batch = firebase.firestore().batch(); // Use batch to perform multiple writes
      for (const [attribute, actions] of Object.entries(this.state.user_permissions)) {
        const docRef = permissionsCollectionRef.doc(attribute);
        batch.set(docRef, actions); // Update or create the document
      }

      // Commit the batch
      await batch.commit();
     //console..log("User permissions updated successfully.");
      this.setState({ permissionsLoading: false });
      toast.success(this.props.t("User permissions updated successfully."))
      this.togglePermissions()
    } catch (error) {
     //console..error("Error updating user permissions:", error);
      this.setState({ permissionsLoading: false });
      toast.error(this.props.t("Error updating user permissions"))
      throw error;
    }
  }
  getUser = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            this.setState({
              user: userData,
            })
            if (userDoc.data().isAdmin) {
              this.setState({
                isAdmin: true
              })

            }
            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });

  }
  addNewTimeline = async (timeline) => {
    const branch = JSON.parse(localStorage.getItem("branch"));

    if (!branch) {
      //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      //const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        branchId: branch,
        user: "Admin"
      });

      //console.log("Timeline  written!");
    } catch (error) {
     //console..error("Error adding timeline:", error);
    }
  }
  getStaff = async () => {
    const branch = JSON.parse(localStorage.getItem("branch"));
    if (branch && branch !== "") {
     //console..log(branch);
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          //console.log(user.email)

          user = firebase.auth().currentUser.uid

         //console..log("dkhel " + user)
          const collection = firebase.firestore().collection("users")


          this.setState({ loading: true }, () => {
            collection.doc(user).collection("staffs").where("branchId", "==", branch)
              .onSnapshot((doc) => {
                let kra = []
                doc.docs.forEach(e => {
                  kra.push(e.data())
                });
                this.setState({
                  loading: false,
                  staffs: kra,
                  user_email: user.email,
                  user_id: user
                })
              }

              );
          });
          //localStorage.setItem("authUser", JSON.stringify(user))
        } else {
         //console..log("not user")
          localStorage.removeItem("authUser")
        }
      })

    } else {
     //console..log("No active branch found.");
    }


  }

  // eslint-disable-next-line no-unused-vars
  /* componentDidUpdate(prevProps, prevState, snapshot) {
    const { staffs } = this.state

    if (!isEmpty(staffs) && size(prevProps.staffs) !== size(staffs)) {
      //this.setState({ staffs: {}, isEdit: false })

    }

  } */
  toggleInvite() {
    this.setState(prevState => ({
      isInvite: !prevState.isInvite,
    }))
  }
  toggleDinvite() {
    this.setState(prevState => ({
      isDinvite: !prevState.isDinvite,
    }))
  }
  toggleCinvite() {
    this.setState(prevState => ({
      isCinvite: !prevState.isCinvite,
    }))
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      ibadl: false
    }))
  }
  togglePermissions() {
    this.setState(prevState => ({
      permissions: !prevState.permissions
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }
  toggleNoData() {
    this.setState(prevState => ({
      no_data: !prevState.no_data,
    }))
  }

  handleStaffClicks = () => {
    this.setState({ staff: [], isEdit: false, isShow: false, selectedGroup: null })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { staffs } = this.props
    this.setState({
      staffs: staffs.filter(staff =>
        Object.keys(staff).some(
          key =>
            typeof staff[key] === "string" &&
            staff[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }

  /*  InviteUser = async (staff) => {
     try {
       this.setState({ submitLoading: true });
 
       const collection = firebase.firestore().collection("invitations");
       const staffCollection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs").doc(staff.id);
 
       const timeline = {
         statusTitle: "Admin invitation sent",
         iconClass: "bx bx-user-check  h2 text-success",
         description: "An invitation sent to ",
         data: staff.first_name + " " + staff.last_name,
         time: new Date().getTime(),
         user: "Admin"
       };
 
       //console.log(firebase.auth().currentUser.email);
 
       const iid = uuid();
       const newDocRef = collection.doc();
       await newDocRef.set({
         id: newDocRef.id,
         code: iid,
         from: firebase.auth().currentUser.email,
         account_id: firebase.auth().currentUser.uid,
         first_name: staff.first_name,
         last_name: staff.last_name,
         staff_id: staff.id,
         email: staff.email,
         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
         status: "created",
         branchId:staff.branchId
       });
 
       //console.log("Staff  written!");
       toast.success(this.props.t('Invitation sent '))
 
       await staffCollection.update({
         status: "pending",
         invitationId: newDocRef.id
       });
 
       this.addNewTimeline(timeline);
       this.setState({ submitLoading: false });
       //this.toggleInvite();
     } catch (error) {
      //console..error("Error sending Invitation:", error);
       toast.error(this.props.t("Error Sending Invitation"))
       this.setState({ submitLoading: false });
     }
   } */

  InviteUser = async (staff) => {
    try {
      this.setState({ submitLoading: true });
      const collection = firebase.firestore().collection("invitations");
      const staffCollection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs").doc(staff.id);
      const userDoc = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);

      const timeline = {
        statusTitle: "Admin invitation sent",
        iconClass: "bx bx-user-check  h2 text-success",
        description: "An invitation sent to ",
        data: staff.first_name + " " + staff.last_name,
        time: new Date().getTime(),
        user: "Admin"
      };

      //console.log(firebase.auth().currentUser.email);
      const iid = uuid();
      const newDocRef = collection.doc();
      await newDocRef.set({
        id: newDocRef.id,
        code: iid,
        from: firebase.auth().currentUser.email,
        account_id: firebase.auth().currentUser.uid,
        first_name: staff.first_name,
        last_name: staff.last_name,
        staff_id: staff.id,
        email: staff.email,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        status: "created",
        branchId: staff.branchId
      });

      // Increment invitationCount
      await userDoc.update({
        invitationCount: firebase.firestore.FieldValue.increment(1)
      });

      //console.log("Staff  written!");
      toast.success(this.props.t('Invitation sent '))
      await staffCollection.update({
        status: "pending",
        invitationId: newDocRef.id
      });
      this.addNewTimeline(timeline);
      this.setState({ submitLoading: false });
      //this.toggleInvite();
    } catch (error) {
      if (error.code === "permission-denied") {
        this.setState({ submitLoading: false, permissionModal: true });
        return;
      }
      toast.error(this.props.t("Error Sending Invitation"))
      this.setState({ submitLoading: false });
    }
  }
  DinviteUser = async (staff) => {
    try {
      // Delete the user
      this.setState({ submitLoading: true })
      const staffCollection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs").doc(staff.id);
      const userDoc = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);
      const deleteUserCallable = firebase.app().functions('europe-west1').httpsCallable('deleteUser');
      await deleteUserCallable({ staff });
      //console.log('User deleted .');
      toast.success(this.props.t('Admin Privilige deleted '))
      const timeline = {
        statusTitle: "Admin Privilige deleted",
        iconClass: "bx bx-user-x  h2 text-danger",
        description: "An invitation deleted ",
        data: staff.first_name + " " + staff.last_name,
        time: new Date().getTime(),
        user: "Admin"
      };
      await staffCollection.update({
        status: "none",
        invitationId: null,
        isUser: false,
        userId: ""
      });
      // Increment invitationCount
      await userDoc.update({
        invitationCount: firebase.firestore.FieldValue.increment(-1)
      });
      this.addNewTimeline(timeline);
      this.setState({ submitLoading: false });
      //this.toggleDinvite();
    } catch (error) {
      this.setState({ submitLoading: false });
     //console..error('Error deleting user:', error.message);
      toast.error(this.props.t('Error Deleting Admin Privilige'))
      //this.toggleDinvite();
    }
  }
  CinviteUser = async (staff) => {
    try {
      this.setState({ submitLoading: true });

      const collection = firebase.firestore().collection("invitations");
      const staffCollection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs").doc(staff.id);
      const userDoc = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);

      const timeline = {
        statusTitle: "Admin invitation cancelled",
        iconClass: "bx bx-user-x  h2 text-warning",
        description: "An invitation cancelled ",
        data: staff.first_name + " " + staff.last_name,
        time: new Date().getTime(),
        user: "Admin"
      };

      //console.log(firebase.auth().currentUser.email);

      const idoc = collection.doc(staff.invitationId);
      await idoc.update({
        status: "cancelled"
      });

      // Update user's invitationCount
      await userDoc.update({
        invitationCount: firebase.firestore.FieldValue.increment(-1)
      });

      //console.log("Invitation  cancelled!");
      toast.success(this.props.t('Invitation cancelled '));

      await staffCollection.update({
        status: "none",
        invitationId: null
      });

      this.addNewTimeline(timeline);
      this.setState({ submitLoading: false });
      //this.toggleCinvite();
    } catch (error) {
     //console..error("Error cancelling Invitation:", error);
      toast.error(this.props.t("Error Cancelling Invitation"));
      this.setState({ submitLoading: false });
      //this.toggleCinvite();
    }
  }
  /* CinviteUser = async (staff) => {
    try {
      this.setState({ submitLoading: true });

      const collection = firebase.firestore().collection("invitations");
      const staffCollection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs").doc(staff.id);

      const timeline = {
        statusTitle: "Admin invitation cancelled",
        iconClass: "bx bx-user-x  h2 text-warning",
        description: "An invitation cancelled ",
        data: staff.first_name + " " + staff.last_name,
        time: new Date().getTime(),
        user: "Admin"
      };

      //console.log(firebase.auth().currentUser.email);

      const idoc = collection.doc(staff.invitationId);
      await idoc.update({
        status: "cancelled"
      });

      //console.log("Invitation  cancelled!");
      toast.success(this.props.t('Invitation cancelled '));

      await staffCollection.update({
        status: "none",
        invitationId: null
      });

      this.addNewTimeline(timeline);
      this.setState({ submitLoading: false });
      //this.toggleCinvite();
    } catch (error) {
     //console..error("Error cancelling Invitation:", error);
      toast.error(this.props.t("Error Cancelling Invitation"));
      this.setState({ submitLoading: false });
      //this.toggleCinvite();
    }
  } */

  /* Insert,Update Delete data */
  handleInviteUser = (staff) => {
    this.InviteUser(staff)
    this.toggleInvite()
  }
  handleDinviteUser = (staff) => {
    this.DinviteUser(staff)
    this.toggleDinvite()
  }
  handleCinviteUser = (staff) => {

    this.CinviteUser(staff)
    this.toggleCinvite()
  }

  handleDeleteStaff = (staff) => {
   //console..log("delete inside index " + this.state.staffs.id_file)

    // const { onDeleteStaff } = this.props
    /* if (staff['code'] === '') {
       //onDeleteStaff(order)
     } else { */
    //onDeleteStaff(staff)
    this.deleteStaff(staff)
    //toast.success(this.props.t('Staff member infos deleted '))
    this.toggleDelete()
    //}

  }



  handleViewClick = arg => {
    const stf = arg
    const id_url = stf.idCardDoc ? stf.idCardDoc.url : ""
    this.setState({ selectedGroup: { label: stf.designation, value: stf.designation } })
    this.setState({
      staff: {
        //code: staff.code,
        first_name: stf.first_name,
        last_name: stf.last_name,
        email: stf.email,
        mobile: stf.mobile,
        designation: stf.designation,
        address: stf.address,
        city: stf.city,
        state: stf.state,
        gender: stf.gender,
        date_birth: stf.date_birth,
        id_number: stf.id_number,
        id_url: id_url,
      },
      gender: stf.gender,
      isShow: true,
      isEdit: false
    })

    //this.toggleIsShow()
    this.toggle()
    // //console.log(this.state.isShow)
    //this.toggleViewModal()
  }

  handlePermissionsClick = arg => {
    const stf = arg
    this.setState({ selectedGroup: { label: stf.designation, value: stf.designation } })
    this.setState({
      staff: {
        id: stf.id,
        first_name: stf.first_name,
        last_name: stf.last_name,
        email: stf.email,
        mobile: stf.mobile,
        designation: stf.designation,
        address: stf.address,
        city: stf.city,
        state: stf.state,
        gender: stf.gender,
        date_birth: stf.date_birth,
        id_number: stf.id_number,
        userId: stf.userId
      },
      isEdit: true,
      isShow: false,
      gender: stf.gender,

    })
    this.fetchUserPermissions(stf.userId)
    this.togglePermissions()
  }
  handleStaffClick = arg => {
    const stf = arg
    const id_url = stf.idCardDoc ? stf.idCardDoc.url : ""
    /* this.getDownloadLink1(stf.id_file)
    this.getDownloadLink(stf.id_file1) */
    this.setState({ selectedGroup: { label: stf.designation, value: stf.designation } })
    this.setState({
      staff: {
        id: stf.id,
        first_name: stf.first_name,
        last_name: stf.last_name,
        email: stf.email,
        mobile: stf.mobile,
        designation: stf.designation,
        address: stf.address,
        city: stf.city,
        state: stf.state,
        gender: stf.gender,
        date_birth: stf.date_birth,
        id_number: stf.id_number,
        id_url: id_url,
      },
      isEdit: true,
      isShow: false,
      gender: stf.gender,

    })

    this.toggle()
  }
  handleCheckboxChange = (attribute, action) => {
    this.setState((prevState) => ({
      user_permissions: {
        ...prevState.user_permissions,
        [attribute]: {
          ...prevState.user_permissions[attribute],
          [action]: !prevState.user_permissions[attribute][action],
        },
      },
    }));
  };

  /**
   * Handling submit Order on Order form
   */
  handleValidStaffSubmit = (e, values) => {
    const { onAddNewStaff, onUpdateStaff } = this.props
    const { isEdit, staffs, selectedStaff } = this.state
    const cds = []
    var kr = false
    var em = 0, mo = 0, id = 0
    var emA = 0, moA = 0, idA = 0


    const foundem = staffs.filter(element => element.email === values.email.trim() && this.state.staff.id !== element.id);
    em = foundem.length
    //console.log(foundem)
    const foundmo = staffs.filter(element => element.mobile === values.mobile.trim() && this.state.staff.id !== element.id);
    mo = foundmo.length
    const foundid = staffs.filter(element => element.id_number === values.id_number.trim() && this.state.staff.id !== element.id);
    id = foundid.length

    const foundemA = staffs.filter(element => element.email === values.email.trim());
    emA = foundemA.length
    const foundmoA = staffs.filter(element => element.mobile === values.mobile.trim());
    moA = foundmoA.length
    const foundidA = staffs.filter(element => element.id_number === values.id_number.trim());
    idA = foundidA.length





    if (isEdit) {
      //des=this.state.selectedGroup.defaultInputValue

      const updateStaff = {
        id: this.state.staff.id,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile: values.mobile,
        designation: values.designation,
        address: values.address,
        city: values.city,
        state: values.state,
        gender: this.state.gender,
        date_birth: values.date_birth,
        id_number: values.id_number,
        idCardDoc: this.state.selectedFiles.idCard

      }

      if (!this.state.ibadl && this.state.staff.first_name.trim() == updateStaff.first_name.trim()
        && this.state.staff.last_name.trim() == updateStaff.last_name.trim()
        && this.state.staff.email.trim() == updateStaff.email.trim()
        && this.state.staff.mobile.trim() == updateStaff.mobile.trim()
        && this.state.staff.designation.trim() == updateStaff.designation.trim()
        && this.state.staff.address.trim() == updateStaff.address.trim()
        && this.state.staff.city.trim() == updateStaff.city.trim()
        && this.state.staff.state.trim() == updateStaff.state.trim()
        && this.state.staff.gender.trim() == updateStaff.gender.trim()
        && this.state.staff.date_birth.trim() == updateStaff.date_birth.trim()
        && updateStaff.idCardDoc == null
        && this.state.staff.id_number.trim() == updateStaff.id_number.trim()) {
        this.setState({ submitLoading: false })
        this.toggle()

      } else {
        this.updateStaff(updateStaff, em, mo, id)
      }
      // update Order
      //onUpdateStaff(updateStaff)


      // toast.success(this.props.t('Staff member updated '))
    } else {

      const newStaff = {
        //code: cd,
        first_name: values["first_name"],
        last_name: values["last_name"],
        email: values["email"],
        mobile: values["mobile"],
        designation: values["designation"],
        address: values["address"],
        city: values['city'],
        state: values["state"],
        gender: this.state.gender,
        date_birth: values["date_birth"],
        id_number: values["id_number"],
        isUser: false,
        status: "none"
        //id_file:images.fullPath
      }
     //console..log(des)
      // save new Order
      // onAddNewStaff(newStaff)
      this.setState({ staff: newStaff })
      this.addNewStaff(newStaff, emA, moA, idA)
      //toast.success(this.props.t('Staff member Added '))
    }
    this.setState({ selectedStaff: null })
    // this.toggle()
  }
  deleteStaff = (staff) => {
   //console..log(staff)
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")
    const timeline = {
      statusTitle: "Staff member deleted",
      iconClass: "bx bx-group  h2 text-danger",
      description: "Staff member information has been deleted for ",
      data: staff.first_name + " " + staff.last_name,
      time: new Date().getTime(),
      user: "Admin"
    }
    collection.doc(staff.id).delete().then(() => {

      //console.log("Staff member  deleted!");
      toast.success(this.props.t("Staff member  deleted!"))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Staff member has been deleted."),
      })
    }).catch((error) => {
     //console..error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"));


    });
  }

  updateStaff = async (staff, em, mo, id) => {

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")
    const timeline = {
      statusTitle: "Staff member updated",
      iconClass: "bx bx-group h2 text-warning",
      description: "Staff member information has been updated for ",
      data: staff.first_name + " " + staff.last_name,
      time: new Date().getTime(),
      user: "Admin"
    }
    //console.log(firebase.auth().currentUser.email)
    const account_email = firebase.auth().currentUser.email
    if (account_email !== staff.email && em == 0 && mo == 0 && id == 0) {

      try {
        // Get current document to check for existing files
        const currentDoc = await collection.doc(staff.id).get();
        const currentData = currentDoc.data();

        // Handle ID card file update
        let idCardData = currentData.idCardDoc || null;
        if (this.state.selectedFiles.idCard) {
          // Delete old file if it exists
          if (currentData.idCardDoc?.path) {
            await this.deleteFile(currentData.idCardDoc.path);
          }
          // Upload new file
          idCardData = await this.uploadFile(this.state.selectedFiles.idCard, 'idCard');
        }

        // Update document with new data
        await collection.doc(staff.id).update({

          first_name: staff.first_name,
          last_name: staff.last_name,
          email: staff.email,
          mobile: staff.mobile,
          designation: staff.designation,
          address: staff.address,
          city: staff.city,
          state: staff.state,
          gender: staff.gender,
          date_birth: staff.date_birth,
          id_number: staff.id_number,
          // Update document fields only if new files were uploaded
          ...(idCardData && {
            idCardDoc: idCardData
          })

        });
        //console.log("Document Updated!");
        toast.success(this.props.t("Staff  Updated!"))

        this.addNewTimeline(timeline)
        this.setState({
          submitLoading: false,
          selectedFiles: { idCard: null },
          uploadProgress: { idCard: 0 }
        });
        this.toggle()
      } catch (error) {
       //console..error("Error updating document: ", error);
        toast.error(this.props.t("Error updating document"))
        this.setState({ submitLoading: false })
      }
    } else {
      if (account_email === staff.email) {
       //console..error("You cannot use this email !");
        toast.error(this.props.t("You cannot use this email !"))
        this.setState({ submitLoading: false })
      }
      if (em != 0) {
       //console..error("Email already exist ");
        toast.error(this.props.t("Email already exist"))
        this.setState({ submitLoading: false })
      }
      if (mo != 0) {
       //console..error("Mobile number already exist ");
        toast.error(this.props.t("Mobile number already exist"))
        this.setState({ submitLoading: false })
      }
      if (id != 0) {
       //console..error("ID number already exist ");
        toast.error(this.props.t("ID number already exist"))
        this.setState({ submitLoading: false })
      }
    }

  }

  addNewStaff = async (staff, em, mo, id) => {
    const branch = JSON.parse(localStorage.getItem("branch"));

    if (!branch) {
      //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
    try {
      // Upload files first if they exist
      const [idCardFile] = await Promise.all([
        this.uploadFile(this.state.selectedFiles.idCard, 'idCard')
      ]);
      const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")

      this.setState({ submitLoading: true })
      const timeline = {
        statusTitle: "Staff member added",
        iconClass: "bx bx-group h2 text-success",
        description: "Staff member information has been added ",
        data: staff.first_name + " " + staff.last_name,
        time: new Date().getTime(),
        user: "Admin"
      }
      var newDoc = collection.doc();
      //console.log(firebase.auth().currentUser.email)
      const account_email = firebase.auth().currentUser.email
      if (account_email !== staff.email && em == 0 && mo == 0 && id == 0) {
        await newDoc.set(
          {
            id: newDoc.id,
            first_name: staff.first_name,
            last_name: staff.last_name,
            email: staff.email,
            mobile: staff.mobile,
            designation: staff.designation,
            address: staff.address,
            city: staff.city,
            state: staff.state,
            gender: staff.gender,
            date_birth: staff.date_birth,
            id_number: staff.id_number,
            isUser: staff.isUser,
            status: staff.status,
            branchId: branch,
            createdAt: new Date().getTime(),
            ...(idCardFile && {
              idCardDoc: {
                path: idCardFile.path,
                url: idCardFile.url
              }
            })
          })
        //console.log("stf  written!");
        toast.success(this.props.t('Staff member added '))

        this.addNewTimeline(timeline)
        this.setState({
          submitLoading: false,
          selectedFiles: { idCard: null },
          uploadProgress: { idCard: 0 }
        });
        this.toggle()

      } else {
        if (account_email === staff.email) {
         //console..error("You cannot use this email !");
          toast.error(this.props.t("You cannot use this email !"))
          this.setState({ submitLoading: false })
        }
        if (em != 0) {
         //console..error("Email already exist ");
          toast.error(this.props.t("Email already exist"))
          this.setState({ submitLoading: false })
        }
        if (mo != 0) {
         //console..error("Mobile number already exist ");
          toast.error(this.props.t("Mobile number already exist"))
          this.setState({ submitLoading: false })
        }
        if (id != 0) {
         //console..error("ID number already exist ");
          toast.error(this.props.t("ID number already exist"))
          this.setState({ submitLoading: false })
        }
      }
    } catch (error) {
     //console..error("Error Adding staff member: ", error);
      toast.error(this.props.t("Error Adding staff member infos"))
      this.setState({ submitLoading: false })
    }

  }


  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }

  handleMasterCheckboxChange = (action, isChecked) => {
    const updatedPermissions = { ...this.state.user_permissions };

    // Update all attributes for the specific action
    Object.keys(updatedPermissions).forEach((attribute) => {
      updatedPermissions[attribute][action] = isChecked;
    });

    // Update state
    this.setState({ user_permissions: updatedPermissions });
  };
  deleteFile = async (filePath) => {
    if (!filePath) return;

    try {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(filePath);
      await fileRef.delete();
    } catch (error) {
     //console..error("Error deleting file:", error);
      // Continue execution even if delete fails
    }
  };
  uploadFile = async (file, type) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${this.state.user_id}/staff-documents/${type}-${Date.now()}.${fileExtension}`;
    const fileRef = storageRef.child(fileName);

    try {
      const uploadTask = fileRef.put(file);

      // Monitor upload progress if needed
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState(prevState => ({
            uploadProgress: {
              ...prevState.uploadProgress,
              [type]: progress
            }
          }));
        }
      );

      await uploadTask;
      const downloadURL = await fileRef.getDownloadURL();
      return { path: fileName, url: downloadURL };
    } catch (error) {
     //console..error(`Error uploading ${type}:`, error);
      toast.error(`Error uploading ${type}`);
      return null;
    }
  };

  handleFileChange = (event, documentType) => {
    const file = event.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB limit

    if (file) {

      if (file.size > maxSize) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [documentType]: 'File size should not exceed 5MB'
          }
        }));
        return;
      }

      if (!file.type.match('image.*') && !file.type.match('application/pdf')) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [documentType]: 'Please upload an image or PDF file'
          }
        }));
        return;
      }

      this.setState(prevState => ({
        documents: {
          ...prevState.documents,
          [documentType]: file
        },
        errors: {
          ...prevState.errors,
          [documentType]: ''
        }
      }));
      this.setState(prevState => ({
        selectedFiles: {
          ...prevState.selectedFiles,
          [documentType]: file
        }
      }));

      // Create preview for images only
      if (file.type.match('image.*')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState(prevState => ({
            previews: {
              ...prevState.previews,
              [documentType]: reader.result
            }
          }));
        };
        reader.readAsDataURL(file);
      } else {
        this.setState(prevState => ({
          previews: {
            ...prevState.previews,
            [documentType]: null
          }
        }));
      }
    }
  }


  getPlanLimits = (userData) => {
    const subscriptionPlanId = userData.subscription?.planId;
    const lifetimeAccess = userData.lifetimeAccess;

    // Check if the subscription plan is unlimited
    const isUnlimitedPlan =
      subscriptionPlanId === 'price_1QoNOqGFbCpQEhRTkiWwF3wZ' ||
      subscriptionPlanId === 'price_1QoNOqGFbCpQEhRTddXhmILJ';

    // If the subscription plan is unlimited, return unlimited limits
    if (isUnlimitedPlan) {
      return {
        invitations: null, // Unlimited
        vehicles: null,    // Unlimited
        branches: null,    // Unlimited
      };
    }

    // If lifetime access is true, return lifetime access limits
    if (lifetimeAccess) {
      return {
        invitations: 5,
        vehicles: 30,
        branches: 3,
      };
    }

    // Otherwise, check the subscription plan
    switch (subscriptionPlanId) {
      case 'price_1QoNLuGFbCpQEhRT16ld1V0Y':
      case 'price_1QoNLuGFbCpQEhRTo1w6doyB':
        return {
          invitations: 2,
          vehicles: 10,
          branches: 1,
        };
      case 'price_1QoNNVGFbCpQEhRTn1khi0aa':
      case 'price_1QoNNVGFbCpQEhRTvmg7sy5O':
        return {
          invitations: 5,
          vehicles: 30,
          branches: 3,
        };
      default:
        // Default to free trial limits
        return {
          invitations: 1,
          vehicles: 5,
          branches: 1,
        };
    }
  };

  getSubscriptionInterval = (planId) => {
    const planIntervalMapping = {
      starter: {
        monthly: 'price_1QoNLuGFbCpQEhRT16ld1V0Y',
        annual: 'price_1QoNLuGFbCpQEhRTo1w6doyB',
      },
      professional: {
        monthly: 'price_1QoNNVGFbCpQEhRTn1khi0aa',
        annual: 'price_1QoNNVGFbCpQEhRTvmg7sy5O',
      },
      unlimited: {
        monthly: 'price_1QoNOqGFbCpQEhRTkiWwF3wZ',
        annual: 'price_1QoNOqGFbCpQEhRTddXhmILJ',
      },
    };
    for (const planType in planIntervalMapping) {
      for (const interval in planIntervalMapping[planType]) {
        if (planIntervalMapping[planType][interval] === planId) {
          return interval; // 'monthly' or 'annual'
        }
      }
    }
    return null; // If no match is found
  };
  // Helper function to get plan name
  getPlanName = (planId) => {
    const { user } = this.state
    if (user.lifetimeAccess) {
      return 'Lifetime Access';
    }
    const plans = {
      'price_1QoNLuGFbCpQEhRT16ld1V0Y': 'Starter Plan',
      'price_1QoNLuGFbCpQEhRTo1w6doyB': 'Starter Plan',
      'price_1QoNNVGFbCpQEhRTn1khi0aa': 'Pro Plan',
      'price_1QoNNVGFbCpQEhRTvmg7sy5O': 'Pro Plan',
      'price_1QoNOqGFbCpQEhRTkiWwF3wZ': 'Unlimited Plan',
      'price_1QoNOqGFbCpQEhRTddXhmILJ': 'Unlimited Plan',
    };
    return plans[planId] || 'Free Trial';
  };
  redirectToPortal = async (customerId) => {
    try {
      this.setState({ redirecting: true })
      const createPortalLink = firebase.app().functions('europe-west1').httpsCallable('createPortalLink')
      const result = await createPortalLink({ customerId });
      //console.log(result.data.url)
      this.setState({ redirecting: false })
      window.location.href = result.data.url;
    } catch (error) {
      this.setState({ redirecting: false })
     //console..error('Error redirecting to portal:', error);
    }
  };
  togglePermission() {
    this.setState(prevState => ({
      permissionModal: !prevState.permissionModal,
    }))
  }
  render() {

    const emptyDataMessage = () => {
      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your staff members informations by clicking")}<h5 className="text-primary"><Link to="#" onClick={this.handleStaffClicks} >{this.props.t("New Staff member")}</Link></h5>
                </p>


              </div>
            }

          </Col>
        </Row>
      </Container>


        ;
    }
    const { user, errors, staffs, isAdmin } = this.state
    const planLimits = this.getPlanLimits(user);
    const planName = this.getPlanName(user.subscription?.planId);
    const isLifetimeAccess = user.lifetimeAccess;
    const subscriptionInterval = this.getSubscriptionInterval(user.subscription?.planId); // 'monthly' or 'annual'
    const nextPaymentDate = user.subscription?.currentPeriodEnd; // Next payment date
    const formattedDate = moment(nextPaymentDate).format("MMMM D, YYYY");
    const data = staffs;
    // //console.log(data)
    let dataxcl = []
    data.map(function (item) {
      delete item.staff;
      dataxcl.push({
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        mobile: item.mobile,
        designation: item.designation,
        id_number: item.id_number,
        date_birth: item.date_birth,
        gender: item.gender,
        address: item.address,
        city: item.city,
        state: item.state
      })
    });
    /*  let newArray = data.map(function (item) {
       delete item.staff;
 
       return item;
     }); */

   //console..log(dataxcl)


   //console..log("staffs" + staffs.length)

    const { gender } = this.state
    const { selectedGroup } = this.state
    const { SearchBar } = Search
    const { date_birth } = this.state;
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc, kra } = this.state
    const optionGroup =
      [
        { label: "", value: "" },
        { label: this.props.t("Manager"), value: "Manager" },
        { label: this.props.t("Secretary"), value: "Secretary" },
        { label: this.props.t("Driver"), value: "Driver" },
        { label: this.props.t("Other"), value: "Other" }
      ]


    function exportPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Staffs Infos";
      const headers = [["Name", "Email", "Address", "Mobile", "Gender", "ID number", "Designation"]];

      const dt = data.map(elt => [elt.first_name + " " + elt.last_name, elt.email, elt.address + " \n" + elt.city, elt.mobile, elt.gender, elt.id_number, elt.designation]);

      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Staff.pdf")


    }
    function printPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);
      const title = "Staffs Infos";
      const headers = [["Name", "Email", "Address", "Mobile", "Gender", "ID number", "Designation"]];

      const dt = data.map(elt => [elt.first_name + " " + elt.last_name, elt.email, elt.address + " \n" + elt.city, elt.mobile, elt.gender, elt.id_number, elt.designation]);
      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }



    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: staffs.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'first_name',
      order: 'asc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };

    function ExportToExcel() {

      exportFromJSON({ data: dataxcl, fileName: 'Staffs', exportType: exportFromJSON.types.xls })
    }


   //console..log(this.state.staffs.gender)
    /*  var myCurrentDate=new Date();
     var d=new Date(myCurrentDate);
         d.setFullYear(d.getFullYear() - 18);
     var minDate=d.getFullYear()+"-01"
         //console.log(minDate) */
    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Staff | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Extras")} breadcrumbItem={this.props.t("Staff")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Modal isOpen={this.state.redirecting}>
              <ModalBody>
                <Col>
                  <div>
                    <div >
                      <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <h6 className='text-center'>{this.props.t("Loading ...")}</h6>
                </Col>

              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.permissionModal}
              role="alert"
              autoFocus={true}
              data-toggle="permissionModal"
              centered
              size="md"
            >
              <ModalHeader toggle={this.togglePermission} tag="h4">     </ModalHeader>

              <Row className="justify-content-center">
                <Col >

                  <div className="p-2 mt-4 mb-0">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="mdi mdi-alert-circle-outline h1 mt-2 text-primary mdi-48px"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-3 mb-0">
                        <h4>{this.props.t("Upgrade Required !")}</h4>
                        <br />
                        <p>
                          {this.props.t("You have reached the limit of")} <strong>{planLimits.invitations}</strong> {this.props.t("users for your")} <strong>{planName}</strong> {this.props.t("plan.")} <br />
                          {this.props.t("Upgrade your plan to invite more users.")}
                        </p>
                        <Link onClick={() =>
                          user.lifetimeAccess || !user.subscription?.planId
                            ? (window.location.href = '/upgrade')
                            : this.redirectToPortal(user.stripeId)
                        }
                        >
                          <strong> {user.lifetimeAccess || !user.subscription?.planId ? 'Upgrade Now' : 'Manage Subscription'}</strong>

                        </Link>

                      </div>
                    </div>
                  </div>

                </Col>
              </Row>
            </Modal>
            <Row>
              <Col xs="12">
                {isAdmin ?
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory()}
                        keyField='id'
                        columns={(this.state.StaffColumns || [])}
                        data={(staffs || [])}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={staffs}
                            columns={(this.state.StaffColumns || [])}
                            bootstrap4
                            search

                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2" >
                                  <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col sm="8" >

                                    <div className="text-sm-end" >
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded mb-2 me-2"
                                        onClick={this.handleStaffClicks}
                                      >
                                        <i className="mdi mdi-plus me-1" />{" "}
                                        {this.props.t("New Staff member")}
                                      </Button>

                                      <Link to="#"

                                      >

                                        {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                        <td>
                                          <UncontrolledDropdown>
                                            <DropdownToggle href="#" className="card-drop" tag="a">
                                              <i className="mdi mdi-dots-vertical font-size-18" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                              <DropdownItem href="#" onClick={() => this.state.staffs.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                                <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                                {this.props.t("Export PDF")}

                                              </DropdownItem>
                                              <DropdownItem href="#" onClick={() => this.state.staffs.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                                <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                                {this.props.t("Export Excel")}
                                              </DropdownItem>
                                              <DropdownItem href="#" onClick={() => this.state.staffs.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                                <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                                {this.props.t("Print")}
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>

                                        </td>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                                <div className="table-responsive">
                                  <BootstrapTable

                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    striped={true}
                                    noDataIndication={emptyDataMessage}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                  />


                                  <Modal
                                    isOpen={this.state.permissions}
                                    className={this.props.className}
                                  >
                                    <ModalHeader toggle={this.togglePermissions} tag="h4">
                                      {this.props.t("User permissions")}
                                    </ModalHeader>
                                    <ModalBody>
                                      {this.state.permissionsLoading ?
                                        <Col>
                                          <div>
                                            <div >
                                              <div className="spinner-chase">
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                              </div>
                                            </div>
                                          </div>
                                          <br />
                                          <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                        </Col>

                                        :
                                        <><Table bordered>
                                          <thead>
                                            <tr>
                                              <th>{this.props.t("Attribute")}</th>
                                              {["add", "update", "delete"].map((action) => {
                                                // Calculate the states for the master checkbox
                                                const allChecked = Object.values(this.state.user_permissions).every((perm) => perm[action]);
                                                const noneChecked = Object.values(this.state.user_permissions).every((perm) => !perm[action]);
                                                const isIndeterminate = !allChecked && !noneChecked;

                                                return (
                                                  <th key={action}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                      <span style={{ marginRight: "8px" }}>{action.charAt(0).toUpperCase() + action.slice(1)}</span>
                                                      <input
                                                        type="checkbox"
                                                        ref={(el) => el && (el.indeterminate = isIndeterminate)} // Set indeterminate state
                                                        onChange={(e) => this.handleMasterCheckboxChange(action, e.target.checked)}
                                                        checked={allChecked} // Fully checked if all are true
                                                      />
                                                    </div>
                                                  </th>
                                                );
                                              })}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Object.keys(this.state.user_permissions).map((attribute) => (
                                              <tr key={attribute}>
                                                <td>{attribute}</td>
                                                {["add", "update", "delete"].map((action) => (
                                                  <td key={action}>
                                                    <input
                                                      type="checkbox"
                                                      checked={this.state.user_permissions[attribute][action]}
                                                      onChange={() => this.handleCheckboxChange(attribute, action)}
                                                    />
                                                  </td>
                                                ))}
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>

                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button

                                                  className="btn btn-success save-user"
                                                  onClick={() => this.updateUserPermissions()}
                                                >
                                                  {this.props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row></>}
                                    </ModalBody>
                                  </Modal>
                                  <Modal
                                    isOpen={this.state.modal}
                                    className={this.props.className}
                                  >
                                    <ModalHeader toggle={this.toggle} tag="h4">
                                      {!!this.state.isEdit ? this.props.t("Edit Staff Member") : null}
                                      {!!this.state.isShow ? this.props.t("Staff Member Infos") : null}
                                      {!this.state.isEdit && !this.state.isShow ? this.props.t("Add Staff Member") : null}
                                    </ModalHeader>
                                    <ModalBody>
                                      {this.state.submitLoading ?
                                        <Col>
                                          <div>
                                            <div >
                                              <div className="spinner-chase">
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                              </div>
                                            </div>
                                          </div>
                                          <br />
                                          <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                        </Col>

                                        :

                                        <AvForm
                                          onValidSubmit={
                                            this.handleValidStaffSubmit
                                          }
                                        // disabled={this.state.isShow ? true : false}
                                        >

                                          <Row>

                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">
                                                  {this.props.t("First name")}
                                                </Label>
                                                <AvField
                                                  name="first_name"
                                                  placeholder={this.props.t("First name")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter First Name")}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                  id="validationCustom01"
                                                  value={this.state.staff.first_name || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom02">
                                                  {this.props.t("Last name")}
                                                </Label>
                                                <AvField
                                                  name="last_name"
                                                  placeholder={this.props.t("Last name")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter Last name")}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                  id="validationCustom02"
                                                  value={this.state.staff.last_name || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom03">
                                                  {this.props.t("E-mail")}
                                                </Label>
                                                <AvField
                                                  name="email"
                                                  placeholder={this.props.t("E-mail")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter a valid E-mail")}
                                                  className="form-control"
                                                  validate={{
                                                    required: { value: true },
                                                    email: { value: true },
                                                  }}
                                                  id="validationCustom03"
                                                  value={this.state.staff.email || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />

                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-4">
                                                <Label> {this.props.t("Date of birth")}</Label>
                                                <AvField
                                                  name="date_birth"
                                                  placeholder={this.props.t("Date of birth")}
                                                  type="date"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={Moment(this.state.staff.date_birth).format('YYYY-MM-DD') || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>

                                          </Row>
                                          <Row>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom04">
                                                  {this.props.t("Phone Number")}
                                                </Label>
                                                <AvField
                                                  name="mobile"
                                                  placeholder={this.props.t("Phone Number")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter a valid phone number")}
                                                  className="form-control"

                                                  validate={{
                                                    required: { value: true },
                                                    pattern: {
                                                      value: '^(0|\\+)\\d{9,14}$',
                                                      errorMessage: this.props.t("Enter a valid phone number")
                                                    },
                                                  }}
                                                  id="validationCustom04"
                                                  value={this.state.staff.mobile || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6 ">
                                              <FormGroup className="mb-3">
                                                <Label className="d-block mb-3">{this.props.t("Gender")}</Label>
                                                <div className="form-check form-check-inline">

                                                  <input
                                                    id="windows"
                                                    value="Male"
                                                    name="gender"
                                                    type="radio"
                                                    className="form-check-input"
                                                    defaultChecked={this.state.staff.gender == "Male" ? true : false}
                                                    onChange={this.handleGenderChange}
                                                    disabled={this.state.isShow ? true : false}
                                                  />
                                                  <Label
                                                    className="form-check-label"
                                                    htmlFor="customRadioInline1"
                                                  >
                                                    {this.props.t("Male")}
                                                  </Label>
                                                </div>
                                                &nbsp;
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    id="mac"
                                                    value="Female"
                                                    name="gender"
                                                    type="radio"
                                                    className="form-check-input"
                                                    defaultChecked={this.state.staff.gender == "Female" ? true : false}
                                                    onChange={this.handleGenderChange}
                                                    disabled={this.state.isShow ? true : false}
                                                  />
                                                  <Label
                                                    className="form-check-label"
                                                    htmlFor="customRadioInline1"
                                                  >
                                                    {this.props.t("Female")}
                                                  </Label>
                                                </div>
                                              </FormGroup>

                                            </Col>

                                          </Row>

                                          <Row>
                                            <Col md="12">
                                              <FormGroup className="mb-3">
                                                <AvField
                                                  name="address"
                                                  label={this.props.t("Address")}
                                                  type="textarea"
                                                  errorMessage={this.props.t("Invalid Address")}
                                                  rows="1"
                                                  placeholder={this.props.t("Address")}
                                                  value={this.state.staff.address || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>

                                          </Row>

                                          <Row>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <AvField
                                                  name="city"
                                                  label={this.props.t("City")}
                                                  type="text"
                                                  errorMessage={this.props.t("Invalid City")}
                                                  placeholder={this.props.t("City")}
                                                  value={this.state.staff.city || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <AvField
                                                  name="state"
                                                  label={this.props.t("Region")}
                                                  type="text"
                                                  /*  errorMessage={this.props.t("Invalid State")} */
                                                  placeholder={this.props.t("region")}
                                                  value={this.state.staff.state || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>

                                            <Col md="6">
                                              <FormGroup className="mb-3">

                                                <Label>{this.props.t("Designation")}</Label>
                                                <AvField
                                                  name="designation"
                                                  type="select"
                                                  placeholder={this.state.staff.designation}
                                                  //onChange={this.handleSelectGroup}
                                                  //options={optionGroup}
                                                  value={this.state.staff.designation}
                                                  isDisabled={this.state.isShow ? true : false}
                                                  errorMessage={this.props.t("Select a designation")}
                                                  className="form-control"

                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  disabled={this.state.isShow ? true : false}
                                                >

                                                  {optionGroup.map((e, key) => {
                                                    if (key == 0) {
                                                      return <option key={key} value="" disabled>{this.props.t("designation")}</option>;
                                                    } else {
                                                      return <option key={key} value={e.value}>{e.label}</option>;
                                                    }

                                                  })}
                                                </AvField>



                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label >
                                                  {this.props.t("Client ID number")}
                                                </Label>
                                                <AvField
                                                  name="id_number"
                                                  placeholder={this.props.t("Client ID number")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter Client ID number")}

                                                  disabled={this.state.isShow ? true : false}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={this.state.staff.id_number || ""}
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                            {!this.state.isShow ? <Label>{this.props.t("Upload ID")}</Label> : null}
                                            {this.state.isShow && this.state.staff.id_url !== "" ?
                                              <Row className="align-items-center">
                                                <Col md={8}>
                                                  <p className="mb-0"> {decodeURIComponent(this.state.staff.id_url).split("/").pop().split("?")[0]}</p>
                                                </Col>
                                                <Col md={4} className="text-end">
                                                  <Button
                                                    color="primary"
                                                    onClick={() => window.open(this.state.staff.id_url, "_blank")}
                                                    download

                                                  >
                                                    <i className="bx bx-download"></i>

                                                  </Button>
                                                </Col>
                                              </Row>
                                              :
                                              null
                                            }
                                            {!this.state.isShow && !this.state.isEdit ? <FormGroup className="mt-1 mb-2">

                                              <Input
                                                type="file"
                                                id="idCard"
                                                name="idCard"
                                                accept="image/*, application/pdf"
                                                onChange={(e) => this.handleFileChange(e, 'idCard')}
                                                className="mb-1"
                                              />
                                              <FormText color="muted">
                                                {this.props.t("Upload a clear image or PDF (Max size - 5MB)")}
                                              </FormText>
                                              {errors.idCard && (
                                                <Alert color="danger" className="mt-2">
                                                  {errors.idCard}
                                                </Alert>
                                              )}

                                            </FormGroup> : null

                                            }
                                            {!this.state.isShow && this.state.isEdit ? <> {this.state.staff.id_url !== "" ? <Row className="align-items-center">
                                              <Col md={8}>
                                                <p className="mb-0"> {decodeURIComponent(this.state.staff.id_url).split("/").pop().split("?")[0]}</p>
                                              </Col>
                                              <Col md={4} className="text-end">
                                                <Button
                                                  color="primary"
                                                  onClick={() => window.open(this.state.staff.id_url, "_blank")}
                                                  download

                                                >
                                                  <i className="bx bx-download"></i>

                                                </Button>
                                              </Col>
                                            </Row> : null}
                                              <FormGroup className="mt-1 mb-2">
                                                {this.state.staff.id_url !== "" ? <Label>{this.props.t("Replace file")}</Label> : null}<br />
                                                <Input
                                                  type="file"
                                                  id="idCard"
                                                  name="idCard"
                                                  accept="image/*, application/pdf"
                                                  onChange={(e) => this.handleFileChange(e, 'idCard')}
                                                  className="mb-1"
                                                />
                                                <FormText color="muted">
                                                  {this.props.t("Upload a clear image or PDF (Max size - 5MB)")}
                                                </FormText>
                                                {errors.idCard && (
                                                  <Alert color="danger" className="mt-2">
                                                    {errors.idCard}
                                                  </Alert>
                                                )}

                                              </FormGroup></> : null

                                            }
                                          </Row>

                                          {this.state.isShow ? null :
                                            <Row>
                                              <Col>
                                                <div className="text-end">

                                                  <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                  >
                                                    {this.props.t("Save")}
                                                  </button>
                                                </div>
                                              </Col>
                                            </Row>
                                          }

                                        </AvForm>
                                      }
                                    </ModalBody>
                                  </Modal>

                                  {this.state.isInvite ? (
                                    <SweetAlert
                                      title={this.props.t("Send invitation to")}
                                      showCancel
                                      confirmButtonText={this.props.t("Yes, invite!")}
                                      confirmBtnBsStyle="success"
                                      cancelBtnBsStyle="danger"
                                      onConfirm={() =>
                                        this.handleInviteUser(kra)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          isInvite: false,
                                        })
                                      }
                                    >
                                      {" " + kra.email + " ? "}
                                    </SweetAlert>
                                  ) : null}

                                  {this.state.isCinvite ? (
                                    <SweetAlert
                                      title={this.props.t("Cancel invitation sent to")}
                                      showCancel
                                      confirmButtonText={this.props.t("Yes, Cancel!")}
                                      confirmBtnBsStyle="success"
                                      cancelBtnBsStyle="danger"
                                      onConfirm={() =>
                                        this.handleCinviteUser(kra)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          isCinvite: false,
                                        })
                                      }
                                    >
                                      {" " + kra.email + " ? "}
                                    </SweetAlert>
                                  ) : null}


                                  {this.state.isDinvite ? (
                                    <SweetAlert
                                      title={this.props.t("Delete Admin privilige for")}
                                      showCancel
                                      confirmButtonText={this.props.t("Yes, Cancel!")}
                                      confirmBtnBsStyle="success"
                                      cancelBtnBsStyle="danger"
                                      onConfirm={() =>
                                        this.handleDinviteUser(kra)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          isDinvite: false,
                                        })
                                      }
                                    >
                                      {" " + kra.email + " ? "}
                                    </SweetAlert>
                                  ) : null}
                                  {this.state.isDelete ? (
                                    <SweetAlert
                                      title={this.props.t("Are you sure?")}
                                      warning
                                      showCancel
                                      confirmButtonText={this.props.t("Yes, delete it!")}
                                      confirmBtnBsStyle="success"
                                      cancelBtnBsStyle="danger"
                                      onConfirm={() =>
                                        this.handleDeleteStaff(vc)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          isDelete: false,
                                        })
                                      }
                                    >
                                      {this.props.t("You won't be able to revert this!")}
                                    </SweetAlert>
                                  ) : null}

                                  {this.state.no_data ? (
                                    <SweetAlert
                                      title={this.props.t("No data!")}
                                      warning
                                      onConfirm={() => this.setState({ no_data: false })}
                                    >
                                      {this.props.t("There is no data to export")}
                                    </SweetAlert>
                                  ) : null}

                                </div>
                                {/* <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div> */}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                  : null}

              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Staff.propTypes = {
  staffs: PropTypes.array,
  onGetStaff: PropTypes.func,
  onAddNewStaff: PropTypes.func,
  onDeleteStaff: PropTypes.func,
  onUpdateStaff: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  staffs: state.staff.staffs
})

const mapDispatchToProps = dispatch => ({
  onGetStaff: () => dispatch(getStaff()),
  onAddNewStaff: staff => dispatch(addNewStaff(staff)),
  onUpdateStaff: staff => dispatch(updateStaff(staff)),
  onDeleteStaff: staff => dispatch(deleteStaff(staff)),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Staff)))