import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import { withTranslation } from "react-i18next"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import
paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import maintanence from "../../assets/images/coming-soon.svg"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FormText, Alert, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Moment from 'moment';

import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";
import { Receipt } from "@material-ui/icons";

class Expenses extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vehicles: [],
            openGroups: [],
            currency: "",
            p_loading: false,
            modalDenied: false,
            expensePermissions: null,
            user_id: "",
            loading: true,
            submitLoading: false,
            isShow: false,
            changed: false,
            gender: "",
            viewmodal: false,
            modal: false,
            isDelete: false,
            no_data: false,
            success_dlg: false,
            dynamic_title: '',
            dynamic_description: '',
            vc: "",
            selectedGroup: null,
            expenses: [],
            expense: [],
            date_birth: new Date(),
            ExpenseColumns: [

                {
                    dataField: "label",
                    text: this.props.t("Label"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <Link to="#" className="text-body fw-bold">
                            {row.label}
                        </Link>
                    ),
                },
                {
                    dataField: "type",
                    isDummyField: true,
                    text: this.props.t("Expense type"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>
                            {" "}
                            {this.props.t(row.type)}
                        </>
                    ),
                },

                {
                    dataField: "date",
                    isDummyField: true,
                    text: this.props.t("Date created"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>
                            <i className="far fa-calendar-alt me-1"
                            />{" "}
                            {row.date}
                        </>
                    ),
                },
                {
                    dataField: "payment_date",
                    isDummyField: true,
                    text: this.props.t("Payment date"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>
                            <i className="far fa-calendar-alt me-1"
                            />{" "}
                            {row.payment_date}
                        </>
                    ),
                },
                {
                    dataField: "amount",
                    isDummyField: true,
                    text: this.props.t("Total amount"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>
                            {" "}
                            {row.amount} {this.state.currency || ""}
                        </>
                    ),
                },
                {
                    dataField: "view",
                    isDummyField: true,
                    text: this.props.t("View Details"),
                    sort: true,
                    formatter: (cellContent, expense) => (
                        <Button
                            type="button"
                            color="primary"
                            className="btn-sm btn-rounded"
                            onClick={() => this.handleViewClick(expense)}
                        >
                            {this.props.t("View Details")}
                        </Button>
                    ),
                },
                {
                    dataField: "action",
                    isDummyField: true,
                    text: this.props.t("Action"),
                    formatter: (cellContent, expense) => (
                        <>
                            <div className="d-flex gap-3">
                                <Link to="#" className="text-success">
                                    <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleExpenseClick(expense)} />
                                </Link>
                                <Link to="#" className="text-danger">
                                    <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.handleDeleteClick(expense)} />
                                </Link>
                            </div>
                        </>
                    ),
                },

            ],
            documents: {
                invoice: null,
                receipt: null,
                other: null
            },
            previews: {
                invoice: null,
                receipt: null,
                other: null
            },
            errors: {
                invoice: null,
                receipt: null,
                other: null
            },
            selectedFiles: {
                invoice: null,
                receipt: null,
                other: null
            },
            uploadProgress: {
                invoice: 0,
                receipt: 0,
                other: 0
            }/* ,
            selectedFiles: [] */
        }

        this.toggleDenied = this.toggleDenied.bind(this)
        this.handleExpenseClick = this.handleExpenseClick.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handleValidExpenseSubmit = this.handleValidExpenseSubmit.bind(this)
        this.handleExpenseClicks = this.handleExpenseClicks.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.handleViewClick = this.handleViewClick.bind(this)
        this.toggleIsShow = this.toggleIsShow.bind(this)
    }


    toggleDenied() {
        this.setState(prevState => ({
            modalDenied: !prevState.modalDenied,
        }))
    }
    handleDeleteClick = (expense) => {

        const { expensePermissions, p_loaded } = this.state;

        if (!p_loaded) {
            return; // Exit early if permissions are not yet loaded
        }

        if (expensePermissions === null || expensePermissions.delete) {
            this.setState({ isDelete: true, vc: expense });
        } else {
            this.setState({ modalDenied: true }, () => {
               //console..log("Permission denied");
            });
        }

    };
    /**
     * Formats the size
     */



    componentDidMount() {
        const { expenses, onGetExpense } = this.state
        if (expenses && !expenses.length) {
            //onGetExpense()
            this.getExpense()
        }
        this.getVehicle()
        this.setState({ expenses })
        this.getExpensePermissions()
    }
    getVehicle = async () => {
        this.setState({ loading: true });
        const branch = JSON.parse(localStorage.getItem("branch"));

        if (branch !== undefined && branch !== "") {
        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeVehicles) {
            this.unsubscribeVehicles();
        }

        this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        this.setState({ currency: userDoc.data().currency })
                        const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles").where("branchId","==",branch);
                        this.setState({
                            user_id: userId
                        })
                        this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
                            let vehicles = [];
                            snapshot.docs.forEach(doc => {
                                vehicles.push(doc.data());
                            });

                            this.setState({
                                loading: false,
                                vehicles: vehicles
                            });
                        });

                        //localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");
                        this.setState({
                            loading: false,
                            vehicles: []
                        });
                    }
                } catch (error) {
                   //console..error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the reservations if user is not authenticated
                this.setState({
                    loading: false,
                    vehicles: []
                });
            }
        });
        }else{
        //toast.error("Error")
this.setState({loading: false,});
        }
        
    }
    async getExpensePermissions() {
        try {
            this.setState({ p_loaded: false })
            firebase.auth().onAuthStateChanged(async user => {
                if (user) {
                    const userId = user.uid;
                    // Reference to the user document
                    const userDocRef = firebase.firestore().collection("users").doc(userId);

                    // Fetch the user document
                    const userSnapshot = await userDocRef.get();

                    if (!userSnapshot.exists) {
                        this.setState({ p_loaded: true })
                       //console..log("User document does not exist.");
                        return null; // Return null if user document doesn't exist
                    }

                    const userData = userSnapshot.data();

                    // Check if the user is an admin
                    if (userData.isAdmin) {
                        this.setState({ p_loaded: true })
                        //console.log("User is an admin; permissions are not restricted.");
                        return null; // Return null or handle differently for admins
                    } else {
                        const userD = await firebase.firestore().collection("users").doc(userData.id).get();
                        //console.log(userD.data())
                        this.setState({ currency: userD.data().currency })
                    }

                    // Reference to the permissions document for 'clients'
                    const permissionsDocRef = userDocRef.collection("permissions").doc("expenses");

                    // Fetch the document
                    const permissionsSnapshot = await permissionsDocRef.get();

                    if (permissionsSnapshot.exists) {
                        const expensePermissions = permissionsSnapshot.data();
                        /////console..log("Client Permissions:", clientPermissions);
                        this.setState({ expensePermissions });
                        this.setState({ p_loaded: true })
                        return expensePermissions; // Return the permissions as an object
                    } else {
                        this.setState({ p_loaded: true })
                       //console..log("No permissions found for 'expenses'.");
                        return null; // Return null if no permissions exist
                    }
                } else {
                    this.setState({ p_loaded: true })
                   //console..log("Not authenthicated")
                }
            })

        } catch (error) {
            this.setState({ p_loaded: true })
           //console..error("Error fetching expenses permissions:", error);
            throw error;
        }
    }

    getExpense = async () => {
        this.setState({ loading: true });
        const branch = JSON.parse(localStorage.getItem("branch"));

        if (branch !== undefined && branch !== "") {
        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeExpenses) {
            this.unsubscribeExpenses();
        }

        this.unsubscribeExpenses = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        this.setState({ currency: userDoc.data().currency })
                        const userExpensesCollectionRef = usersCollection.doc(userId).collection("expenses").where("branchId","==",branch);
                        this.setState({
                            user_id: userId,
                            account_user: userDoc.data()
                        })
                        this.unsubscribeExpenses = userExpensesCollectionRef.onSnapshot(snapshot => {
                            let t = [];
                            snapshot.docs.forEach(doc => {
                                t.push(doc.data());
                            });

                            this.setState({
                                loading: false,
                                expenses: t
                            });
                        });

                        //localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");
                        this.setState({
                            loading: false,
                            expenses: []
                        });
                    }
                } catch (error) {
                   //console..error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the reservations if user is not authenticated
                this.setState({
                    loading: false,
                    expenses: []
                });
            }
        });
        }else{
        //toast.error("Error")
this.setState({loading: false,});
        }
        

    }
    // eslint-disable-next-line no-unused-vars
    /* componentDidUpdate(prevProps, prevState, snapshot) {
        const { expenses } = this.state


    } */

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }
    toggleDelete() {
        this.setState(prevState => ({
            isDelete: !prevState.isDelete,
        }))
    }

    handleExpenseClicks = () => {

        const { expensePermissions, p_loaded } = this.state;

        if (!p_loaded) {
            return; // Exit early if permissions are not yet loaded
        }

        if (expensePermissions === null || expensePermissions.add) {
            this.setState({ expense: [], isEdit: false, isShow: false })

            this.toggle()
        } else {
            this.setState({ modalDenied: true }, () => {
               //console..log("Permission denied");
            });
        }


    }

    // eslint-disable-next-line no-unused-vars
    handleTableChange = (type, { page, searchText }) => {
        const { expenses } = this.props
        this.setState({
            expenses: expenses.filter(expense =>
                Object.keys(expense).some(
                    key =>
                        typeof expense[key] === "string" &&
                        expense[key].toLowerCase().includes(searchText.toLowerCase())
                )
            ),
        })
    }

    toggleViewModal = () => {
        this.setState(prevState => ({
            viewmodal: !prevState.viewmodal,
        }))
    }

    toggleIsShow = () => {
        this.setState(prevState => ({
            isShow: !prevState.isShow,
        }))
    }


    /* Insert,Update Delete data */

    handleDeleteExpense = (expense) => {


        const { onDeleteExpense } = this.state
        /* if (expense['code'] === '') {
            //
        } else { */
        //onDeleteExpense(expense)
        this.deleteExpense(expense)
        this.toggleDelete()
        //}

    }

    handleViewClick = arg => {
        const expense = arg
        //console.log(expense)
        const inv_url = expense.invoiceDoc ? expense.invoiceDoc.url : ""
        const rec_url = expense.receiptDoc ? expense.receiptDoc.url : ""
        const other_url = expense.otherDoc ? expense.otherDoc.url : ""
        this.setState({ selectedVehicle: { label: expense.vehicle, value: expense.plate_number } })
        this.setState({
            expense: {
                id: expense.id,
                code: expense.code,
                label: expense.label,
                type: expense.type,
                vehicle: expense.vehicle,
                date: expense.date,
                payment_date: expense.payment_date,
                pd: expense.pd,
                amount: expense.amount,
                inv_url: inv_url,
                rec_url: rec_url,
                other_url: other_url
            },

            isShow: true,
            isEdit: false
        })

        //this.toggleIsShow()
        this.toggle()

        //this.toggleViewModal()
    }


    handleExpenseClick = arg => {

        const { expensePermissions, p_loaded } = this.state;

        if (!p_loaded) {
            return; // Exit early if permissions are not yet loaded
        }

        if (expensePermissions === null || expensePermissions.update) {
            const expense = arg
            //console.log(expense)
            const inv_url = expense.invoiceDoc ? expense.invoiceDoc.url : ""
            const rec_url = expense.receiptDoc ? expense.receiptDoc.url : ""
            const other_url = expense.otherDoc ? expense.otherDoc.url : ""
            this.setState({ selectedVehicle: { label: expense.vehicle, value: expense.plate_number } })
            //this.setState({selectedGroup:staff.designation})
            this.setState({
                expense: {
                    id: expense.id,
                    code: expense.code,
                    label: expense.label,
                    vehicle: expense.vehicle,
                    type: expense.type,
                    date: expense.date,
                    pd: expense.pd,
                    payment_date: expense.payment_date,
                    notes: expense.notes,
                    amount: expense.amount,
                    inv_url: inv_url,
                    rec_url: rec_url,
                    other_url: other_url
                },
                selectedFiles: {
                    invoice: null,
                    receipt: null,
                    other: null
                },
                isEdit: true,
                isShow: false,
            })

            this.toggle()
        } else {
            this.setState({ modalDenied: true }, () => {
               //console..log("Permission denied");
            });
        }

    }

    /**
     * Handling submit Order on Order form
     */
    handleValidExpenseSubmit = (e, values) => {
        const { onAddNewExpense, onUpdateExpense } = this.props
        const { isEdit, expenses, selectedExpense, vehicles } = this.state
        const cds = []
        this.state.expenses.map(spl =>
            cds.push(spl.code)
        )
        const cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1
        /*  let des= this.state.selectedGroup.value
         if(this.state.changed){
           des= this.state.selectedGroup.value
           this.setState({changed:false})
         }else{
           des=this.state.selectedGroup
         }
          */
        const p = vehicles.find(v => v.id === values.vehicle);
        var plt = ""
        var vehicle = ""
        if (p !== undefined) {
            plt = p.plate_number
            vehicle = p.make + " (" + p.plate_number + ")"
        }

        var pd = new Date(values.payment_date).getTime()
        /* this.setState({
          gender:this.state.staffs.gender
        }) */
        var na = 0, naA = 0
        const foundna = expenses.filter(element => element.label === values.label && this.state.expense.id !== element.id);
        na = foundna.length


        const foundnaA = expenses.filter(element => element.label === values.label);
        naA = foundnaA.length
        if (isEdit) {
            //des=this.state.selectedGroup.defaultInputValue

            const updateExpense = {
                code: cd,
                id: this.state.expense.id,
                label: values.label.trim(),
                vehicle: values.vehicle,
                plate_number: plt,
                pd: pd,
                type: values.type,
                date: values.date,
                payment_date: values.payment_date,
                notes: values.notes.trim(),
                amount: parseInt(values.amount),
                invoice: this.state.selectedFiles.invoice,
                receipt: this.state.selectedFiles.receipt,
                other: this.state.selectedFiles.other
            }
//console.log(updateExpense.invoice,updateExpense.receipt,updateExpense.other)
            // update Order
            ///onUpdateExpense(updateExpense)
            if (this.state.expense.label == updateExpense.label
                && this.state.expense.vehicle == updateExpense.vehicle
                && this.state.expense.type == updateExpense.type
                && this.state.expense.date == updateExpense.date
                && this.state.expense.payment_date == updateExpense.payment_date
                && this.state.expense.amount == updateExpense.amount
                && this.state.expense.notes == updateExpense.notes
                &&  updateExpense.invoice==null
                &&  updateExpense.receipt==null
                &&  updateExpense.other==null
            ) {
                this.setState({ submitLoading: false })
                this.toggle()
            } else {
                this.updateExpense(updateExpense, na)
            }

        } else {

            const newExpense = {
                code: cd,
                label: values["label"],
                type: values["type"],
                vehicle: vehicle,
                plate_number: plt,
                v_id: values.vehicle,
                date: values["date"],
                pd: pd,
                payment_date: values["payment_date"],
                amount: parseInt(values["amount"]),
                notes: values["notes"],
            }
           //console..log(des)
            // save new Order
            //onAddNewExpense(newExpense)
            this.setState({ expense: newExpense })
            this.addNewExpense(newExpense, naA)
        }
        this.setState({ selectedExpense: null })
        //this.toggle()
    }

    deleteExpense = (expense) => {
       //console..log(supplier)
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("expenses")
        const timeline = {
            statusTitle: "Expense deleted",
            iconClass: "bx bx-money h2 text-danger",
            description: "Expense information has been deleted for ",
            data: expense.label,
            time: new Date().getTime(),

        }
        collection.doc(expense.id).delete().then(() => {

            //console.log("Element  deleted!");
            toast.success(this.props.t("Expense infos Deleted"))
            this.addNewTimeline(timeline)
            this.setState({
                success_dlg: true,
                dynamic_title: this.props.t("Deleted"),
                dynamic_description: this.props.t("Expense has been deleted."),
            })
        }).catch((error) => {
           //console..error("Error removing element: ", error);
            toast.error(this.props.t("Error removing element"))

        });

    }

    updateExpense = async (expense, na) => {
        this.setState({ submitLoading: true });
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("expenses");
        const timeline = {
            statusTitle: "Expense updated",
            iconClass: "bx bx-money h2 text-warning",
            description: "Expense information has been updated for ",
            data: expense.label,
            time: new Date().getTime(),
        };

        if (na == 0) {
            try {
                // Get current document to check for existing files
                const currentDoc = await collection.doc(expense.id).get();
                const currentData = currentDoc.data();

                // Handle invoice file update
                let invoiceData = currentData.invoiceDoc || null;
                if (this.state.selectedFiles.invoice) {
                    // Delete old file if it exists
                    if (currentData.invoiceDoc?.path) {
                        await this.deleteFile(currentData.invoiceDoc.path);
                    }
                    // Upload new file
                    invoiceData = await this.uploadFile(this.state.selectedFiles.invoice, 'invoice');
                }

                // Handle receipt file update
                let receiptData = currentData.receiptDoc || null;
                if (this.state.selectedFiles.receipt) {
                    // Delete old file if it exists
                    if (currentData.receiptDoc?.path) {
                        await this.deleteFile(currentData.receiptDoc.path);
                    }
                    // Upload new file
                    receiptData = await this.uploadFile(this.state.selectedFiles.receipt, 'receipt');
                }

                // Handle other file update
                let otherData = currentData.otherDoc || null;
                if (this.state.selectedFiles.other) {
                    // Delete old file if it exists
                    if (currentData.otherDoc?.path) {
                        await this.deleteFile(currentData.otherDoc.path);
                    }
                    // Upload new file
                    otherData = await this.uploadFile(this.state.selectedFiles.other, 'other');
                }

                // Update document with new data
                await collection.doc(expense.id).update({
                    code: expense.code,
                    label: expense.label,
                    type: expense.type,
                    vehicle: expense.vehicle,
                    date: expense.date,
                    pd: expense.pd,
                    payment_date: expense.payment_date,
                    amount: expense.amount,
                    notes: expense.notes,
                    // Update document fields only if new files were uploaded
                    ...(invoiceData && {
                        invoiceDoc: invoiceData
                    }),
                    ...(receiptData && {
                        receiptDoc: receiptData
                    }),
                    ...(otherData && {
                        otherDoc: otherData
                    })
                });

                toast.success(this.props.t("Expense updated"));
                this.addNewTimeline(timeline);
                this.setState({
                    submitLoading: false,
                    selectedFiles: { invoice: null, receipt: null, other: null },
                    uploadProgress: { invoice: 0, receipt: 0, other: 0 }
                });
                this.toggle();

            } catch (error) {
               //console..error("Error updating document: ", error);
                toast.error(this.props.t("Error updating document"));
                this.setState({ submitLoading: false });
            }
        } else {
            if (na != 0) {
               //console..error("Expense already exist");
                toast.error(this.props.t("Expense already exist"));
                this.setState({ submitLoading: false });
            }
        }
    }
    deleteFile = async (filePath) => {
        if (!filePath) return;

        try {
            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(filePath);
            await fileRef.delete();
        } catch (error) {
           //console..error("Error deleting file:", error);
            // Continue execution even if delete fails
        }
    };
    addNewTimeline = async (timeline) => {
        const branch = JSON.parse(localStorage.getItem("branch"));
  
    if (!branch) {
     //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
        try {
            const { user_id, account_user } = this.state;
            const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
            const newDoc = collection.doc();
            const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

            await newDoc.set({
                id: newDoc.id,
                statusTitle: timeline.statusTitle,
                iconClass: timeline.iconClass,
                description: timeline.description,
                time: timeline.time,
                data: timeline.data,
                branchId:branch,
                user: by
            });

            //console.log("Timeline  written!");
        } catch (error) {
           //console..error("Error adding timeline:", error);
        }
    }
    uploadFile = async (file, type) => {
        if (!file) return null;

        const storageRef = firebase.storage().ref();
        const fileExtension = file.name.split('.').pop();
        const fileName = `${this.state.user_id}/expenses-documents/${type}-${Date.now()}.${fileExtension}`;
        const fileRef = storageRef.child(fileName);

        try {
            const uploadTask = fileRef.put(file);

            // Monitor upload progress if needed
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.setState(prevState => ({
                        uploadProgress: {
                            ...prevState.uploadProgress,
                            [type]: progress
                        }
                    }));
                }
            );

            await uploadTask;
            const downloadURL = await fileRef.getDownloadURL();
            return { path: fileName, url: downloadURL };
        } catch (error) {
           //console..error(`Error uploading ${type}:`, error);
            toast.error(`Error uploading ${type}`);
            return null;
        }
    };

    addNewExpense = async (expense, na) => {
        const branch = JSON.parse(localStorage.getItem("branch"));
  
    if (!branch) {
     //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("expenses");
        this.setState({ submitLoading: true });

        const timeline = {
            statusTitle: "New Expense created",
            iconClass: "bx bx-money h2 text-success",
            description: "Expense information has been created ",
            data: expense.label,
            time: new Date().getTime(),
        }

        if (na == 0) {
            try {
                // Upload files first if they exist
                const [invoiceFile, receiptFile, otherFile] = await Promise.all([
                    this.uploadFile(this.state.selectedFiles.invoice, 'invoice'),
                    this.uploadFile(this.state.selectedFiles.receipt, 'receipt'),
                    this.uploadFile(this.state.selectedFiles.other, 'other')
                ]);

                var newDoc = collection.doc();
                await newDoc.set({
                    id: newDoc.id,
                    code: expense.code,
                    label: expense.label,
                    type: expense.type,
                    vehicle: expense.vehicle,
                    v_id: expense.v_id,
                    pd: expense.pd,
                    plate_number: expense.plate_number,
                    date: expense.date,
                    payment_date: expense.payment_date,
                    amount: expense.amount,
                    notes: expense.notes,
                    branchId:branch,
                    createdAt: new Date().getTime(),
                    // Add document data if files were uploaded
                    ...(invoiceFile && {
                        invoiceDoc: {
                            path: invoiceFile.path,
                            url: invoiceFile.url
                        }
                    }),
                    ...(receiptFile && {
                        receiptDoc: {
                            path: receiptFile.path,
                            url: receiptFile.url
                        }
                    }),
                    ...(otherFile && {
                        otherDoc: {
                            path: otherFile.path,
                            url: otherFile.url
                        }
                    })
                });

                toast.success(this.props.t("Expense added"));
                this.addNewTimeline(timeline);
                this.setState({
                    submitLoading: false,
                    selectedFiles: { invoice: null, receipt: null, other: null },
                    uploadProgress: { invoice: 0, receipt: 0, other: 0 }
                });
                this.toggle();

            } catch (error) {
               //console..error("Error Adding expense: ", error);
                toast.error(this.props.t("Error Adding Expense"));
                this.setState({ submitLoading: false });
            }
        } else {
            if (na != 0) {
               //console..error("Expense already exist");
                toast.error(this.props.t("Expense already exist"));
                this.setState({ submitLoading: false });
            }
        }
    }


    handleFileChange = (event, documentType) => {
        const file = event.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5MB limit

        if (file) {

            if (file.size > maxSize) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        [documentType]: 'File size should not exceed 5MB'
                    }
                }));
                return;
            }

            if (!file.type.match('image.*') && !file.type.match('application/pdf')) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        [documentType]: 'Please upload an image or PDF file'
                    }
                }));
                return;
            }

            this.setState(prevState => ({
                documents: {
                    ...prevState.documents,
                    [documentType]: file
                },
                errors: {
                    ...prevState.errors,
                    [documentType]: ''
                }
            }));
            this.setState(prevState => ({
                selectedFiles: {
                    ...prevState.selectedFiles,
                    [documentType]: file
                }
            }));

            // Create preview for images only
            if (file.type.match('image.*')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.setState(prevState => ({
                        previews: {
                            ...prevState.previews,
                            [documentType]: reader.result
                        }
                    }));
                };
                reader.readAsDataURL(file);
            } else {
                this.setState(prevState => ({
                    previews: {
                        ...prevState.previews,
                        [documentType]: null
                    }
                }));
            }
        }
    }
    render() {

        const emptyDataMessage = () => {

            return <Container>
                <Row>
                    <Col lg="12">
                        {this.state.loading ?
                            <div className="text-center">
                                <Row className="justify-content-center mt-5">
                                    <Col sm="4">
                                        <div>
                                            <div >
                                                <div className="spinner-chase">
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            :
                            <div className="text-center">

                                <Row className="justify-content-center mt-5">
                                    <Col sm="4">
                                        <div className="maintenance-img">
                                            <img
                                                src={maintanence}
                                                alt=""
                                                className="img-fluid mx-auto d-block"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                                <p className="text-muted">
                                    {this.props.t("Start adding your Expenses by clicking")}<h5 className="text-primary"><Link to="#" onClick={this.handleExpenseClicks} >{this.props.t("New Expense")}</Link></h5>
                                </p>


                            </div>
                        }

                    </Col>
                </Row>
            </Container>


                ;
        }

        //console.log(this.state.expense)
        const { errors, previews, vehicles, currency, expenses } = this.state
        const data = expenses;
        let dataxcl = []
        data.map(function (item) {
            delete item.expense;
            dataxcl.push({
                label: item.label,
                type: item.type,
                vehicle: item.vehicle,
                date: item.date,
                payment_date: item.payment_date,
                amount: item.amount,
                notes: item.notes,
            })
        });

        const { selectedGroup } = this.state
        const { SearchBar } = Search
        const { isEdit } = this.state
        const { isDelete } = this.state
        const { vc } = this.state

        //pagination customization
        let vcList = []
        vcList.push({ label: "", value: "" })
        vehicles.map(function (item, i) {
            vcList.push({ id: item.id, label: item.make + ' (' + item.plate_number + ')', value: item.plate_number })

        })

        const defaultSorted = [{
            dataField: 'daily_rate',
            order: 'asc'
        }];

        const selectRow = {
            mode: 'checkbox',
        };
        function ExportToExcel() {
            exportFromJSON({ data: dataxcl, fileName: 'Expenses', exportType: exportFromJSON.types.xls })
        }
        function exportPDF() {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 20;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(15);

            const title = "Expenses Infos";
            const headers = [["Expense Label", "Type", "Vehicle", "Date Created", "Payment date", "Amount", "Notes"]];

            const dt = data.map(elt => [elt.label, elt.type, elt.vehicle, elt.date, elt.payment_date, elt.amount, elt.notes]);

            let content = {
                startY: 50,
                head: headers,
                body: dt
            };

            doc.text(title, marginLeft, 20);
            doc.autoTable(content);
            doc.save("Expenses.pdf")
        }
        function printPDF() {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 20;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(15);

            const title = "Expenses Infos";
            const headers = [["Expense Label", "Type", "vehicle", "Date created", "Payment date", "amount", "notes"]];

            const dt = data.map(elt => [elt.label, elt.type, elt.vehicle, elt.date, elt.payment_date, elt.amount, elt.notes]);

            let content = {
                startY: 50,
                head: headers,
                body: dt
            };

            doc.text(title, marginLeft, 20);
            doc.autoTable(content);
            var string = doc.output('datauristring');
            var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
            var x = window.open();
            x.document.open();
            x.document.write(embed);
            x.document.close();

        }

        return (

            <React.Fragment>


                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Expenses | Medios - Car Rental Management System")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Rental")} breadcrumbItem={this.props.t("Expenses")} />
                        {this.state.success_dlg ? (
                            <SweetAlert
                                success
                                title={this.state.dynamic_title}
                                onConfirm={() => this.setState({ success_dlg: false })}
                            >
                                {this.state.dynamic_description}
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory()}
                                            keyField='id'
                                            columns={(this.state.ExpenseColumns || [])}
                                            data={(expenses || [])}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={expenses}
                                                    columns={(this.state.ExpenseColumns || [])}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2" class="noPrint">
                                                                <Col sm="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm="8" >

                                                                    <div className="text-sm-end" >
                                                                        <Button
                                                                            type="button"
                                                                            color="success"
                                                                            className="btn-rounded mb-2 me-2"
                                                                            onClick={this.handleExpenseClicks}
                                                                        >
                                                                            <i className="mdi mdi-plus me-1" />{" "}
                                                                            {this.props.t("New Expense")}
                                                                        </Button>

                                                                        <Link to="#"

                                                                        >

                                                                            {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                                                            <td>
                                                                                <UncontrolledDropdown>
                                                                                    <DropdownToggle href="#" className="card-drop" tag="a">
                                                                                        <i className="mdi mdi-dots-vertical font-size-18" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu className="dropdown-menu-end">
                                                                                        <DropdownItem href="#" onClick={() => this.state.expenses.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                                                                            <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                                                                            {this.props.t("Export PDF")}

                                                                                        </DropdownItem>
                                                                                        <DropdownItem href="#" onClick={() => this.state.expenses.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                                                                            <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                                                                            {this.props.t("Export Excel")}
                                                                                        </DropdownItem>
                                                                                        <DropdownItem href="#" onClick={() => this.state.expenses.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                                                                            <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                                                                            {this.props.t("Print")}
                                                                                        </DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>

                                                                            </td>
                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="table-responsive">
                                                                <BootstrapTable

                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    defaultSorted={defaultSorted}
                                                                    bordered={false}
                                                                    striped={false}
                                                                    noDataIndication={emptyDataMessage}
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                />

                                                                <Modal
                                                                    isOpen={this.state.modalDenied}
                                                                    role="alert"
                                                                    autoFocus={true}
                                                                    data-toggle="modalDenied"
                                                                    centered
                                                                    size="md"
                                                                >
                                                                    <ModalHeader toggle={this.toggleDenied} tag="h4">     </ModalHeader>

                                                                    <Row className="justify-content-center">
                                                                        <Col >

                                                                            <div className="p-2 mt-4 mb-0">
                                                                                <div className="text-center">
                                                                                    <div className="avatar-md mx-auto">
                                                                                        <div className="avatar-title rounded-circle bg-light">
                                                                                            <i className="mdi mdi-shield-lock-outline h1 mt-4 text-primary mdi-48px"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="p-2 mt-3 mb-0">
                                                                                        <h4>{this.props.t("Permission Denied !")}</h4>
                                                                                        <br />
                                                                                        <p>


                                                                                            {this.props.t("You do not have the required permissions to perform this action.")}<br /> {this.props.t("Please contact the administrator for assistance.")}

                                                                                        </p>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                    </Row>
                                                                </Modal>
                                                                <Modal
                                                                    isOpen={this.state.modal}
                                                                    className={this.props.className}
                                                                    size='md'
                                                                >
                                                                    <ModalHeader toggle={this.toggle} tag="h4">
                                                                        {!!isEdit ? this.props.t("Edit Expenses") : null}
                                                                        {!!this.state.isShow ? this.props.t("Expenses Infos") : null}
                                                                        {!isEdit && !this.state.isShow ? this.props.t("Add Expense") : null}
                                                                    </ModalHeader>
                                                                    <ModalBody>
                                                                        {this.state.submitLoading ?

                                                                            <Col>
                                                                                <div>
                                                                                    <div >
                                                                                        <div className="spinner-chase">
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                                                            </Col>
                                                                            :
                                                                            <AvForm
                                                                                onValidSubmit={
                                                                                    this.handleValidExpenseSubmit
                                                                                }
                                                                                disabled={this.state.isShow ? true : false}
                                                                            >
                                                                                <Row>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label htmlFor="validationCustom01">
                                                                                                {this.props.t("Expense label")}
                                                                                            </Label>
                                                                                            <AvField
                                                                                                name="label"
                                                                                                placeholder={this.props.t("Expense label")}
                                                                                                type="text"
                                                                                                errorMessage={this.props.t("Enter Expense label")}
                                                                                                className="form-control"
                                                                                                validate={{ required: { value: true } }}
                                                                                                id="validationCustom01"
                                                                                                value={this.state.expense.label || ""}

                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <AvField type="select" name="type" label="Expense type"


                                                                                            errorMessage={this.props.t("Select expense type")}
                                                                                            className="form-control"
                                                                                            value={this.state.expense.type}
                                                                                            validate={{
                                                                                                required: { value: true },
                                                                                            }}>
                                                                                            <option value="" disabled>
                                                                                                {this.props.t("-- Select an Expense --")}
                                                                                            </option>

                                                                                            {/* Vehicle-Related Expenses */}
                                                                                            <optgroup label={this.props.t("Vehicle")}>

                                                                                                <option value="Car Wash">{this.props.t("Car Wash")}</option>
                                                                                                <option value="Fuel">{this.props.t("Fuel")}</option>
                                                                                                <option value="Parking Fees">{this.props.t("Parking Fees")}</option>
                                                                                                <option value="Transport Service">{this.props.t("Transport Service")}</option>
                                                                                                <option value="Vehicle Registration">{this.props.t("Vehicle Registration")}</option>
                                                                                                <option value="Road Tolls">{this.props.t("Road Tolls")}</option>
                                                                                                <option value="Vehicle Accessories">{this.props.t("Vehicle Accessories")}</option>
                                                                                                <option value="Battery Service">{this.props.t("Battery Service")}</option>
                                                                                                <option value="Special Permits">{this.props.t("Special Permits")}</option>
                                                                                                <option value="GPS/Navigation">{this.props.t("GPS/Navigation")}</option>
                                                                                            </optgroup>

                                                                                            <optgroup label={this.props.t("Operations")}>
                                                                                                <option value="Office Supplies" >{this.props.t("Office Supplies")}</option>
                                                                                                <option value="Utilities" >{this.props.t("Utilities")}</option>
                                                                                                <option value="Marketing" >{this.props.t("Marketing")}</option>
                                                                                                <option value="Training" >{this.props.t("Training")}</option>
                                                                                            </optgroup>

                                                                                            <optgroup label={this.props.t("Customer")}>
                                                                                                <option value="Complimentary Services" >{this.props.t("Complimentary Services")}</option>
                                                                                                <option value="Customer Rewards" >{this.props.t("Customer Rewards")}</option>
                                                                                            </optgroup>

                                                                                            <optgroup label={this.props.t("Administrative")}>
                                                                                                <option value="Legal Fees" >{this.props.t("Legal Fees")}</option>
                                                                                                <option value="Accounting Fees" >{this.props.t("Accounting Fees")}</option>
                                                                                                <option value="Software" >{this.props.t("Software")}</option>
                                                                                            </optgroup>

                                                                                            <optgroup label={this.props.t("Miscellaneous")}>
                                                                                                <option value="Security" >{this.props.t("Security")}</option>
                                                                                                <option value="Events" >{this.props.t("Events")}</option>
                                                                                                <option value="Refund Fees" >{this.props.t("Refund Fees")}</option>
                                                                                                <option value="Temp Staff" >{this.props.t("Temp Staff")}</option>
                                                                                            </optgroup>

                                                                                            <optgroup label={this.props.t("Other")}>
                                                                                                <option value="Other" >{this.props.t("Other")}</option>
                                                                                            </optgroup>
                                                                                        </AvField>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md="6" className="mb-2">
                                                                                        <Label>{this.props.t("Vehicle")}</Label>
                                                                                        {!isEdit && !this.state.isShow ?
                                                                                            <div>
                                                                                                <AvField
                                                                                                    name="vehicle"
                                                                                                    type="select"
                                                                                                    placeholder={""}
                                                                                                    //onChange={this.handleSelectVehicle}
                                                                                                    //options={vcList}
                                                                                                    value={this.state.expense.vehicle}
                                                                                                    disabled={this.state.isShow || !!isEdit ? true : false}
                                                                                                    errorMessage={this.props.t("Select a Vehicle")}
                                                                                                    className="form-control"

                                                                                                /* validate={{
                                                                                                    required: { value: true },
                                                                                                }} */
                                                                                                >

                                                                                                    {vcList.map((e, key) => {
                                                                                                        if (key == 0) {
                                                                                                            return <option key={key} value="" disabled>{this.props.t("vehicle")}</option>;
                                                                                                        } else {
                                                                                                            return <option key={key} value={e.id}>{e.label}</option>;
                                                                                                        }
                                                                                                    })}
                                                                                                </AvField>
                                                                                                <Link to="/vehicles" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Vehicle")}</Link>
                                                                                            </div> : <AvField
                                                                                                name="vehicle"
                                                                                                type="text"
                                                                                                disabled={this.state.isShow || !!isEdit ? true : false}
                                                                                                value={this.state.expense.vehicle || ""}
                                                                                            />
                                                                                        }
                                                                                    </Col>
                                                                                    <Col md="6">

                                                                                        <FormGroup className="mb-3">
                                                                                            <Label htmlFor="validationCustom01">
                                                                                                {this.props.t("Amount")}
                                                                                            </Label>
                                                                                            <AvField
                                                                                                name="amount"
                                                                                                placeholder={this.props.t("Amount")}
                                                                                                type="number"
                                                                                                errorMessage={this.props.t("Enter amount")}
                                                                                                className="form-control"
                                                                                                validate={{ required: { value: true } }}
                                                                                                id="validationCustom01"
                                                                                                value={this.state.expense.amount || ""}

                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>



                                                                                <Row>

                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-4">
                                                                                            <Label> {this.props.t("Date")}</Label>
                                                                                            <AvField
                                                                                                name="date"
                                                                                                type="date"
                                                                                                value={Moment(this.state.expense.date).format('YYYY-MM-DD') || ""}
                                                                                                //defaultValue={this.state.expense.date || ""}
                                                                                                errorMessage={this.props.t("Select date created")}
                                                                                                //onChange={this.handleSDChange}
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                validate={{
                                                                                                    required: { value: true },
                                                                                                }}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>

                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-4">
                                                                                            <Label>{this.props.t("Payment date")}</Label>
                                                                                            <AvField
                                                                                                name="payment_date"
                                                                                                placeholder={this.props.t("Payment date")}
                                                                                                type="date"
                                                                                                validate={{
                                                                                                    required: { value: true },
                                                                                                }}
                                                                                                value={Moment(this.state.expense.payment_date).format('YYYY-MM-DD') || ""}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>


                                                                                </Row>

                                                                                <Row>

                                                                                    {!this.state.isShow ? <Label>{this.props.t("Upload invoice")}</Label> : null}
                                                                                    {this.state.isShow && this.state.expense.inv_url !== "" ?

                                                                                        <Row className="align-items-center">
                                                                                            <Col md={8}>
                                                                                                <p className="mb-0"> {decodeURIComponent(this.state.expense.inv_url).split("/").pop().split("?")[0]}</p>
                                                                                            </Col>
                                                                                            <Col md={4} className="text-end">
                                                                                                <Button
                                                                                                    color="primary"
                                                                                                    onClick={() => window.open(this.state.expense.inv_url, "_blank")}
                                                                                                    download

                                                                                                >
                                                                                                    <i className="bx bx-download"></i>

                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>

                                                                                        : null}
                                                                                    {!this.state.isShow && !this.state.isEdit ? <FormGroup className="">

                                                                                        <Input
                                                                                            type="file"
                                                                                            id="invoice"
                                                                                            name="invoice"
                                                                                            accept="image/*, application/pdf"
                                                                                            onChange={(e) => this.handleFileChange(e, 'invoice')}
                                                                                            className=""
                                                                                        />
                                                                                        <FormText color="muted">
                                                                                            {this.props.t("Upload a clear image or PDF (Max size - 5MB)")}
                                                                                        </FormText>
                                                                                        {errors.invoice && (
                                                                                            <Alert color="danger" className="mt-2">
                                                                                                {errors.invoice}
                                                                                            </Alert>
                                                                                        )}

                                                                                    </FormGroup> : null}
                                                                                    {!this.state.isShow && this.state.isEdit ? <> {this.state.expense.inv_url !== "" ? <Row className="align-items-center">
                                                                                        <Col md={8}>
                                                                                            <p className="mb-0"> {decodeURIComponent(this.state.expense.inv_url).split("/").pop().split("?")[0]}</p>
                                                                                        </Col>
                                                                                        <Col md={4} className="text-end">
                                                                                            <Button
                                                                                                color="primary"
                                                                                                onClick={() => window.open(this.state.expense.inv_url, "_blank")}
                                                                                                download

                                                                                            >
                                                                                                <i className="bx bx-download"></i>

                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row> : null}
                                                                                        <FormGroup className="">
                                                                                            {this.state.expense.inv_url !== "" ? <Label>{this.props.t("Replace file")}</Label> : null}<br />
                                                                                            <Input
                                                                                                type="file"
                                                                                                id="invoice"
                                                                                                name="invoice"
                                                                                                accept="image/*, application/pdf"
                                                                                                onChange={(e) => this.handleFileChange(e, 'invoice')}
                                                                                                className="mb-1"
                                                                                            />
                                                                                            <FormText color="muted">
                                                                                                {this.props.t("Upload a clear image or PDF (Max size - 5MB)")}
                                                                                            </FormText>
                                                                                            {errors.invoice && (
                                                                                                <Alert color="danger" className="mt-2">
                                                                                                    {errors.invoice}
                                                                                                </Alert>
                                                                                            )}

                                                                                        </FormGroup></> : null

                                                                                    }
                                                                                </Row>
                                                                                
                                                                                <Row className="mt-2">

                                                                                    {!this.state.isShow ? <Label>{this.props.t("Upload receipt")}</Label> : null}
                                                                                    {this.state.isShow && this.state.expense.rec_url !== "" ?

                                                                                        <Row className="align-items-center">
                                                                                            <Col md={8}>
                                                                                                <p className="mb-0"> {decodeURIComponent(this.state.expense.rec_url).split("/").pop().split("?")[0]}</p>
                                                                                            </Col>
                                                                                            <Col md={4} className="text-end">
                                                                                                <Button
                                                                                                    color="primary"
                                                                                                    onClick={() => window.open(this.state.expense.rec_url, "_blank")}
                                                                                                    download

                                                                                                >
                                                                                                    <i className="bx bx-download"></i>

                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>

                                                                                        : null}
                                                                                    {!this.state.isShow && !this.state.isEdit ? <FormGroup className="">

                                                                                        <Input
                                                                                            type="file"
                                                                                            id="receipt"
                                                                                            name="receipt"
                                                                                            accept="image/*, application/pdf"
                                                                                            onChange={(e) => this.handleFileChange(e, 'receipt')}
                                                                                            className=""
                                                                                        />
                                                                                        <FormText color="muted">
                                                                                            {this.props.t("Upload a clear image or PDF (Max size - 5MB)")}
                                                                                        </FormText>
                                                                                        {errors.receipt && (
                                                                                            <Alert color="danger" className="mt-2">
                                                                                                {errors.receipt}
                                                                                            </Alert>
                                                                                        )}

                                                                                    </FormGroup> : null}
                                                                                    {!this.state.isShow && this.state.isEdit ? <> {this.state.expense.rec_url !== "" ? <Row className="align-items-center">
                                                                                        <Col md={8}>
                                                                                            <p className="mb-0"> {decodeURIComponent(this.state.expense.rec_url).split("/").pop().split("?")[0]}</p>
                                                                                        </Col>
                                                                                        <Col md={4} className="text-end">
                                                                                            <Button
                                                                                                color="primary"
                                                                                                onClick={() => window.open(this.state.expense.rec_url, "_blank")}
                                                                                                download

                                                                                            >
                                                                                                <i className="bx bx-download"></i>

                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row> : null}
                                                                                        <FormGroup className="">
                                                                                            {this.state.expense.rec_url !== "" ? <Label>{this.props.t("Replace file")}</Label> : null}<br />
                                                                                            <Input
                                                                                                type="file"
                                                                                                id="receipt"
                                                                                                name="receipt"
                                                                                                accept="image/*, application/pdf"
                                                                                                onChange={(e) => this.handleFileChange(e, 'receipt')}
                                                                                                className=""
                                                                                            />
                                                                                            <FormText color="muted">
                                                                                                {this.props.t("Upload a clear image or PDF (Max size - 5MB)")}
                                                                                            </FormText>
                                                                                            {errors.receipt && (
                                                                                                <Alert color="danger" className="mt-2">
                                                                                                    {errors.receipt}
                                                                                                </Alert>
                                                                                            )}

                                                                                        </FormGroup></> : null

                                                                                    }
                                                                                </Row>
                                                                               
                                                                                <Row className="">
                                                                                    {!this.state.isShow ? <Label>{this.props.t("Other Document")}</Label> : null}

                                                                                    {this.state.isShow && this.state.expense.other_url !== "" ?

                                                                                        <Row className="align-items-center">
                                                                                            <Col md={8}>
                                                                                                <p className="mb-0"> {decodeURIComponent(this.state.expense.other_url).split("/").pop().split("?")[0]}</p>
                                                                                            </Col>
                                                                                            <Col md={4} className="text-end">
                                                                                                <Button
                                                                                                    color="primary"
                                                                                                    onClick={() => window.open(this.state.expense.other_url, "_blank")}
                                                                                                    download

                                                                                                >
                                                                                                    <i className="bx bx-download"></i>

                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>

                                                                                        : null}
                                                                                    {!this.state.isShow && !this.state.isEdit ? <FormGroup className=" ">

                                                                                        <Input
                                                                                            type="file"
                                                                                            id="other"
                                                                                            name="other"
                                                                                            accept="image/*, application/pdf"
                                                                                            onChange={(e) => this.handleFileChange(e, 'other')}
                                                                                            className=""
                                                                                        />
                                                                                        <FormText color="muted">
                                                                                            {this.props.t("Upload a clear image or PDF (Max size - 5MB)")}
                                                                                        </FormText>
                                                                                        {errors.other && (
                                                                                            <Alert color="danger" className="mt-2">
                                                                                                {errors.other}
                                                                                            </Alert>
                                                                                        )}

                                                                                    </FormGroup> : null}
                                                                                    {!this.state.isShow && this.state.isEdit ? <> {this.state.expense.other_url !== "" ? <Row className="align-items-center">
                                                                                        <Col md={8}>
                                                                                            <p className="mb-0"> {decodeURIComponent(this.state.expense.other_url).split("/").pop().split("?")[0]}</p>
                                                                                        </Col>
                                                                                        <Col md={4} className="text-end">
                                                                                            <Button
                                                                                                color="primary"
                                                                                                onClick={() => window.open(this.state.expense.other_url, "_blank")}
                                                                                                download

                                                                                            >
                                                                                                <i className="bx bx-download"></i>

                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row> : null}
                                                                                        <FormGroup className="">
                                                                                            {this.state.expense.other_url !== "" ? <Label>{this.props.t("Replace file")}</Label> : null}<br />
                                                                                            <Input
                                                                                                type="file"
                                                                                                id="other"
                                                                                                name="other"
                                                                                                accept="image/*, application/pdf"
                                                                                                onChange={(e) => this.handleFileChange(e, 'other')}
                                                                                                className=""
                                                                                            />
                                                                                            <FormText color="muted">
                                                                                                {this.props.t("Upload a clear image or PDF (Max size - 5MB)")}
                                                                                            </FormText>
                                                                                            {errors.other && (
                                                                                                <Alert color="danger" className="mt-2">
                                                                                                    {errors.other}
                                                                                                </Alert>
                                                                                            )}

                                                                                        </FormGroup></> : null

                                                                                    }
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md="12">

                                                                                        <FormGroup className="mb-3">
                                                                                            <Label htmlFor="validationCustom03">
                                                                                                {this.props.t("Notes")}
                                                                                            </Label>
                                                                                            <AvField
                                                                                                name="notes"
                                                                                                placeholder={this.props.t("Notes")}
                                                                                                type="textarea"
                                                                                                rows="2"
                                                                                                value={this.state.expense.notes || ""}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>


                                                                                </Row>



                                                                                {this.state.isShow ? null :
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">

                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    {this.props.t("Save")}
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }

                                                                            </AvForm>
                                                                        }
                                                                    </ModalBody>
                                                                </Modal>


                                                                {this.state.isDelete ? (
                                                                    <SweetAlert
                                                                        title={this.props.t("Are you sure?")}
                                                                        warning
                                                                        showCancel
                                                                        confirmButtonText={this.props.t("Yes, delete it!")}
                                                                        confirmBtnBsStyle="success"
                                                                        cancelBtnBsStyle="danger"
                                                                        onConfirm={() =>
                                                                            this.handleDeleteExpense(vc)
                                                                        }
                                                                        onCancel={() =>
                                                                            this.setState({
                                                                                isDelete: false,
                                                                            })
                                                                        }
                                                                    >
                                                                        {this.props.t("You won't be able to revert this!")}
                                                                    </SweetAlert>
                                                                ) : null}
                                                                {this.state.no_data ? (
                                                                    <SweetAlert
                                                                        title={this.props.t("No data!")}
                                                                        warning
                                                                        onConfirm={() => this.setState({ no_data: false })}
                                                                    >
                                                                        {this.props.t("There is no data to export")}
                                                                    </SweetAlert>
                                                                ) : null}

                                                            </div>

                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                />
            </React.Fragment>
        )
    }
}

Expenses.propTypes = {
    expenses: PropTypes.array,
    onGetExpense: PropTypes.func,
    onAddNewExpense: PropTypes.func,
    onDeleteExpense: PropTypes.func,
    onUpdateExpense: PropTypes.func,
    className: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = state => ({
    expenses: state.rental.expenses
})

const mapDispatchToProps = dispatch => ({
    onGetExpense: () => dispatch(getExpense()),
    onAddNewExpense: expense => dispatch(addNewExpense(expense)),
    onUpdateExpense: expense => dispatch(updateExpense(expense)),
    onDeleteExpense: expense => dispatch(deleteExpense(expense)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(Expenses)))
