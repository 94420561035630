import PropTypes from 'prop-types'
import React, { Component } from "react"
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import * as moment from 'moment';
import { connect } from "react-redux"
import { Form, FormGroup, Label, Row, Col, Table, Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import toast, { Toaster } from 'react-hot-toast';

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from '../CommonForBoth/RightSidebar'

import megamenuImg from "../../assets/images/megamenu-img.png"


// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import logo from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"

//i18n
import { withTranslation } from "react-i18next"
import Tour from 'reactour';

// Redux Store
import { toggleRightSidebar } from "../../store/actions"

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj;
  }
}
const steps = [
  {
    selector: '.languages',
    content: 'Language Selection - Customize the language settings.',
  },
  {
    selector: '.full_screen',
    content: 'Full Screen Mode - Expand the dashboard for a better view.',
  },
  {
    selector: '.notifications',
    content: 'Notifications - Stay updated with important alerts and messages.',
  },
  {
    selector: '.profile_menu',
    content: 'Profile Menu - Access your personal settings and preferences.',
  }
];

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      av_vehicles: [],
      checking: false,
      pdchanged: false,
      rdchanged: false,
      PD: new Date(),
      RD: new Date().setDate(new Date().getDate() + 1),
      isTourOpen: true,
      isSearch: false,
      open: false,
      position: 'right',
      name: "",
      user: [],
      modal: false,
      loadingUrl: false,
      AvModal: false,
      hasClicked: false,
      admin:false,
    }
    this.handlePDChange = this.handlePDChange.bind(this)
    this.handleRDChange = this.handleRDChange.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.toggleAvailability = this.toggleAvailability.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
  }
  handlePDChange = PD => {

    this.setState({ PD: PD.target.value })
    this.setState({ pdchanged: true })

  }
  handleRDChange = RD => {

    this.setState({ RD: RD.target.value })
    this.setState({ rdchanged: true })
  }
  handleCheck = async (e, values) => {
    this.setState({ checking: true, hasClicked: true })
    const sdate = new Date(values.startDate);
    const edate = new Date(values.endDate);
    const sd = Math.floor(sdate.getTime());
    const ed = Math.floor(edate.getTime());

    const result = await this.checkAvailability(sd, ed);
    if (result.success) {
      this.setState({ checking: false })
      // Use result.availableVehicles
      this.setState({ av_vehicles: result.availableVehicles })
      //console.log('Available vehicles:', result.availableVehicles);
    } else {
      this.setState({ checking: false, av_vehicles: [] })
      // Handle the error
      toast.error(this.props.t(result.error))
      //console.error('Error:', result.error);
    }
  }
  handleTourStart = () => {
    this.setState({ isTourOpen: true });
  };

  handleTourEnd = () => {
    this.setState({ isTourOpen: false });
  };
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }
  toggleAvailability() {
    this.setState(prevState => ({
      AvModal: !prevState.AvModal
    }))
    this.setState({ av_vehicles: [], hasClicked: false })
  }
  getUser = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            this.setState({
              admin:userDoc.data().isAdmin
            })
            const userId = userDoc.data().id;
            const userUsersCollectionRef = usersCollection.doc(userId)

            this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
              let kra = snapshot.data()
              this.setState({
                loading: false,
                user: kra,
              })
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback()
  }
  /** 
   * Right sidebar drawer 
  * */

  toggleRightDrawer() {
    this.setState({ position: 'right' });
    this.setState({ open: !this.state.open });
  }
  closeDrawer() {
    this.setState({ open: false });
  }
  onDrawerClose() {
    this.setState({ open: false });
  }


  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar()
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      //console.log(userData)
      this.setState({ name: userData.username })
    }
    const { user } = this.state
    if (user && !user.length) {
      //onGetStaff()
      this.getUser()
    }
    this.setState({ user })
  }
  isSameOrFutureDate = (date1, date2) => {
    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return d1.getTime() >= d2.getTime();
  }
  async checkAvailability(startDate, endDate) {

    // Convert epoch timestamps to Date objects for comparison
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    const now = new Date();
    //console.log('Current date:', now.toDateString());
    //console.log('Start date:', startDateTime.toDateString());
    //console.log('End date:', endDateTime.toDateString());
    // Validate input dates
    if (!this.isSameOrFutureDate(startDateTime, now) || !this.isSameOrFutureDate(endDateTime, now)) {
      //console.log("Start and end dates must be today or in the future")
      return { success: false, error: "Start and end dates must be today or in the future" };
    }

    if (startDate >= endDate) {
      //console.log("End date must be after start date")
      return { success: false, error: "End date must be after start date" };
    }
    try {
      // Get all vehicles
      const allVehicles = await this.getAllVehicles();

      // Get relevant reservations
      const relevantReservations = await this.getRelevantReservations(startDate, endDate);

      // Filter available vehicles
      const availableVehicles = allVehicles.filter(vehicle =>
        this.isVehicleAvailable(vehicle, relevantReservations, startDate, endDate)
      ); //console.log('Available vehicles:', availableVehicles);
      return { success: true, availableVehicles };
    } catch (error) {
     //console..error("Error in checkAvailability:", error);
      return { success: false, error: "An error occurred while checking availability" };
    }
  }

  // Helper function to get all vehicles from Firestore
  async getAllVehicles() {
    const branch = JSON.parse(localStorage.getItem("branch"));

    if (!branch) {
      toast.error("Error: Branch not found");
      return;
    }
    try {
      const vehiclesRef = firebase.firestore().collection("users").doc(this.state.user.id).collection('vehicles').where("branchId", "==", branch);
      const vehiclesSnapshot = await vehiclesRef.get();
      return vehiclesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      //console.error("Error fetching vehicles:", error);
      throw error;
    }
  }

  // Helper function to get relevant reservations
  async getRelevantReservations(startDate, endDate) {
    const branch = JSON.parse(localStorage.getItem("branch"));

    if (!branch) {
      toast.error("Error: Branch not found");
      return;
    }
    try {
      const reservationsRef = firebase.firestore().collection("users").doc(this.state.user.id).collection('reservations');
      const q = reservationsRef
        .where('status', 'in', ['Rental', 'Created'])
        .where('pd', '<=', endDate)
        .where("branchId", "==", branch)

      const reservationsSnapshot = await q.get();
      return reservationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })).filter(reservation => reservation.rd >= startDate);
    } catch (error) {
      //console.error("Error fetching reservations:", error);
      throw error;
    }
  }

  // Helper function to check if a single vehicle is available
  isVehicleAvailable(vehicle, reservations, startDate, endDate) {
    // Filter reservations for this specific vehicle
    const vehicleReservations = reservations.filter(res => res.v_id === vehicle.id);

    // Check if any reservation overlaps with the requested date range
    const isOverlapping = vehicleReservations.some(reservation => {
      return (
        (startDate >= reservation.pd && startDate < reservation.rd) ||
        (endDate > reservation.pd && endDate <= reservation.rd) ||
        (startDate <= reservation.pd && endDate >= reservation.rd)
      );
    });

    return !isOverlapping;
  }

  redirectToCheckout = async (userId) => {
    this.setState({ loadingUrl: true, modal: false, })

    const userRef = firebase.firestore().collection("users").doc(userId)
    await userRef.collection("checkout_sessions").add({
      /* customer: customer, */
      mode: "payment",
      price: "price_1Q0M6RGFbCpQEhRTjYkJFAJ8",
      success_url: `${window.location.origin}/dashboard`,
      cancel_url: `${window.location.origin}/dashboard`,
      allow_promotion_codes: true,
    }).then((docRef) => {
      docRef.onSnapshot(async (snap) => {
        const { url } = snap.data();
        //console.log("west lfaunction", url)
        if (url !== undefined) {
          this.setState({ loadingUrl: false })
          /* window.open(url); */
          window.location.href = url;
        }
      })
    })

    // this.toggle()
  }

  render() {

    //console.log(this.state.user.company_name)
    const {admin, user, av_vehicles, checking, hasClicked } = this.state
    //console.log(av_vehicles, checking)
    var showButton = false
    ////console..log(user.id)
    if (!user.subscribed) {
      //console.log("valid");
      showButton = true
    } else {
      showButton = false
    }

    const sub = (
      user?.lifetimeAccess ||
      (user?.subscription?.status === "active"));
   ////console..log(sub)
    const branch = JSON.parse(localStorage.getItem("branch"));
    const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    const branches = JSON.parse(localStorage.getItem("branches")) || [];
    const hasBranches = Array.isArray(branches) && branches.length > 0;
    var currentBranch = ""
    branches.forEach(b => {
      if (b.id === branch) {
        currentBranch = b.name
      }
    })
    return (
      <React.Fragment>
        <div>
          {/*  <button onClick={this.handleTourStart}>Start Tour</button> */}
          {/*   <Tour
            steps={steps}
            isOpen={this.state.isTourOpen}
            onRequestClose={this.handleTourEnd}
            rounded={8}
            accentColor="#556EE6"
            
          /> */}

          {/* <div id="welcome-message">
          <h1>Welcome to your Dashboard</h1>
          <p>This is where you can manage your account and view your data.</p>
        </div>

        <div id="analytics-section">
          <h2>Analytics</h2>
          <p>Here you can see your performance metrics and analytics.</p>
        </div>

        <button id="settings-button">Account Settings</button> */}
        </div>

        <Modal isOpen={this.state.AvModal} centered={true}>
          <ModalHeader toggle={this.toggleAvailability} tag="h5">
            {this.props.t("Check vehicle Availibility")}
          </ModalHeader>
          <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>

            <AvForm
              onValidSubmit={
                this.handleCheck
              }
            >
              <Row>
                <Col md="6">
                  <FormGroup className="mb-4">
                    <Label> {this.props.t("Pickup date")}</Label>
                    <AvField
                      name="startDate"
                      type="datetime-local"
                      /*defaultValue={this.state.reservation.pickup_date || ""}
                      errorMessage={this.props.t("Select a Pickup date")}
                      disabled={this.state.isShow ? true : false}*/
                      errorMessage={this.props.t("Select a Pickup date")}
                      /* min={new Date().toISOString().slice(0, 16)} */
                      onChange={this.handlePDChange}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup className="mb-4">
                    <Label> {this.props.t("Return date")}</Label>
                    <AvField
                      name="endDate"
                      type="datetime-local"
                      /* defaultValue={this.state.reservation.return_date || ""}
                       errorMessage={this.props.t("Select a Return date")}
                       disabled={this.state.isShow ? true : false}*/
                      errorMessage={this.props.t("Select a Return date")}
                      onChange={this.handleRDChange}
                      /*  min={new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 16)} */
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-center">

                    <button
                      type="submit"
                      className="btn btn-success save-user mb-2"
                    >
                      {this.props.t("Check Availibility")}
                    </button>
                  </div>
                </Col>
              </Row>

            </AvForm>
            {!hasClicked ? (
              null
            ) : checking ? (

              <Col>
                <hr />
                <div>
                  <div >
                    <div className="spinner-chase">
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                    </div>
                  </div>
                </div>
                <br />
                <h6 className='text-center'>{this.props.t("Checking ...")}</h6>
              </Col>
            ) : (
              <div className="table-responsive">
                <hr />
                <Table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>{this.props.t("Make")}</th>
                      <th>{this.props.t("Model")}</th>
                      <th>{this.props.t("Plate Number")}</th>
                      <th>{this.props.t("Fuel Type")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.av_vehicles && this.state.av_vehicles.length > 0 ? (
                      this.state.av_vehicles.map((vehicle, index) => (
                        <tr key={index}>
                          <td>{vehicle.make}</td>
                          <td>{vehicle.model}</td>
                          <td>{vehicle.plate_number}</td>
                          <td>{vehicle.fuel_type}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No vehicles available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            )}

            {/* {checking ? <Col>
              <div>
                <div >
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
              <br />
              <h6 className='text-center'>{this.props.t("Checking ...")}</h6>
            </Col> :
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>{this.props.t("Make")}</th>
                      <th>{this.props.t("Model")}</th>
                      <th>{this.props.t("Plate Number")}</th>
                      <th>{this.props.t("Fuel Type")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.av_vehicles && this.state.av_vehicles.length > 0 ? (
                      this.state.av_vehicles.map((vehicle, index) => (
                        <tr key={index}>
                          <td>{vehicle.make}</td>
                          <td>{vehicle.model}</td>
                          <td>{vehicle.plate_number}</td>
                          <td>{vehicle.fuel_type}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No vehicles available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            } */}

          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.loadingUrl} centered={true}>
          <ModalBody>
            <Col>
              <div>
                <div >
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
              <br />
              <h6 className='text-center'>{this.props.t("Redirecting to checkout page ...")}</h6>
            </Col>

          </ModalBody>
        </Modal>
        {this.state.modal ? (
          <SweetAlert
            title={this.props.t("Purchase Medios Now?")}
            showCancel
            confirmButtonText={this.props.t("Yes, Proceed!")}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.redirectToCheckout(this.state.user.id)
            }
            onCancel={() =>
              this.setState({
                modal: false,
              })
            }
          >
            {/* {" "+kra.email + " ? "} */}
          </SweetAlert>
        ) : null}
        <header id="page-topbar">
          <div className="navbar-header">

            <div className="d-flex">
              <div className="navbar-brand-box d-lg-none d-md-block">
                <Link to="/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                </Link>

                <Link to="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>

              <form className="app-search d-none d-lg-block">
                <div className="px-5 mt-2 position-relative">
                  <h4>{this.state.user.company_name}{admin && branches.length > 1 ? <small>{"("}{currentBranch}{")"}</small> : null}</h4>
                </div>
              </form>


              {/* <div className="dropdown d-none d-lg-inline-block ms-1">
              <Link to="/qrcode" className="dropdown d-none d-lg-inline-block ms-1">
              <i className="mdi mdi-qrcode-scan mdi-lg" />
              </Link>
                <button
                
                  type="button"
                  className="btn header-item noti-icon"
                
                >
                  
                </button>
              </div> */}
            </div>

            <div className="d-flex">
              {!sub && user.length !== 0 && admin ? (user.country=="Morocco"?<h6 className=" text-warning  me-3 mt-4 " onClick={this.toggle}> <i className="mdi mdi-crown-outline  mdi-18px  me-1 mt-2"></i><strong>{" "}{this.props.t("Buy Now")}</strong></h6> :<Link to="/upgrade"className=" text-warning  me-3 mt-4 " > <i className="mdi mdi-crown-outline  mdi-18px  me-1 mt-2"></i><strong>{" "}{this.props.t("Subscribe Now")}</strong></Link> ) : null}

              {/* <form className="app-search d-none d-lg-block">
                <div className="px-5  position-relative">
                  <Button className="btn btn-outline">Buy Now</Button>
                </div>
              </form> */}
              {/* <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch })
                  }}
                  type="button"
                  className="btn header-item noti-icon"
                  id="page-header-search-dropdown"
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">

                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
 */}

              <div className="languages">
                <LanguageDropdown />
              </div>
              <div >
                <div className="dropdown d-none d-lg-inline-block ms-1">
                  <button
                    type="button"
                    onClick={this.toggleAvailability}
                    className="btn header-item noti-icon"
                  >

                    <i className="bx bx-car"></i>
                  </button>
                </div>
              </div>
              <div className="full_screen">
                <div className="dropdown d-none d-lg-inline-block ms-1">
                  <button
                    id="full_screen"
                    type="button"
                    onClick={this.toggleFullscreen}
                    className="btn header-item noti-icon"
                    data-toggle="fullscreen"
                  >
                    <i className="bx bx-fullscreen"></i>
                  </button>
                </div>
              </div>

              {/* <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  className="btn header-item noti-icon"

                >
                  <i className="bx bx-message-square-dots" />
                </button>
              </div> */}
              <div className="notifications">
                <NotificationDropdown />
              </div>
              <div className="profile_menu">
                <ProfileMenu />
              </div>


              {/* <div
                onClick={this.toggleRightDrawer} disabled={this.state.open}
                className="dropdown d-inline-block"
              >
                <button
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <ReactDrawer
          open={this.state.open}
          position={this.state.position}
          onClose={this.onDrawerClose}
        >
          <RightSidebar onClose={this.onDrawerClose} />
        </ReactDrawer>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
)

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  toggleRightSidebar: PropTypes.func
}