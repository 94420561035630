import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';

import axios from 'axios';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import toast, { Toaster } from 'react-hot-toast';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

function ConnectDrive({ userId }) {
  const { t } = useTranslation();
  const [driveStatus, setDriveStatus] = useState({ isConnected: false, email: null });
  const [loading, setLoading] = useState(true);
  const [frequency, setFrequency] = useState('weekly');
  const [isLoading, setIsLoading] = useState(false);
  const [last, setLast] = useState(null);
  useEffect(() => {
    async function fetchDriveStatus() {
      const status = await checkDriveConnection(userId);
      //console.log(status)
      setDriveStatus(status);
      setLoading(false);
    }
    fetchDriveStatus();
  }, [userId]);

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      // //console..log('Code Response:', codeResponse);

      try {
        const tokens = await exchangeCodeForTokens(codeResponse.code);
        // //console..log('Tokens:', tokens);

        const tokenData = {
          access_token: tokens.access_token,
          expiry_date: new Date().getTime() + (tokens.expires_in || 3600) * 1000,
          refresh_token: tokens.refresh_token || null,
          frequency: 'weekly' // Adding frequency field with default value
        };

        await firebase.firestore().collection('users').doc(userId).collection('googleDrive').doc('tokens').set(tokenData);
        //console.log('Google Drive tokens stored successfully');
        // toast.success(t("Google Drive tokens stored successfully"))

        const newStatus = await checkDriveConnection(userId);
        ////console..log(newStatus)
        setDriveStatus(newStatus);
      } catch (error) {
        ////console..error('Error in token exchange:', error);
      }
    },
    onError: errorResponse =>console.log('Login Failed:'),
    scope: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.email',
    access_type: 'offline'
  });

  const logout = async () => {
    try {
      // 1. Call Google's logout function
      googleLogout();

      // 2. Delete tokens from Firestore
      await firebase.firestore()
        .collection('users')
        .doc(userId)
        .collection('googleDrive')
        .doc('tokens')
        .delete();

      setDriveStatus(false);


     //console..log('Logged out successfully and tokens deleted from Firestore');

    } catch (error) {
     //console..error('Error during logout:', error);

    }
  };

  async function checkDriveConnection(userId) {
    try {
      const tokenDoc = await firebase.firestore().collection('users').doc(userId).collection('googleDrive').doc('tokens').get();
      const tokenData = tokenDoc.data();
      //console.log(tokenData)
      if (!tokenData || !tokenData.access_token) {
        return { isConnected: false, email: null };
      }

      if (new Date().getTime() > tokenData.expiry_date) {
        const refreshedTokens = await refreshAccessToken(tokenData.refresh_token);
        tokenData.access_token = refreshedTokens.access_token;
        tokenData.expiry_date = new Date().getTime() + (refreshedTokens.expires_in || 3600) * 1000;

        await firebase.firestore().collection('users').doc(userId).collection('googleDrive').doc('tokens').set(tokenData);
      }

      setFrequency(tokenData.frequency || 'weekly');
      if (tokenData.last_update !== undefined) {
        const milliseconds = tokenData.last_update.seconds * 1000 + tokenData.last_update.nanoseconds / 1e6;
        const date = new Date(milliseconds);
        var lst = moment(date).format('DD-MM-YYYY hh:mm');

        setLast(lst);
      }
      const response = await axios.get('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenData.access_token}`
        }
      });

      return { isConnected: true, email: response.data.email };
    } catch (error) {
      //console.error('Error checking drive connection:', error);
      return { isConnected: false, email: null };
    }
  }

  const exchangeCodeForTokens = async (code) => {
    const response = await axios.post('https://oauth2.googleapis.com/token', null, {
      params: {
        code,
        client_id: "53122160804-epd7tc6m4tdel9m7c35238jv12k7a843.apps.googleusercontent.com",
        client_secret: "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",
        redirect_uri: "https://app.medios.ma",
        grant_type: 'authorization_code',
      },
    });
    return response.data;
  };

  const refreshAccessToken = async (refreshToken) => {
    const response = await axios.post('https://oauth2.googleapis.com/token', null, {
      params: {
        client_id: "53122160804-epd7tc6m4tdel9m7c35238jv12k7a843.apps.googleusercontent.com",
        client_secret: "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",
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      },
    });
    return response.data;
  };

  const saveFrequency = async () => {
    try {
      await firebase.firestore().collection('users').doc(userId).collection('googleDrive').doc('tokens').update({
        frequency: frequency
      });
      toast.success(t("Backup frequency set to : ") + frequency)
      //console.log('Frequency saved successfully');
    } catch (error) {
      toast.error(t("Error setting backup frequency"))
      //console.error('Error saving frequency:', error);
    }
  };
  const handleBackup = async () => {
    setIsLoading(true);
    try {
      const tokenDoc = await firebase.firestore().collection('users').doc(userId).collection('googleDrive').doc('tokens').get();
      const tokenData = tokenDoc.data();
      if (!tokenData || !tokenData.access_token) {
        ////console..error('No access token available');
        toast.error(t("Backup error"));
        setIsLoading(false);
        return;
      }
      // Check if token is expired and refresh if necessary
      if (new Date().getTime() > tokenData.expiry_date) {
        const refreshedTokens = await refreshAccessToken(tokenData.refresh_token);
        tokenData.access_token = refreshedTokens.access_token;
        tokenData.expiry_date = new Date().getTime() + (refreshedTokens.expires_in || 3600) * 1000;
        await firebase.firestore().collection('users').doc(userId).collection('googleDrive').doc('tokens').set(tokenData);
      }
      // Call your Firebase function
      const backupDrive = firebase.app().functions('europe-west1').httpsCallable('backupDrive');
      backupDrive({ userId: userId, token: tokenData.access_token })
        .then(async (result) => {
          const tdt = await firebase.firestore().collection('users').doc(userId).collection('googleDrive').doc('tokens').get();
          const lu = tdt.data().last_update
          const milliseconds = lu.seconds * 1000 + lu.nanoseconds / 1e6;
          const date = new Date(milliseconds);
          var lst = moment(date).format('DD-MM-YYYY hh:mm');

          setLast(lst)
          toast.success(t(result.data.status));
          //console.log(result.data.status); // 'Backup uploaded to Google Drive successfully'
          /*  //console..log(result.data.fileId);  */// The ID of the file in Google Drive
        })
        .catch((error) => {
          toast.error(t("Failed to upload the backup."));
          //console.error('Error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });

    } catch (error) {
      //console.error('Error during backup:', error);
      toast.error(t('Error during backup. Please try again.'));
      setIsLoading(false);
    }
  };

  if (loading) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <div>
      <Modal isOpen={isLoading}>
        <ModalBody>
          <Col>
            <div>
              <div >
                <div className="spinner-chase">
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                </div>
              </div>
            </div>
            <br />
            <h6 className='text-center'>{t("Uploading backup...")}</h6>
          </Col>

        </ModalBody>
      </Modal>
      {driveStatus.isConnected ? (
        <div className='text-center'>
          <p>{t("Google Drive is connected: ")}<strong>{driveStatus.email}</strong></p>
          <Row className="text-center mb-4 mt-0">
            <Col>


              <button
                type="button"
                className="btn btn-light"
                onClick={handleBackup}
              >
                <i className="bx bx-cloud-upload font-size-16 align-middle me-2"></i>
                {t("Upload Now")}
              </button>
              <button
                type="button"
                className="btn btn-danger ms-4"
                onClick={() => logout()}
              >
                <i className="bx bx-power-off font-size-16 align-middle me-2"></i>
                {t("logout")}
              </button>
            </Col>
          </Row>
          <hr />
          <h6 className="card-title-desc text-center">{t("Select backup frequency:")}</h6>
          <div className="d-flex justify-content-center">
            <div className="radio-group">
              <div className="form-check">
                <input
                  type="radio"
                  id="weekly"
                  name="backupFrequency"
                  className="form-check-input"
                  value="weekly"
                  checked={frequency === 'weekly'}
                  onChange={(e) => setFrequency(e.target.value)}
                />
                <label className="form-check-label" htmlFor="weekly">
                  {t("Backup weekly")}
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  id="monthly"
                  name="backupFrequency"
                  className="form-check-input"
                  value="monthly"
                  checked={frequency === 'monthly'}
                  onChange={(e) => setFrequency(e.target.value)}
                />
                <label className="form-check-label" htmlFor="monthly">
                  {t("Backup monthly")}
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <button
              type="button"
              className="btn btn-info save-user mt-2 btn-rounded"
              onClick={saveFrequency}
            >
              {t("Save")}
            </button>
          </div>{last ? 
            <><hr /><Row className="text-center mt-2">
              <Col>
                {last ? <h6>{t("Last backup on ")}<strong> {last}</strong>.</h6> : null}

              </Col>
            </Row><hr className='mt-1' /></>
            : null}

        </div>
      ) : (
        <button
          type="button"
          className="btn btn-light mb-3"
          onClick={() => login()}
        >
          <i className="bx bx-cloud font-size-16 align-middle me-2"></i>
          {t("Connect Google Drive")}
        </button>
      )}
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>

  );
}

ConnectDrive.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ConnectDrive;