import React, { Component } from "react"
import PropTypes from "prop-types"
import axios from "axios";
import { Alert, Card, CardBody, CardTitle, Col, Container, Label, Row, Modal, ModalBody, ModalHeader } from "reactstrap"
import Select from "react-select"
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { withTranslation } from "react-i18next"
import firebase from "firebase/app"
import "firebase/auth"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';



import geohash from "ngeohash";

import cities from "../../common/data/villes.json"
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"
const LoadingContainer = () => <div>Loading...</div>
// action
import { apiError, registerUser, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Redirect, Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      error: null,
      countryList: [],
      currencyMap: {},
      selectedCountry: '',
      selectedCurrency: '',
      isCurrencyDisabled: true,
      ref_code: "",
      loadingUrl: false,
      redirectModal: false,
      isBuy: false,
      redirectToDashboard: false,
      selectedCity: null,
      user: false,
      registrationError: false,
      registrationErrorMsg: "",
      mapmodal: false,
      longitude: null,
      latitude: null,
      userAddress: null,
      userCity: null,
      userState: null,
      addressLoading: false,
      planParam:"",
      markerTitle: "The marker`s title will appear as a tooltip.",
      markerName: "Medios",
      markerPosition: { lat: 37.778519, lng: -122.40564 }

    }

    // handleValidSubmit
    this.onClickMarker = this.onClickMarker.bind(this)
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handleSelectCity = this.handleSelectCity.bind(this)
    this.toggleViewModal = this.toggleViewModal.bind(this)
    this.getLocation = this.getLocation.bind(this)
    this.getCoordinates = this.getCoordinates.bind(this)
    this.getUserAddress = this.getUserAddress.bind(this)
  }

  onClickMarker(t, map, coord) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    this.getUserAddress(lat, lng)
    this.setState({

      markerTitle: "",
      markerName: "",
      markerPosition: { lat, lng }

    });

  }
  toggleViewModal = () => {
    //this.getLocation()
    this.setState(prevState => ({
      mapmodal: !prevState.mapmodal,
    }))
  }
  registerUser = (values) => {
    const { isBuy } = this.state
    return new Promise((resolve, reject) => {

      firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
            const uid = firebase.auth().currentUser.uid
            const doc = firebase.firestore().collection("users").doc(uid)
            const currentDate = new Date();
            const currentTimestamp = currentDate.getTime();
            const futureDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000));
            const futureTimestamp = futureDate.getTime();
            //console.log(currentTimestamp, futureTimestamp);
            /*  */
            // const gHash = geohash.encode(this.state.markerPosition.lat, this.state.markerPosition.lng);
            doc.set(
              {
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                subscribed: false,
                start: currentTimestamp,
                end: futureTimestamp,
                validTrial: true,
                id: uid,
                company_name: values.company_name,
                phone: values.phone,
                email: values.email,
                city: values.city,
                address: values.address,
                country: values.country,
                currency: values.currency,
                //state:values.state,
                referal_code: this.state.ref_code,
                state: values.state,
                patente: "",
                ice: "",
                id_fiscal: "",
                rc: "",
                tp: "",
                website: "",
                description: "",
                //username: values.username,
                image_url: "https://firebasestorage.googleapis.com/v0/b/meanz-3960c.appspot.com/o/Untitled%20design%20(8).png?alt=media&token=38a6bcd1-e78e-4b57-9431-55f5a496d615",
                isAdmin: true
              }).then(() => {

                toast.success('User Registred  ')
                // window.location = '/dashboard';
                this.setState({
                  user: true,
                })
                //console.log("user  written!");


                if (isBuy) {
                  // If BUY plan is selected, call redirectToCheckout with BUY plan and payment mode
                  this.redirectToCheckout(firebase.auth().currentUser.uid, "BUY", "payment");
                } else if (this.state.planParam) {
                  // If another plan is selected, determine the mode (payment or subscription)
                  const isSubscriptionPlan = [
                    "StarterMonthly",
                    "StarterYearly",
                    "ProfessionalMonthly",
                    "ProfessionalYearly",
                    "UnlimitedMonthly",
                    "UnlimitedYearly",
                  ].includes(this.state.planParam);
              
                  const mode = isSubscriptionPlan ? "subscription" : "payment";
                  this.redirectToCheckout(firebase.auth().currentUser.uid, this.state.planParam, mode);
                } else {
                 //console..error("No valid plan selected");
                }
                /* if (isBuy) {
                  this.redirectToCheckout(firebase.auth().currentUser.uid)
                } else {
                  //window.location = '/dashboard'
                } */
              })
              .catch((error) => {
               //console..error("Error Adding user: ", error);

              });


          },
          error => {
            reject(this._handleError(error))
            //toast.error(error.message)
            this.setState({
              registrationError: true,
              registrationErrorMsg: error.message
            })
          }
        )

    })


  }
  // handleValidSubmit
  handleValidSubmit(event, values) {
    //this.props.registerUser(values)
    this.setState({
      registrationError: false,
      registrationErrorMsg: ""
    })

    /* if (this.state.userAddress === null || this.state.userCity === null) {
      toast.error("Please select agency localisation")
    } else {
 */
    this.registerUser(values)
    /*  } */

  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const buyParam = urlParams.get('buy');
    const refParam = urlParams.get('ref_code');
    const planParam = urlParams.get('plan');
    this.setState({planParam:planParam})
    ////console..log(planParam)
    if (refParam !== null || refParam !== "") {
      this.setState({
        ref_code: refParam
      })
    }
    if (buyParam === 'true') {
      this.setState({
        isBuy: true
      })
    } else {
      this.setState({
        isBuy: false
      })
    }
    /* const { usernames } = this.state
    if (usernames && !usernames.length) {
      //onGetStaff()
      this.getUsernames()

    } */
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        this.setState({ redirectToDashboard: true }); // Redirect to dashboard
      } else {
        // No user is signed in.
        this.setState({ redirectToDashboard: false });
      }
    });



    try {
      // Fetch all countries and their currencies
      const countriesResponse = await axios.get('https://restcountries.com/v3.1/all');
    
      // Create country list and currency map
      const countryData = countriesResponse.data.reduce((acc, country) => {
        if (country.name?.common && country.currencies) {
          // Add to country list
          acc.countryList.push({
            label: country.name.common,
            value: country.name.common,
            currencies: Object.keys(country.currencies),
          });
    
          // Add to currency map
          acc.currencyMap[country.name.common] = Object.keys(country.currencies);
        }
        return acc;
      }, { countryList: [], currencyMap: {} });
    
      // Sort countries alphabetically
      countryData.countryList.sort((a, b) => a.label.localeCompare(b.label));
    
      // Try to detect user's location using ipwhois.app
      const geoResponse = await axios.get('https://ipwhois.app/json/');
      const detectedCountry = geoResponse.data.country; // This returns full country name
    
      //console.log(detectedCountry);
    
      this.setState({
        loading: false,
        countryList: [{ label: 'Select Country', value: '' }, ...countryData.countryList],
        currencyMap: countryData.currencyMap,
        selectedCountry: detectedCountry || '',
        selectedCurrency: detectedCountry ? countryData.currencyMap[detectedCountry]?.[0] || '' : ''
      });
    
    } catch (error) {
     //console..error('Error fetching data:', error);
      this.setState({
        loading: false,
        error: 'Failed to load country and currency data. Please try again later.',
        countryList: [{ label: 'Select Country', value: '' }]
      });
    }
    
  }
  getCurrencyOptions = (countryName) => {
    const currencies = this.state.currencyMap[countryName] || [];
    return [
      { label: 'Select Currency', value: '' },
      ...currencies.map(currency => ({
        label: currency,
        value: currency
      }))
    ];
  };

  handleCountryChange = (event) => {
    const countryName = event.target.value;
    const currencies = this.state.currencyMap[countryName] || [];

    this.setState({
      selectedCountry: countryName,
      selectedCurrency: currencies.length === 1 ? currencies[0] : ''
    });
  };

  handleCurrencyChange = (event) => {
    this.setState({
      selectedCurrency: event.target.value
    });
  };
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError)
    } else {
      alert("geolocation is not supported")
    }
  }
  getCoordinates(position) {
   //console..log(position)
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      markerPosition: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    })
    this.getUserAddress(position.coords.latitude, position.coords.longitude)
  }
  handleLocationError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        toast.error("User denied the request for Geolocation.")
        break;
      case error.POSITION_UNAVAILABLE:
        toast.error("Location information is unavailable.")
        break;
      case error.TIMEOUT:
        toast.error("The request to get user location timed out.")
        break;
      case error.UNKNOWN_ERROR:
        toast.error("An unknown error occurred.")
        break;
      default:
        toast.error("An unknown error occurred.")
    }
  }
  getUserAddress(lat, lng) {
    //console.log("get user address", lat, lng)
    var key = "AIzaSyANuYDpzsSpPUtQHTGJPoBz9EKWelMNNtg"
    this.setState({
      addressLoading: true
    })
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${key}`)
      .then(response => response.json())
      .then(data => {
        //console.log(data.results)
        this.setState({
          addressLoading: false
        })
        if (data.results[0]) {

          for (var i = 0; i < data.results.length; i++) {
            if (data.results[i].types[0] === "route") {
              var address = data.results[i].formatted_address;
              //console.log(address)
              this.setState({
                userAddress: address
              })
            };
            if (data.results[i].types[0] === "locality") {
              var city = data.results[i].address_components[0].short_name;
              var state = data.results[i].address_components[1].short_name;
              //console.log(city)
              //console.log(state)
              this.setState({
                userCity: city,
                userState: state,

              })
            };
          };
        };
      }
      )
      .catch(error =>console.log("error" ))
  }
  handleSelectCity = selectedCity => {

    this.setState({ selectedCity: selectedCity })

  }
  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
  redirectToCheckout = async (userId, plan, mode = "payment") => {
    this.setState({ loadingUrl: true, redirectModal: false });
  
    // Define price IDs for each plan
    const priceIds = {
      BUY: "price_1Q0M6RGFbCpQEhRTjYkJFAJ8", // Example price ID for BUY (one-time payment)
      LTD: "price_1QoNPxGFbCpQEhRT8pPUpwbE", // Example price ID for LTD
      StarterMonthly: "price_1QoNLuGFbCpQEhRT16ld1V0Y", // Example price ID
      StarterYearly: "price_1QoNLuGFbCpQEhRTo1w6doyB", // Example price ID
      ProfessionalMonthly: "price_1QoNNVGFbCpQEhRTn1khi0aa", // Example price ID
      ProfessionalYearly: "price_1QoNNVGFbCpQEhRTvmg7sy5O", // Example price ID
      UnlilmitedMonthly: "price_1QoNOqGFbCpQEhRTkiWwF3wZ", // Example price ID
      UnlimitedYearly: "price_1QoNOqGFbCpQEhRTddXhmILJ", // Example price ID
    };
  
    // Get the price ID based on the selected plan
    const priceId = priceIds[plan];
    if (!priceId) {
     //console..error("Invalid plan selected");
      return;
    }
  
    const userRef = firebase.firestore().collection("users").doc(userId);
    await userRef
      .collection("checkout_sessions")
      .add({
        mode: mode, // Set mode dynamically (payment or subscription)
        price: priceId, // Set price ID dynamically based on the plan
        success_url: `${window.location.origin}/dashboard`,
        cancel_url: `${window.location.origin}/dashboard`,
        allow_promotion_codes: true,
      })
      .then((docRef) => {
        docRef.onSnapshot(async (snap) => {
          const { url } = snap.data();
          if (url !== undefined) {
            this.setState({ loadingUrl: false });
            window.location.href = url;
          }
        });
      });
  };
  /* redirectToCheckout = async (userId) => {
    this.setState({ loadingUrl: true, redirectModal: false })

    const userRef = firebase.firestore().collection("users").doc(userId)
    await userRef.collection("checkout_sessions").add({
      
      mode: "payment",
      price: "price_1Q0M6RGFbCpQEhRTjYkJFAJ8",
      success_url: `${window.location.origin}/dashboard`,
      cancel_url: `${window.location.origin}/dashboard`,
      allow_promotion_codes: true,
    }).then((docRef) => {
      docRef.onSnapshot(async (snap) => {
        const { url } = snap.data();
        // //console.log("west lfaunction", url)
        if (url !== undefined) {
          this.setState({ loadingUrl: false })
          
          window.location.href = url;
        }
      })
    })

    // this.toggle()
  } */
  render() {
    if (this.state.redirectToDashboard) {
      return <Redirect to="/dashboard" />;
    }
    const { planParam,loading, error, countryList, selectedCountry, selectedCurrency, isBuy, ref_code } = this.state
   ////console..log(countryList, selectedCountry, selectedCurrency)
    if (loading) {
      return <div>Loading ...</div>;
    }

    if (error) {
      return <div className="text-danger">{error}</div>;
    }
    const currencyOptions = this.getCurrencyOptions(selectedCountry);
    const isCurrencyDisabled = !selectedCountry;
    const villes = []
    const { selectedCity } = this.state
    cities.map(function (item, i) {
      villes.push({ label: item.ville, value: item.ville })

    })

    return (
      <React.Fragment>
        <Modal isOpen={this.state.loadingUrl} centered={true}>
          <ModalBody>
            <Col>
              <div>
                <div >
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
              <br />
              <h6 className='text-center'>{"Redirecting to checkout page ..."}</h6>
            </Col>

          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.mapmodal}
          size='md'
        >
          <ModalHeader toggle={this.toggleViewModal} tag="h4">
            Select localisation
          </ModalHeader>
          <ModalBody>
            <Row>

              <Col>
                <Card>
                  <CardBody>

                    <h4 className="h4">Address</h4>
                    <Row>
                      <Col >
                        <h6 className="card-title-desc">
                          {this.state.addressLoading ?
                            <i className="bx bx-loader bx-spin bx-md" />
                            :

                            this.state.userAddress || "Address... "
                          }

                        </h6>
                      </Col>

                      <Col className="mb-3 text-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => this.getLocation()}
                        >
                          <i className="bx bx-map font-size-16 align-middle me-2"></i>
                          Current location
                        </button>
                      </Col >
                    </Row>
                    <Row id="gmaps-overlay"
                      className="gmaps "
                      style={{ position: "relative" }}>

                      <Map
                        bootstrapURLKeys={{ key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE" }}
                        google={this.props.google}
                        zoom={5}
                        style={{ width: "100%", height: "100%", borderRadius: '8px' }}
                        onClick={this.onClickMarker}
                        fullscreenControl={false}
                        initialCenter={{

                          lat: 31.7218287,
                          lng: -11.6459998,
                        }}
                        center={{

                          lat: this.state.markerPosition.lat,
                          lng: this.state.markerPosition.lng,
                        }}

                      >
                        <Marker
                          title={this.state.markerTitle}
                          name={this.state.markerName}
                          position={this.state.markerPosition}
                        />
                      </Map>

                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <div className="text-end">

                          <button
                            type="button"
                            className="btn btn-success save-user"
                            onClick={() => this.toggleViewModal()}
                          >
                            Confirm
                          </button>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
              </Col>
              <Row className="text-center">
                <p className="card-title-desc">
                  This helps clients easily find your agency for pickups and returns.
                </p>
              </Row>
            </Row>




          </ModalBody>
        </Modal>
        <div className="home-btn d-none d-sm-block">
          <a href="https://medios.ma" className="text-dark">
            <i className="bx bx-home h2" />
          </a>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">{this.props.t("Free Register")}</h5>
                          <p>{this.props.t("Get your free Medios account now.")}</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.state.user && this.state.user ? (

                          <Redirect to='/dashboard' />
                        ) : null}
                        {this.state.registrationError &&
                          this.state.registrationError ? (
                          <Alert color="danger">
                            {this.state.registrationErrorMsg}
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={this.props.t("Email")}
                            className="form-control"
                            placeholder={this.props.t("Enter email")}
                            type="email"
                            required
                          />
                        </div>

                        {/* <div className="mb-3">
                          <AvField
                            name="username"
                            label="Username"
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: 'Please enter a username' },
                              minLength: { value: 9, errorMessage: 'Your username must be at least 9 characters' },
                              maxLength: { value: 25, errorMessage: 'Username too long' },
                              pattern: { value: "^[A-Za-z0-9_-]+$", errorMessage: 'Your username must not contain spaces' },
                            }}
                            placeholder="Enter username"
                          />
                        </div> */}
                        <div className="mb-3">
                          <AvField
                            name="password"
                            label={this.props.t("Password")}
                            type="password"
                            validate={{
                              required: { value: true, errorMessage: 'Please enter a password' },
                              pattern: { value: "^[a-zA-Z0-9!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]{10,}$", errorMessage: 'Your password must be composed of letters, numbers, and some common special characters, and be at least 10 characters long' }

                            }}

                            placeholder={this.props.t("Enter Password")}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="password1"
                            type="password"
                            label={this.props.t("Re-type Password")}
                            placeholder={this.props.t("Re-type Password")}
                            errorMessage={this.props.t("Re-type Password")}
                            validate={{
                              required: { value: true, errorMessage: this.props.t("Please enter a password") },

                              /* minLength: { value: 9, errorMessage: 'Your password must be between 9 and 20 characters' },
                              maxLength: { value: 20, errorMessage: 'Your password must be between 9 and 20 characters' }, */
                              //  pattern: { value: "^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$", errorMessage: 'Your password must be composed with letter and numbers' },
                              match: { value: "password", errorMessage: this.props.t("Passwords do not match") },

                            }}

                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="phone"
                            label={this.props.t("Phone number")}
                            placeholder={this.props.t("Phone number")}
                            type="text"
                            errorMessage={this.props.t("Enter a valid phone number")}
                            className="form-control"

                            validate={{
                              required: { value: true },
                              pattern: {
                                value: '^(0|\\+)\\d{9,14}$',
                                errorMessage: this.props.t("Enter a valid phone number")
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="company_name"
                            label={this.props.t("Company name")}
                            className="form-control"
                            placeholder={this.props.t("Enter company name")}
                            errorMessage={this.props.t("Enter company name")}
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col>
                              <Label>{this.props.t("Country")}</Label>
                              <AvField
                                type="select"
                                name="country"
                                value={selectedCountry}
                                onChange={this.handleCountryChange}
                                errorMessage={this.props.t("Please select a country")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                              >
                                {countryList.map(country => (
                                  <option key={country.value} value={country.value}>
                                    {country.label}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                            <Col>
                              <Label>{this.props.t("Currency")}</Label>
                              <AvField
                                type="select"
                                name="currency"
                                value={selectedCurrency}
                                onChange={this.handleCurrencyChange}
                                disabled={isCurrencyDisabled}
                                errorMessage={this.props.t("Please select a currency")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                              >
                                {currencyOptions.map(currency => (
                                  <option key={currency.value} value={currency.value}>
                                    {currency.label}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            {this.props.t("Address")}
                          </Label>
                          <AvField
                            name="address"
                            type="text"
                            autoComplete="off"
                            placeholder={this.props.t("Enter company address")}
                            errorMessage={this.props.t("Enter company address")}
                            required
                          />
                        </div>
                        <Row>
                          <Col md={6}  >
                            <div className="mb-3">
                              <AvField
                                name="city"
                                label={this.props.t("City")}
                                className="form-control"
                                placeholder={this.props.t("Enter city")}
                                errorMessage={this.props.t("Enter city")}
                                type="text"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6} >
                            <div className="mb-3">
                              <AvField
                                name="state"
                                label={this.props.t("Region")}
                                className="form-control"
                                placeholder={this.props.t("Enter region")}
                                errorMessage={this.props.t("Enter region")}
                                type="text"
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col   >
                            <div className="mb-1">
                              <AvField
                                name="referal_code"
                                label="Referal Code"
                                className="form-control"
                                placeholder="Enter Code"
                                errorMessage="Enter Code"
                                type="text"
                              />
                            </div>
                          </Col>
                        </Row> */}
                        {/* <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            City
                          </Label>
                          <AvField
                            name="city"
                            type="text"
                            autoComplete="off"
                            placeholder="Enter city"
                            errorMessage="Enter city"
                            required
                          />
                        </div> */}
                        {/* <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Agency localisation
                          </Label>
                          <div ><button
                            type="button"
                            className="btn btn-dark"
                            onClick={() => this.toggleViewModal()}
                          >
                            <i className="bx bx-map font-size-16 align-middle me-2"></i>
                            Location
                          </button>

                          </div>

                        </div> */}
                        {/* {this.state.userAddress && this.state.userCity ? */}

                        {/*  :null} */}

                        <div className="mt-4 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            {this.props.t("Register")}
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            {this.props.t("By registering you agree to the Medios")}{" "}
                            <a href="https://medios.ma/terms" target="_blank" rel="noreferrer" className="text-primary">
                              {this.props.t("Terms of Use")}
                            </a>
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    {this.props.t("Already have an account ?")}{" "}<br /><br />
                    <Link
                      to="/login"
                      className="fw-medium text-primary"
                    >
                      {" "}
                      {this.props.t("Login")}
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} Medios.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
  google: PropTypes.object,
  t: PropTypes.any
}

const mapStateToProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
  LoadingContainer: LoadingContainer,
  v: "3",
})(
  withTranslation()(Register)
)
