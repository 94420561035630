import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Modal, ModalBody,Col, Card, CardBody, Button } from "reactstrap";
// Add the Firebase products that you want to use
import firebase from "firebase/app"
import { withTranslation } from "react-i18next"

class CardPricing extends Component {
  state = {
    loadingUrl:false
  }
  // Function to handle the upgrade button click
  handleUpgrade = async () => {
    const { pricing, billingCycle } = this.props;
    this.setState({ loadingUrl: true })
    // Determine the price ID based on the billing cycle or Lifetime Deal
    const priceId = pricing.isLifetime
      ? pricing.priceId.oneTime
      : billingCycle === "annual"
        ? pricing.priceId.annual
        : pricing.priceId.monthly;
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        try {
          // Reference to the Firestore collection for checkout sessions
          const docRef = await firebase.firestore()
            .collection('users')
            .doc(user.uid) // Assuming `currentUser` is available in your context
            .collection('checkout_sessions')
            .add({
              price: priceId, // Use the determined price ID
              success_url: window.location.origin,
              cancel_url: window.location.origin,
              mode: pricing.isLifetime ? "payment" : "subscription",
              allow_promotion_codes: true,
            });
    
          // Listen for changes to the checkout session
          docRef.onSnapshot((snap) => {
            const { error, url } = snap.data();
            if (error) {
              // Handle errors
              this.setState({ loadingUrl: false })
              alert(`An error occurred: ${error.message}`);
            }
            if (url) {
              // Redirect to the Stripe Checkout URL
              this.setState({ loadingUrl: false })
              window.location.assign(url);
            }
          });
        } catch (error) {
          this.setState({ loadingUrl: false })
         //console..error("Error creating checkout session:", error);
         /*  alert("An error occurred while creating the checkout session."); */
        }
      } else {
        this.setState({ loadingUrl: false })
       //console..log("error")
      }
    })
    
  };

  render() {
    const { pricing, billingCycle } = this.props;
    const {loadingUrl}=this.state
    // Handle Lifetime Deal separately
    const isLifetimeDeal = pricing.isLifetime;
    const price = isLifetimeDeal
      ? pricing.price.oneTime
      : billingCycle === "annual"
        ? pricing.price.annual
        : pricing.price.monthly;

    const duration = isLifetimeDeal
      ? pricing.duration
      : billingCycle === "annual"
        ? this.props.t("Per year")
        : pricing.duration;

    return (
      <React.Fragment>
        <Modal isOpen={loadingUrl} centered={true} size="sm" >
          <ModalBody>
            <Col>
              <div>
                <div >
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
              <br />
              <h6 className='text-center'>{this.props.t("Redirecting to checkout page ...")}</h6>
            </Col>

          </ModalBody>
        </Modal>
        <Col xl="3" md="6">
          <Card
            className={`plan-box ${isLifetimeDeal ? "lifetime-plan" : ""}`}
            style={isLifetimeDeal ? { boxShadow: "0 8px 30px rgba(0, 0, 0, 0.15)" } : {}}
          >
            <CardBody className="p-4">
              <div className="d-flex">
                <div className="flex-grow-1">
                  {!isLifetimeDeal ? (
                    <h5>{this.props.t(pricing.title).toUpperCase()}</h5>
                  ) : (
                    <h4>
                      <strong>{this.props.t(pricing.title).toUpperCase()}</strong>
                    </h4>
                  )}
                  <p className="text-muted">{this.props.t(pricing.description)}</p>
                </div>
                <div className="ms-3">
                  <i className={"bx " + pricing.icon + " h1 text-primary"} />
                </div>
              </div>
              <div className="py-3">
                <h2>
                  <sup>
                    <small>$</small>
                  </sup>{" "}
                  {price}
                  {/*{!isLifetimeDeal ? "/ " : null}  */}
                  <span className="font-size-13">{this.props.t(duration)}</span>
                </h2>
                {isLifetimeDeal && (
                  <strong className="text-success">{this.props.t("Save $10,000+ over 10 years!")}</strong>
                )}
              </div>

              <div className="plan-features mt-0 mb-0">
                {pricing.features.map((feature, key) => (
                  <p key={"_feature_" + key}>
                    <i className="bx bx-check-circle text-primary me-2 text-lg"></i>{" "}
                    {this.props.t(feature.title)}
                  </p>
                ))}
              </div>

              <div className="text-start mt-4">
                <Button
                  color={isLifetimeDeal ? "success" : "primary"}
                  className={`btn btn-${isLifetimeDeal ? "success" : "primary"} btn-sm`}
                  onClick={this.handleUpgrade} // Add onClick handler
                >
                  {isLifetimeDeal ? this.props.t("Get Lifetime Access Now!") : this.props.t("Upgrade")}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

CardPricing.propTypes = {
  pricing: PropTypes.object,
  billingCycle: PropTypes.string,
  t: PropTypes.any,
};

export default withTranslation()(CardPricing);