import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, CardImg, CardFooter, Container, Row, Table } from "reactstrap"
import { isEmpty, map } from "lodash"
import generatePDF from "./GeneratePDF"; // Adjust the path as necessary
import * as moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
import "./print.css";
//Import Image
import logo from "../../assets/images/logo-dark.png"
import PropTypes from "prop-types"
import { getInvoiceDetail } from "../../store/invoices/actions"
import { connect } from "react-redux"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { propTypes } from "react-bootstrap-editable";

import { withTranslation } from "react-i18next"

class PrintContract extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currency: "",
      second_driver: false,
      useCompanyLogo: false,
      use_signature: false,
      reservation: [],
      client_infos: [],
      indie_infos: [],
      car_make: "",
      car_model: "",
      agency: [],
      loading: false,
    }
  }
  componentDidMount() {
    const { reservation, agency } = this.state
    const url = window.location.href.toString()
    const r_id = url.substring(url.lastIndexOf("/") + 1)
    //console.log(r_id)
    if (reservation && !reservation.length) {
      this.getReservationData(r_id)
    }
    this.setState({ reservation })
    if (agency && !agency.length) {
      this.getAgency()
    }
    this.setState({ agency })
  }
  handleCheckboxChange = (event) => {
    this.setState({ useCompanyLogo: event.target.checked });
  };
  handleSecondDriverChange = (event) => {
    this.setState({ second_driver: event.target.checked });
  };
  handleSignatureChange = (event) => {
    this.setState({ use_signature: event.target.checked });
  };
  getAgency = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            this.setState({ currency: userDoc.data().currency })
            const userUsersCollectionRef = usersCollection.doc(userId)


            this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
              let kra = snapshot.data()
              this.setState({
                currency: snapshot.data().currency,
                loading: false,
                agency: kra,
              })
            });



            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });
  }
  getReservationData = async (id) => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;

            const rDoc = await usersCollection.doc(userId).collection("reservations").doc(id).get();
            if (rDoc.exists) {
              let kra = null, cl = null, cli = null
              kra = rDoc.data()

              this.setState({
                loading: false,
                reservation: kra,
              })
              const cDoc = await usersCollection.doc(userId).collection("indies").doc(rDoc.data().client_id).get();
              if (cDoc.exists) {
                cli = cDoc.data()

                this.setState({
                  loading: false,
                  client_infos: cli,
                })
              }
              const vDoc = await usersCollection.doc(userId).collection("vehicles").doc(rDoc.data().v_id).get();
              if (vDoc.exists) {
                this.setState({
                  loading: false,
                  car_make: vDoc.data().make,
                  car_model: vDoc.data().model,
                })
              }
            }
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });


  }





  /* getReservationData = async (id) => {
    firebase.auth().onAuthStateChanged(user => {
      let kra = null, cl = null, cli = null
      if (user) {
        user = firebase.auth().currentUser.uid
        const collection = firebase.firestore().collection("users")
        this.setState({ loading: true }, () => {
          collection.doc(user).collection("reservations").doc(id).get().then((doc) => {
            // //console.log(doc.data())
            kra = doc.data()

            this.setState({
              loading: false,
              reservation: kra,
            })
            
            collection.doc(user).collection("indies").doc(doc.data().client_id).get().then((cl) => {
              cli = cl.data()
              
              this.setState({
                loading: false,
                client_infos: cli,
              })
            })
            collection.doc(user).collection("vehicles").doc(doc.data().v_id).get().then((v) => {
              

              this.setState({
                loading: false,
                car_make: v.data().make,
                car_model: v.data().model,
              })
            })
          }
          );

        });

        //localStorage.setItem("authUser", JSON.stringify(user))
      } else {
       //console..log("not user")
        localStorage.removeItem("authUser")
      }
    })
  } */
  //Print the Invoice
  printInvoice = () => {
    window.print()
  }




  render() {

    const { currency, useCompanyLogo, use_signature, second_driver, reservation, client_infos, car_make, car_model, agency, loading } = this.state
    // //console..log(agency)
    const subtotal = parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers)
    const tva = (parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers)) * 0.2
    const total = subtotal + tva
    { this.props.t("Total Day") }
    return (
      <React.Fragment>
        <div className="page-content">

          <MetaTags>
            <title>{this.props.t("Rental agreement | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Breadcrumbs title={this.props.t("Reservations")} breadcrumbItem={this.props.t("Contract")} />



          <Container fluid id="down">
            {/* Render Breadcrumbs */}

            <Row className="text-center"  >
              {loading ? <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-hourglass bx-spin me-2" />
                    {this.props.t("Loading . . .")}
                  </Link>
                </div>
              </Col> :
                <Col lg="12" id="down">
                  <Row className="form-check  d-print-none d-flex align-items-center mb-4">
                    <Col md="4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                        checked={useCompanyLogo} // Bind the state
                        onChange={this.handleCheckboxChange} // Handle change
                      />
                      <label className="form-check-label" htmlFor="defaultCheck2">
                        {this.props.t("Use Company logo")}
                      </label>
                    </Col>
                    <Col md="4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck3"
                        checked={second_driver} // Bind the state
                        onChange={this.handleSecondDriverChange} // Handle change
                      />
                      <label className="form-check-label" htmlFor="defaultCheck3">
                        {this.props.t("Add second driver")}
                      </label>
                    </Col>
                    <Col md="4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck3"
                        checked={use_signature} // Bind the state
                        onChange={this.handleSignatureChange} // Handle change
                      />
                      <label className="form-check-label" htmlFor="defaultCheck3">
                        {this.props.t("Add signature")}
                      </label>
                    </Col>
                  </Row>
                  <Card >

                    <CardBody>
                      <div className="print-container">
                        <div className="print-header">
                          <header>
                            <Row className="justify-items-center">
                              {/* <Col className=" text-end ">
                                  <img className="logo-dark-element" height="50" src={agency.image_url}></img>
                                </Col> */}
                              {/* <Col className="col-sm-7 text-center text-sm-start mb-2  mb-sm-0"> <img id="logo" src={agency.image_url} width="90" height="30" title="Medios" alt="Medios"></img> </Col> */}
                              {useCompanyLogo && agency.documents_logo ? <Col className="text-start " >
                                <img src={agency.documents_logo} alt="logo-dark" className="logo-dark-element" height="35" />

                              </Col> : null}
                              <Col className="  ">
                                <h4 className="mb-0 ">{this.props.t("Rental agreement")}</h4>
                                <p className="mb-0 ">{this.props.t("Contract Number")} : <span className="fw-bold">{reservation.code}</span></p>
                              </Col>

                            </Row>

                          </header>
                        </div>

                        <div className="print-content">
                          <Container fluid>
                            <h5 className="text-start fw-bold mt-0 mb-2">{this.props.t("Vehicle & Rental Details")} : </h5>
                            <hr className="my-1" />
                            <Row className="text-center">
                              <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase ">{this.props.t("Make & Model")}:</span><br />
                                <span className="fw-700 text-6">{car_make + " " + car_model}</span></Col>

                              {/* <Col className="col-sm-3 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Particular")}:</span><br />
                                  <span className="fw-700 text-6">{this.props.t("Car on Self Drive")}</span> </Col> */}
                              <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Registration Number")}:</span><br />
                                <span className="fw-700 text-6">{reservation.plate_number}</span> </Col>
                              <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Car Category")}:</span><br />
                                <span className="fw-700 text-6">{reservation.vehicle_type}</span></Col>
                            </Row>
                            <hr className="my-0" />
                            <Row >
                              <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Rental Start Date")}:</span><br />
                                <span className="fw-700 text-6">{moment(reservation.pickup_date).format('DD-MM-YYYY')}</span> </Col>
                              <Col className="col-sm-4 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Rental End Date")}:</span><br />
                                <span className="fw-700 text-6">{moment(reservation.return_date).format('DD-MM-YYYY')}</span> </Col>
                              <Col className="col-sm-4 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Total Day")}<small>(s)</small>:</span><br />
                                <span className="fw-700 text-6">{reservation.days}</span> </Col>

                            </Row>
                            <hr className="my-0" />
                            <Row >
                              <Col className="col-sm-3 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Out km")}<small>(s)</small>:</span><br />
                                <span className="fw-700 text-6">{reservation.mileage_pickup || "----"}</span> </Col>
                              <Col className="col-sm-3 mb-2 mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("In km")}<small>(s)</small>:</span><br />
                                <span className="fw-700 text-6">{reservation.mileage_return || "----"}</span> </Col>
                              <Col className="col-sm-3 mb-2s mb-sm-0 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Total km")}<small>(s)</small>:</span><br />
                                <span className="fw-700 text-6">{(reservation.mileage_return !== null && reservation.mileage_pickup !== null && reservation.mileage_return - reservation.mileage_pickup > 0) ? reservation.mileage_return - reservation.mileage_pickup || "----" : "----"}</span> </Col>
                              <Col className="col-sm-3 text-start"> <span className="text-black-70 fs-6 text-uppercase">{this.props.t("Security Deposit")}:</span><br />
                                <span className="fw-700 text-6">{reservation.caution + " " + currency || " ----"}</span> </Col>
                            </Row>
                            <hr className="my-1" />
                            <h5 className="text-start fw-bold mt-0 mb-2">{this.props.t("Client Information")} :</h5>

                            <div >
                              <table className="table rounded-table table-sm">
                                <tbody>
                                  <tr>
                                    <td className="col-3 text-start">
                                      <span className="fw-bold">{this.props.t("Name")} :</span><br />
                                      {client_infos.first_name + " " + client_infos.last_name}
                                    </td>
                                    <td className="col-3 text-start">
                                      <span className="fw-bold">{this.props.t("Date of Birth")}:</span><br />
                                      {client_infos.date_birth}
                                    </td>
                                    <td className="col-3 text-start">
                                      <span className="fw-bold">{this.props.t("CIN/Passport Number")}:</span><br />
                                      {client_infos.id_number}
                                    </td>
                                    <td className="col-3 text-start">
                                      <span className="fw-bold">{this.props.t("Valid Till")}:</span><br />
                                      {client_infos.id_expiration_date}
                                    </td>


                                  </tr>
                                  <tr>
                                    <td className="col-4 text-start">
                                      <span className="fw-bold">{this.props.t("Driving License Number")}:</span><br />
                                      {client_infos.dl_number}
                                    </td>
                                    <td className="col-3 text-start">
                                      <span className="fw-bold">{this.props.t("Phone")}:</span><br />
                                      {client_infos.mobile}
                                    </td>

                                    <td className="col-5 text-start" colSpan="2">
                                      <span className="fw-bold">{this.props.t("Address")}:</span><br />
                                      {client_infos.address + ", " + client_infos.city + ", " + client_infos.country}
                                    </td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>

                            {second_driver ? <><h5 className="text-start fw-bold  mb-2">{this.props.t("Second Driver")} :</h5>

                              <div >
                                <table className="table rounded-table table-sm">
                                  <tbody>
                                    <tr>
                                      <td className="col-3 text-start">
                                        <span className="fw-bold">{this.props.t("Name")} :</span><br />
                                        <br />
                                      </td>
                                      <td className="col-3 text-start">
                                        <span className="fw-bold">{this.props.t("Date of Birth")}:</span><br />

                                      </td>
                                      <td className="col-3 text-start">
                                        <span className="fw-bold">{this.props.t("CIN/Passport Number")}:</span><br />

                                      </td>
                                      <td className="col-3 text-start">
                                        <span className="fw-bold">{this.props.t("Valid Till")}:</span><br />

                                      </td>


                                    </tr>
                                    <tr>
                                      <td className="col-4 text-start">
                                        <span className="fw-bold">{this.props.t("Driving License Number")}:</span><br />
                                        <br />
                                      </td>
                                      <td className="col-3 text-start">
                                        <span className="fw-bold">{this.props.t("Phone")}:</span><br />

                                      </td>

                                      <td className="col-5 text-start" colSpan="2">
                                        <span className="fw-bold">{this.props.t("Address")}:</span><br />

                                      </td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div></> : null}
                            <h5 className="text-start fw-bold mt-2 mb-2">{this.props.t("Charges & Fees")} : </h5>
                            <div className="">
                              <table className="table table-border  table-sm">
                                <thead className="card-header">
                                  <tr>
                                    <td className="col-4 text-start fw-bold">{this.props.t("Description")}</td>
                                    <td className="col-3 text-end fw-bold">{this.props.t("Unit Price")}</td>
                                    <td className="col-2 text-end fw-bold">{this.props.t("Unit")}{" (" + currency + ")" || ""}</td>
                                    <td className="col-2 text-end fw-bold">{this.props.t("Quantity")}</td>
                                    <td className="col-3 text-end fw-bold">{this.props.t("Total")}{" (" + currency + ")" || ""}</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="col-4 text-start">{this.props.t("Net de location")} </td>
                                    <td className="col-3 text-end">{reservation.amount / reservation.days}</td>
                                    <td className="col-2 text-end">{this.props.t("Days")}</td>
                                    <td className="col-2 text-end">{reservation.days}</td>
                                    <td className="col-3 text-end">{reservation.amount}</td>
                                  </tr>

                                  <tr>
                                    <td className="col-4 text-start">{this.props.t("Delivery fee")}<br /></td>
                                    <td className="text-end">{reservation.frais_livraison}</td>
                                    <td className="text-end">{this.props.t("-")}</td>
                                    <td className="text-end">{1}</td>
                                    <td className="text-end">{reservation.frais_livraison}</td>
                                  </tr>
                                  <tr>
                                    <td className="col-4 text-start">{this.props.t("Return fee")}<br /></td>
                                    <td className="text-end">{reservation.frais_reprise}</td>
                                    <td className="text-end">{this.props.t("-")}</td>
                                    <td className="text-end">{1}</td>
                                    <td className="text-end">{reservation.frais_reprise}</td>
                                  </tr>
                                  <tr>
                                    <td className="col-4 text-start">{this.props.t("Divers")}</td>
                                    <td className="text-end">{reservation.frais_divers}</td>
                                    <td className="text-end">{this.props.t("-")}</td>
                                    <td className="text-end">{1}</td>
                                    <td className="text-end">{reservation.frais_divers}</td>
                                  </tr>
                                </tbody>
                                <tfoot className="card-footer">
                                  <tr>
                                    <td colSpan="4" className="text-end border-bottom-0"><strong>{this.props.t("Sub Total")}:</strong></td>
                                    <td className="text-end border-bottom-0">{subtotal}</td>
                                  </tr>
                                  <tr>
                                    <td colSpan="4" className="text-end border-bottom-0"><strong>{this.props.t("TVA 20%")}:</strong></td>
                                    <td className="text-end border-bottom-0">{tva}</td>
                                  </tr>

                                  <tr>
                                    <td colSpan="4" className="text-end border-bottom-0 "><strong>{this.props.t("Total")}:</strong></td>
                                    <td className="text-end border-bottom-0"><strong>{total}</strong></td>
                                  </tr>
                                </tfoot>

                              </table>

                              <hr className="mb-1"></hr>

                              <p className="text-sm mt-1 mb-1">
                                <small>
                                  <strong>
                                    {this.props.t("The tenant acknowledges their responsibility for any damages incurred to the car unless the existence of another party responsible for the damages is established.")}
                                  </strong >
                                </small>
                              </p>

                              <hr className="mt-1"></hr>
                            </div>


                            <h5 className="text-start fw-bold mt-0 mb-2">{this.props.t("Signatures")} : </h5>
                            <table className="table border  rounded mb-0">
                              <tbody className="card-header">
                                <tr>
                                  <td className="col-4 text-start fw-bold">{agency.company_name}</td>
                                  <td className="col-4 text-center fw-bold">{this.props.t("The tenant")}</td>
                                  <td className="col-4 text-end fw-bold">{this.props.t("Return")}</td>
                                </tr>
                                <tr>
                                  <td className="col-4 text-start" >{use_signature && agency.esignature?<img
                                    src={agency.esignature}
                                    alt="Existing Signature"
                                    style={{
                                      width: '60%',
                                      height: '60%',
                                      objectFit: 'contain',

                                    }}
                                  />:null}</td>
                                  <td className="col-4 text-center" ><br /><br /><br /></td>
                                  <td className="col-4 text-end" ><br /><br /><br /></td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <Col className="d-flex align-items-center mb-2">
                                <div
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    border: '2px solid #000', // Border only
                                    marginRight: '10px',
                                  }}
                                ></div>
                                <span>kra kolo</span>
                              </Col> */}
                          </Container>

                         {/*  <div className="page-break"></div>
                          <Row >
                            <Col lg="12">
                              <Card>
                                <CardBody>
                                  <h5 className="mt-4">{this.props.t("Les termes et les conditions")}{"   -   "}{this.props.t("الأحكام و الشروط")}</h5>

                                  <div className="terms-container ">
                                    <Row>
                                      <Col className="french-terms">

                                        <table className="terms-table mt-2">

                                          <tbody>
                                            <tr>
                                              <td>
                                                <strong>1. Âge et assurance</strong>
                                                <ul>
                                                  <li>Âge minimum de location : 20 ans</li>
                                                  <li>Âge maximum de location : 68 ans</li>
                                                  <li>Assurance complète selon les conditions de la société</li>
                                                  <li>L&apos;assurance couvre les accidents de la route sans limitation</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>1. السن والتأمين</strong>
                                                <ul>
                                                  <li>الحد الأدنى للتأجير: 20 سنة</li>
                                                  <li>الحد الأقصى للتأجير: 68 سنة</li>
                                                  <li>تأمين شامل وفقًا لشروط الشركة</li>
                                                  <li>التأمين يغطي حوادث السير دون تحديد</li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <strong>2. Conducteur supplémentaire</strong>
                                                <ul>
                                                  <li>Un conducteur supplémentaire gratuit autorisé</li>
                                                  <li>Le conducteur supplémentaire doit être présent lors de la signature du contrat</li>
                                                  <li>Permis de conduire valide depuis au moins un an requis</li>
                                                  <li>Le conducteur supplémentaire assume les mêmes responsabilités que le conducteur principal</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>2. السائق الإضافي</strong>
                                                <ul>
                                                  <li>يُسمح بسائق إضافي واحد مجانًا</li>
                                                  <li>يجب حضور السائق الإضافي عند توقيع العقد</li>
                                                  <li>يجب تقديم رخصة قيادة سارية لمدة لا تقل عن سنة</li>
                                                  <li>يتحمل السائق الإضافي نفس مسؤوليات السائق الأساسي</li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <strong>3. Dommages et responsabilités</strong>
                                                <ul>
                                                  <li>Le locataire est responsable de tous les dommages, vols ou incendies</li>
                                                  <li>Le locataire supporte les pertes journalières de la société dues à l&apos;immobilisation du véhicule</li>
                                                  <li>Obligation d&apos;informer immédiatement la société de tout accident, vol ou incendie avec rapport de police</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>3. الأضرار والمسؤوليات</strong>
                                                <ul>
                                                  <li>المستأجر مسؤول عن جميع الأضرار، السرقة، أو الحريق</li>
                                                  <li>يتحمل المستأجر الخسائر اليومية للشركة نتيجة توقف السيارة</li>
                                                  <li>يجب إبلاغ الشركة فورًا بأي حادث، سرقة، أو حريق مع تقديم تقرير الشرطة</li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <strong>4. Durée de location</strong>
                                                <ul>
                                                  <li>Durée minimale de location : 48 heures</li>
                                                  <li>Tout dépassement d&apos;une heure entraîne la facturation d&apos;une journée supplémentaire</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>4. مدة الإيجار</strong>
                                                <ul>
                                                  <li>الحد الأدنى لفترة الإيجار: 48 ساعة</li>
                                                  <li>تجاوز الحد الأدنى بساعة واحدة يؤدي إلى فوترة يوم إضافي</li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <strong>5. Paiement</strong>
                                                <ul>
                                                  <li>Le paiement de la location doit être effectué à l&apos;avance</li>
                                                  <li>La société accepte les paiements par chèque</li>
                                                  <li>Le dépôt de garantie n&apos;est remboursable en aucun cas</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>5. الدفع</strong>
                                                <ul>
                                                  <li>يتم دفع مستحقات الإيجار مسبقًا</li>
                                                  <li>تقبل الشركة الدفع بالشيكات</li>
                                                  <li>مبلغ التأمين غير قابل للاسترداد تحت أي ظرف</li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <strong>6. Entretien et réparation</strong>
                                                <ul>
                                                  <li>Le véhicule est livré en bon état et doit être rendu dans le même état</li>
                                                  <li>Le locataire paie les frais de réparation en cas de dommage ou de panne</li>
                                                  <li>Les pannes mécaniques naturelles sont à la charge de la société propriétaire</li>
                                                  <li>Tous les cinq pneus doivent être en bon état lors de la restitution du véhicule</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>6. الصيانة والإصلاح</strong>
                                                <ul>
                                                  <li>تُسلم السيارة للمستأجر في حالة جيدة ويجب إعادتها بنفس الحالة</li>
                                                  <li>المستأجر يدفع مصاريف الإصلاح في حالة أي عطب أو تلف</li>
                                                  <li>الأعطاب الميكانيكية الطبيعية تتحملها الشركة المالكة</li>
                                                  <li> يجب أن تكون جميع العجلات الخمس في حالة جيدة عند إعادة السيارة</li>
                                                </ul>
                                              </td>
                                            </tr>




                                            <tr>
                                              <td>
                                                <strong>7. Utilisation du véhicule</strong>
                                                <ul>
                                                  <li>La location est personnelle et ne peut être transférée à une autre personne</li>
                                                  <li>Le locataire n&apos;a pas le droit de faire conduire une personne non mentionnée dans le contrat</li>
                                                  <li>Distance limitée à 200 km par 24 heures, avec un supplément de 0,85 {currency} par km supplémentaire</li>
                                                  <li>Un accord préalable est nécessaire pour les longs trajets</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>7. استعمال السيارة</strong>
                                                <ul>
                                                  <li>الإيجار للمستأجر شخصيًا وليس له الحق في نقله لشخص آخر</li>
                                                  <li> لا يحق للمستأجر اتخاذ سائق آخر غير المحدد في العقد</li>
                                                  <li>المسافة محددة بـ 200 كم في 24 ساعة، مع دفع 0.85 درهم لكل كم إضافي</li>
                                                  <li> يجب الاتفاق مسبقًا في حالة السفر لمسافات طويلة</li>
                                                </ul>
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <strong>8. Documents du véhicule</strong>
                                                <ul>
                                                  <li>Tous les documents relatifs au véhicule doivent être restitués lors du retour</li>
                                                  <li>La location est considérée comme continue jusqu&apos;à la restitution des documents du véhicule à la société</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>8. وثائق السيارة</strong>
                                                <ul>
                                                  <li>يجب إعادة كل الوثائق المتعلقة بالسيارة عند إرجاعها</li>
                                                  <li>يعتبر الإيجار مستمرًا حتى إعادة وثائق السيارة للشركة</li>
                                                </ul>
                                              </td>
                                            </tr>


                                            <tr>
                                              <td>
                                                <strong>9. Assurance</strong>
                                                <ul>
                                                  <li>L&apos;assurance couvre des dommages limités conformément à la réglementation en vigueur au Maroc</li>
                                                  <li>En cas d&apos;accident ou de dommage, un rapport de police doit être fourni</li>
                                                  <li>En cas de vol du véhicule, la police et la société de location doivent être informées immédiatement</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>9. التأمين</strong>
                                                <ul>
                                                  <li>يغطي التأمين أضرارًا محدودة وفقًا للوائح المعمول بها في المغرب</li>
                                                  <li>في حالة وقوع حادث أو ضرر، يجب إحضار تقرير الشرطة</li>
                                                  <li>في حالة سرقة السيارة، يجب إبلاغ الشرطة والشركة المؤجرة فورًا</li>
                                                </ul>
                                              </td>
                                            </tr>


                                            <tr>
                                              <td>
                                                <strong>10. Remarques importantes</strong>
                                                <ul>
                                                  <li>Le contrat de location est immédiatement annulé si le véhicule est conduit par une personne non mentionnée dans le contrat</li>
                                                  <li>Le contrat est annulé en cas de conduite imprudente ou d&apos;utilisation acrobatique du véhicule</li>
                                                  <li>Le locataire est responsable de toutes les infractions au code de la route et des amendes qui en résultent</li>
                                                </ul>
                                              </td>
                                              <td className="arabic-terms">
                                                <strong>10. ملاحظات هامة</strong>
                                                <ul>
                                                  <li>يتم إلغاء عقد الإيجار فورًا إذا ثبت قيادة السيارة من شخص غير الموقع على العقد</li>
                                                  <li>يتم إلغاء العقد في حالة القيادة المتهورة أو القيام بألعاب بهلوانية بالسيارة</li>
                                                  <li>المستأجر مسؤول عن جميع المخالفات المرورية والغرامات المالية الناتجة عنها</li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </Col>


                                    </Row>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
 */}                        </div>
                        <div className="print-footer">

                          <p><strong>{agency.company_name}</strong><br />
                            {agency.address} - {this.props.t("Email")} : {agency.email} <br />
                            {this.props.t("Tél")}: {agency.phone} - {this.props.t("RC")} : {agency.rc} - {this.props.t("T.P")}: {agency.tp} - {this.props.t("ICE")}: {agency.ice} - {this.props.t("IF")}: {agency.id_fiscal}.
                          </p>
                        </div>
                      </div>
                    </CardBody>


                  </Card>
                  <div className="d-print-none mb-4">
                    <div className="float-center">
                      <Link
                        to="#"
                        onClick={this.printInvoice}
                        className="btn btn-success me-1"
                      >
                        {this.props.t("Print")}{" "}<i className="fa fa-print" />
                      </Link>{" "}
                      {/* <Link
                          to="#"
                          onClick={generatePDF}
                          className="btn btn-primary me-1"
                        >
                          {this.props.t("Save as PDF")}{" "}<i className="fa fa-file-pdf" />
                        </Link>{" "} */}
                    </div>
                  </div>
                </Col>

              }


            </Row>
          </Container>

        </div>
      </React.Fragment>
    )
  }
}

PrintContract.propTypes = {
  invoiceDetail: PropTypes.object,
  reservation: PropTypes.object,
  match: PropTypes.object,
  onGetInvoiceDetail: PropTypes.func,
  t: PropTypes.any
}

const mapStateToProps = ({ invoices }) => ({
  invoiceDetail: invoices.invoiceDetail,
})

const mapDispatchToProps = dispatch => ({
  onGetInvoiceDetail: id => dispatch(getInvoiceDetail(id)),
})


export default connect(
  mapStateToProps, mapDispatchToProps
)(withRouter(withTranslation()(PrintContract)))