import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types'
import { withTranslation } from "react-i18next"

class Apaexlinecolumn extends Component {
  constructor(props) {
    super(props)
    const currency = this.props.currency || "" // Capture currency in constructor

    this.state = {
      options: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "60%",
            endingShape: "rounded",
            borderRadius: 5
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1.8,
          colors: ["transparent"],
        },
        colors: ["#34c38f", "#556ee6", "#f46a6a"],
        xaxis: {
          categories: [
            this.props.t("Jan"),
            this.props.t("Feb"),
            this.props.t("Mar"),
            this.props.t("Apr"),
            this.props.t("May"),
            this.props.t("Jun"),
            this.props.t("Jul"),
            this.props.t("Aug"),
            this.props.t("Sep"),
            this.props.t("Oct"),
            this.props.t("Nov"),
            this.props.t("Dec")
          ],
        },
        yaxis: {
          title: {
            text: currency,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `${val}${currency}` // Use arrow function and captured currency
          },
        },
      },
    }
  }

  componentDidUpdate(prevProps) {
    // Update options when currency prop changes
    if (prevProps.currency !== this.props.currency) {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          yaxis: {
            ...prevState.options.yaxis,
            title: {
              text: this.props.currency || ""
            }
          },
          tooltip: {
            y: {
              formatter: (val) => `${val}${this.props.currency || ""}`
            }
          }
        }
      }));
    }
  }

  render() {
   //console..log(this.props.currency)
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.props.chartSeries || []}
          type="bar"
          height={350}
          className="apex-charts mb-2"
        />
      </React.Fragment>
    )
  }
}

Apaexlinecolumn.propTypes = {
  currency: PropTypes.any,
  chartSeries: PropTypes.any,
  t: PropTypes.any
}

export default withTranslation()(Apaexlinecolumn);