import firebase from "firebase/app"

// Add the Firebase products that you want to use
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import toastr from "toastr";


class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig)
      firebase.auth().onAuthStateChanged(user => {
       //console..log("user",user )
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user))
        } else {
          localStorage.removeItem("authUser")
        }
      })
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /* updateStaff = (staff) => {
    //console.log(staff.id)
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")
  
                  collection.doc(staff.id).update(staff).then(() => {
                    //console.log("Document Updated!");
                    toastr.success("Document Updated!")
                  }).catch((error) => {
                   //console..error("Error updating document: ", error);
                    toastr.error("Error updating document")
                  });

  } */

  /*  deleteStaff = (staff) => {
 
     const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")
     
          
 
           var strRef = firebase.storage().ref(staff.id_file)
           strRef.delete().then(() => {
             //console.log("tmse7")
 
             var strRef1 = firebase.storage().ref(staff.id_file1)
             strRef1.delete().then(() => {
               //console.log("tmse71")
               collection.doc(staff.id).delete().then(() => {
                 //console.log("Element  deleted!");
                 toastr.success("Element  deleted!")
               }).catch((error) => {
                //console..error("Error removing element: ", error);
                 toastr.error("Error removing element: ", error);
               });
             }).catch((error) => {
              //console..error("Error removing element: ", error);
               toastr.error("Error removing element: ", error);
             });
           }).catch((error) => {
            //console..error("Error removing element: ", error);
             toastr.error("Error removing element: ", error);
           });  
 
          
 
         // //console.log(doc.id, " => ", doc.data().email);
      
     
   } */
  /* addNewStaff = (staff) => {
    

    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")
    var em = 0, mo = 0, id = 0
    var query = collection.where("email", "==", staff.email).get()
      .then((querySnapshot) => {
        em = querySnapshot.docs.length

        var queryM = collection.where("mobile", "==", staff.mobile).get()
          .then((querySnapshot) => {
            mo = querySnapshot.docs.length
            var queryID = collection.where("id_number", "==", staff.id_number).get()
              .then((querySnapshot) => {
                id = querySnapshot.docs.length

                if (em == 0 && mo == 0 && id == 0) {
                  
                  var newDoc=collection.doc();
                  newDoc.set(
                    {
                      id:newDoc.id,
                      first_name: staff.first_name,
                      last_name: staff.last_name,
                      email: staff.email,
                      mobile: staff.mobile,
                      designation: staff.designation,
                      address: staff.address,
                      city: staff.city,
                      state: staff.state,
                      gender: staff.gender,
                      date_birth: staff.date_birth,
                      id_number: staff.id_number,

                    }).then(() => {
                    //console.log("stf  written!");
                    toastr.success('Staff member added  ')
                  })
                    .catch((error) => {
                     //console..error("Error Adding staff member: ", error);
                      toastr.error("Error Adding staff member")
                    });

                } else {
                  if (em != 0) {
                   //console..error("Email already exist ");
                    toastr.error("Email already exist ")
                  }
                  if (mo != 0) {
                   //console..error("Mobile number already exist ");
                    toastr.error("Mobile number already exist ")
                  }
                  if (id != 0) {
                   //console..error("ID number already exist ");
                    toastr.error("ID number already exist ")
                  }
                }

              })
              .catch((error) => {
               //console..log("Error getting documents ");
                toastr.error("Error Adding staff member")
              });
          })
          .catch((error) => {
           //console..log("Error getting documents ");
            toastr.error("Error Adding staff member")
          });
      })
      .catch((error) => {
       //console..log("Error getting documents ");
        toastr.error("Error Adding staff member")
      });



  }
 */

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }



  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  /*  logout = () => {
     return new Promise((resolve, reject) => {
       firebase
         .auth()
         .signOut()
         .then(() => {
           resolve(true)
         })
         .catch(error => {
           reject(this._handleError(error))
         })
     })
   } */

     logout = () => {
      return new Promise(async (resolve, reject) => {
       /*  // Step 1: Clean up all Firestore listeners
        const firestore = firebase.firestore(); // Get Firestore instance
        const activeListeners = firestore._listeners; // Access active listeners
       //console..log("active listeners ", activeListeners)
        if (activeListeners && activeListeners.length > 0) {
          activeListeners.forEach(listener => {
            listener(); // Unsubscribe each listener
          });
        }
     */
        // Step 2: Proceed with logout
        await firebase
          .auth()
          .signOut()
          .then(() => {
            resolve(true); // Logout successful
          })
          .catch(error => {
            reject(this._handleError(error)); // Handle logout error
          });
      });
    };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {}
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(data.idToken, data.token)
      //console.log("google.............................")
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token)
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase.auth().signInWithCredential(credential)
          .then(user => {
            resolve(this.addNewUserToFirestore(user))
          })
          .catch(error => {
            reject(this._handleError(error))
          })
      } else {
        reject(this._handleError(error))
      }
    })
  }

  addNewUserToFirestore = (user) => {
    const collection = firebase.firestore().collection("users")
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    }
    collection.doc(firebase.auth().currentUser.uid).set(details).then(() => {
      //console.log("user  written!");
    })
      .catch((error) => {
       //console..error("Error writing user: ", error);
        toastr.danger('Error ' + error)
      });
    return { user, details }
  }

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
