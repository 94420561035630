import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import axios from "axios";
import React, { Component } from "react";
import SignaturePad from "react-signature-canvas";
import BootstrapTable from "react-bootstrap-table-next"
import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  CardText,
  CardTitle,
  Badge,
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Input,
  InputGroupText,
  InputGroup
} from "reactstrap";
import 'firebase/auth';
import SweetAlert from "react-bootstrap-sweetalert"
import { v4 as uuid } from "uuid"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
import imageCompression from 'browser-image-compression';
import ConnectDrive from './ConnectDrive';
import geohash from "ngeohash";

import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';
import * as moment from 'moment';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { collect } from 'underscore';
import { icon } from 'leaflet';
import { GoogleAuth } from 'google-auth-library';
import { withTranslation } from "react-i18next"
const LoadingContainer = () => <div>Loading...</div>

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.signaturePadRef = React.createRef();
    this.state = {
      modalBranch: false,
      showNewSignaturePad: false,
      dlLoading: false,
      documentsLogo: null,
      user_id: "",
      isAdmin: false,
      account_email: "",
      redirecting: false,
      //account_user: [],
      email: "",
      name: "",
      idx: 1,
      user: [],
      branch: [],
      backingUp: false,
      submitLoading: false,
      imageLoading: false,
      modal: false,
      isReset: false,
      isResetUser: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      mapmodal: false,
      signmodal: false,
      permissionModal: false,
      userAddress: null,
      db: "",
      userCity: null,
      userState: null,
      addressLoading: false,
      markerTitle: "The marker`s title will appear as a tooltip.",
      markerName: "Medios",
      markerPosition: { lat: 37.778519, lng: -122.40564 },
      br: [],
      brchs: [],
      BranchColumns: [
        {
          dataField: "name",
          text: this.props.t("BranchName"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.name}
            </Link>
          ),
        },
        {
          dataField: "address",
          text: this.props.t("Branch Address"),
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.address}{" , "}{row.city}
            </>
          ),
        },
        {
          dataField: "isActive",
          text: this.props.t("Status"),
          sort: true,
          formatter: (cellContent, row) => (

            <Badge
              className={row.isActive ? "font-size-12 badge-soft-success" : "font-size-12 badge-soft-warning"}
              color={row.isActive}
              pill
            >
              {row.isActive ? this.props.t("Active") : this.props.t("Inactive")}
            </Badge>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Actions"),
          formatter: (cellContent, branch) => (
            <>
              {!branch.default ? <div className="d-flex gap-3">

                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleBranchClick(branch)} />
                </Link>

                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.handleDeleteClick(branch)} />
                </Link>
              </div> : null}

            </>
          ),
        },
      ]
    }

    // handleValidSubmit
    this.handleBranchClicks = this.handleBranchClicks.bind(this)
    this.handleBranchClick = this.handleBranchClick.bind(this)
    this.handleValidBranchSubmit = this.handleValidBranchSubmit.bind(this)
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handlePWChange = this.handlePWChange.bind(this)
    this.handlePWSubmit = this.handlePWSubmit.bind(this)
    this.handlePWSubmitUser = this.handlePWSubmitUser.bind(this)
    this.toggleBranch = this.toggleBranch.bind(this)
    this.togglePermission = this.togglePermission.bind(this)
    this.toggleReset = this.toggleReset.bind(this)
    this.toggleResetUser = this.toggleResetUser.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleViewModal = this.toggleViewModal.bind(this)
    this.toggleSignModal = this.toggleSignModal.bind(this)
    this.getLocation = this.getLocation.bind(this)
    this.getCoordinates = this.getCoordinates.bind(this)
    this.getUserAddress = this.getUserAddress.bind(this)
    this.onClickMarker = this.onClickMarker.bind(this)
    this.handleEditPicture = this.handleEditPicture.bind(this)
    this.handleDLChange = this.handleDLChange.bind(this)
    this.handleEditDL = this.handleEditDL.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
  }
  handleClear = () => {
    if (this.signaturePadRef.current) {
      this.signaturePadRef.current.clear(); // Clear the signature pad
    }

  };
  handleUploadSignature = async () => {
    const signaturePad = this.signaturePadRef.current;
    const user_id = this.state.user_id; // Assuming you're passing user_id as a prop
    const doc = firebase.firestore().collection("users").doc(user_id)
    if (signaturePad.isEmpty()) {
      toast.error(this.props.t('Please provide a signature first'));
      return;
    }

    try {
      // Show loading state
      this.setState({ isUploading: true });

      // Convert signature to blob
      const dataURL = signaturePad.toDataURL('image/png');
      const response = await fetch(dataURL);
      const blob = await response.blob();

      // Create a reference to the file location in Firebase
      const fileName = `signature_${new Date().getTime()}.png`;
      const signatureRef = firebase.storage().ref(`${user_id}/esign/${fileName}`);

      // Upload the file
      const uploadTask = signatureRef.put(blob);

      // Monitor upload progress
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Optional: Track upload progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
         //console..log('Upload progress: ', progress);
        },
        (error) => {
          // Handle upload errors
          //console.error('Error uploading signature:', error);
          toast.error(this.props.t('Error uploading signature. Please try again.'));
          this.setState({ isUploading: false });
        },
        async () => {
          // Upload completed successfully
          try {
            // Get the download URL
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();

            // Save the URL to your state or database if needed
            doc.update({ esignature: downloadURL })
            this.setState({
              signatureURL: downloadURL,
              isUploading: false,
              isEditingSignature: false
            });

            // Close modal and show success message
            this.toggleSignModal();
            toast.success(this.props.t('Signature uploaded successfully!'));

          } catch (error) {
            //console.error('Error getting download URL:', error);
            toast.error(this.props.t('Error saving signature. Please try again.'));
            this.setState({ isUploading: false });
          }
        }
      );

    } catch (error) {
      //console.error('Error processing signature:', error);
      toast.error(this.props.t('Error processing signature. Please try again.'));
      this.setState({ isUploading: false });
    }
  };
  handleSave = () => {
    // Get reference to the signature pad
    const signaturePad = this.signaturePadRef.current;

    // Check if the signature pad is empty
    if (signaturePad.isEmpty()) {
      // You can replace this with your preferred notification system
      alert('Please provide a signature first');
      return;
    }

    try {
      // Get the signature as a data URL (PNG format by default)
      const dataURL = signaturePad.toDataURL();

      // Create a temporary link element
      const downloadLink = document.createElement('a');

      // Set the download attribute and filename
      downloadLink.download = `signature-${new Date().getTime()}.png`;

      // Set the href to the data URL
      downloadLink.href = dataURL;

      // Add to document, click it, and remove it
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Optionally close the modal after saving
      this.toggleSignModal();

      // Optional: Show success message
      toast.success(this.props.t('Signature saved successfully!'));
    } catch (error) {
      //console.error('Error saving signature:', error);
      toast.error(this.props.t('Error saving signature. Please try again.'));
    }
  };
  onClickMarker(t, map, coord) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    this.getUserAddress(lat, lng)
    this.setState({

      markerTitle: "",
      markerName: "",
      markerPosition: { lat, lng }

    });

  }
  handleDLChange = (event) => {
    this.setState({ documentsLogo: event.target.files[0] });
  };
  toggleViewModal = () => {
    //this.getLocation()
    this.setState(prevState => ({
      mapmodal: !prevState.mapmodal,
    }))
  }
  toggleSignModal = () => {
    //this.getLocation()

    this.handleClear()

    this.setState(prevState => ({
      signmodal: !prevState.signmodal,
      isEditingSignature: false
    }))
  }
  // Add these methods to handle the signature state
  handleChangeSignature = () => {
    const signaturePad = this.signaturePadRef.current;
    this.setState({ isEditingSignature: true }, () => {
      if (signaturePad) {
        signaturePad.clear();
      }
    });
  };
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError)
    } else {
      alert(this.props.t("geolocation is not supported"))
    }
  }
  getCoordinates(position) {
    //console.log(position)
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      markerPosition: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    })
    this.getUserAddress(position.coords.latitude, position.coords.longitude)
  }
  handleLocationError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        toast.error(this.props.t("User denied the request for Geolocation."))
        break;
      case error.POSITION_UNAVAILABLE:
        toast.error(this.props.t("Location information is unavailable."))
        break;
      case error.TIMEOUT:
        toast.error(this.props.t("The request to get user location timed out."))
        break;
      case error.UNKNOWN_ERROR:
        toast.error(this.props.t("An unknown error occurred."))
        break;
      default:
        toast.error(this.props.t("An unknown error occurred."))
    }
  }
  getUserAddress(lat, lng) {
    //console.log("get user address", lat, lng)
    var key = "AIzaSyANuYDpzsSpPUtQHTGJPoBz9EKWelMNNtg"
    this.setState({
      addressLoading: true
    })
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${key}`)
      .then(response => response.json())
      .then(data => {
        //console.log(data.results)
        this.setState({
          addressLoading: false
        })
        if (data.results[0]) {

          for (var i = 0; i < data.results.length; i++) {
            if (data.results[i].types[0] === "route") {
              var address = data.results[i].formatted_address;
              //console.log(address)
              this.setState({
                userAddress: address
              })
            };
            if (data.results[i].types[0] === "locality") {
              var city = data.results[i].address_components[0].short_name;
              var state = data.results[i].address_components[1].short_name;
              ////console..log(city)
              //console.log(state)
              this.setState({
                userCity: city,
                userState: state,

              })
            };
          };
        };
      }
      )
      .catch(error => alert("error" + error))
  }

  toggleReset() {
    this.setState(prevState => ({
      isReset: !prevState.isReset,
    }))
  }
  toggleResetUser() {
    this.setState(prevState => ({
      isResetUser: !prevState.isResetUser,
    }))
  }
  handlePWChange = () => {

    this.toggle()
  }
  resetPassword = (email) => {
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        this.setState({
          success_dlg: true,
          dynamic_title: "Link sent",
          dynamic_description: "An email with password reset link has been sent",
        })
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        ////console..log(errorCode, errorMessage)
        toast.error(this.props.t("Cannot sent email"))
        // ..
      });
  }
  handlePWSubmit() {
    this.resetPassword(this.state.user.email)
    this.toggleReset()
  }
  handlePWSubmitUser() {
    this.resetPassword(this.state.user.email)
    this.toggleResetUser()
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  getUser = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userUsersCollectionRef = usersCollection.doc(userId)
            const userData = userDoc.data();
            this.setState({
              user: userData,
              user_id: userId,
              signatureURL: userData.esignature
            })

            this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
              this.setState({
                image_url: snapshot.data().image_url,
              })
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });

  }
  // handleValidSubmit
  handleValidSubmit(event, values) {

    const updateProfile = {
      id: this.state.user.id,
      // username: values.username.trim(),
      company_name: values.company_name.trim(),
      phone: values.phone.trim(),
      address: values.address.trim(),
      city: values.city.trim(),
      state: values.state.trim(),
      description: values.description.trim(),
      website: values.website.trim(),
      rc: values.rc.trim(),
      patente: values.patente.trim(),
      id_fiscal: values.id_fiscal.trim(),
      ice: values.ice.trim(),
      tp: values.tp.trim()
    }
    //console.log(this.state.user)
    //console.log(updateProfile)
    if (/* this.state.user.username == updateProfile.username
        && */ this.state.user.company_name == updateProfile.company_name
      && this.state.user.phone == updateProfile.phone
      && this.state.user.address == updateProfile.address
      && this.state.user.city == updateProfile.city
      && this.state.user.state == updateProfile.state
      && this.state.user.description == updateProfile.description
      && this.state.user.website == updateProfile.website
      && this.state.user.rc == updateProfile.rc
      && this.state.user.ice == updateProfile.ice
      && this.state.user.tp == updateProfile.tp
      && this.state.user.patente == updateProfile.patente
      && this.state.user.id_fiscal == updateProfile.id_fiscal) {
      this.setState({ submitLoading: false })
    } else {
      this.editProfile(updateProfile)
    }

  }

  handleValidBranchSubmit(event, values) {
    //this.props.editProfile(values)
    if (this.state.isEdit) {
      const updateBranch = {
        id: this.state.br.id,
        name: values.name.trim(),
        address: values.address.trim(),
        city: values.city.trim()
      }

      if (this.state.br.name == updateBranch.name
        && this.state.br.address == updateBranch.address
        && this.state.br.city == updateBranch.city) {
        this.setState({ branchLoading: false })
        this.toggleBranch()
      } else {
        this.editBranch(updateBranch)
      }

    } else {

      const newBranch = {
        name: values["name"],
        address: values["address"],
        city: values['city'],
      }

      this.setState({ br: newBranch })
      this.addNewBranch(newBranch)

    }

  }
  addNewTimeline = async (timeline) => {
    const branch = JSON.parse(localStorage.getItem("branch"));

    if (!branch) {
      //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
    try {
      const { user_id, user } = this.state;
      //console.log(timeline, user)
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = user.isAdmin ? 'Admin' : `${user.first_name} ${user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        branchId: branch,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      //console.error("Error adding timeline:", error);
    }
  }



  editBranch = async (branch) => {
   //console..log(branch)
    const timeline = {
      statusTitle: "Branch updated",
      iconClass: "bx bx-user h2 text-warning",
      description: "Branch information has been updated for ",
      data: branch.name,
      time: new Date().getTime(),
    }

    this.setState({ branchLoading: true });
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("branches");


    try {
      // Get current document to check for existing files
      const currentDoc = await collection.doc(branch.id).get();

      // Update document with new data
      await collection.doc(branch.id).update({
        name: branch.name,
        address: branch.address,
        city: branch.city,

      });

      toast.success(this.props.t("Branch infos Updated!"));
      this.addNewTimeline(timeline);
      this.setState({ branchLoading: false });
      this.toggleBranch();

    } catch (error) {
     //console..error("Error updating document: ", error);
      toast.error(this.props.t("Error updating Branch infos"));
      this.setState({ branchLoading: false });
    }


  }
  addNewBranch = async (branch) => {
    this.setState({ BranchLoading: true });

    try {
      const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("branches");
      const timeline = {
        statusTitle: "Branch added",
        iconClass: "bx bx-user h2 text-success",
        description: "Branch information has been added ",
        data: branch.name,
        time: new Date().getTime(),
      };

      var newDoc = collection.doc();

      await newDoc.set({
        id: newDoc.id,
        name: branch.name,
        address: branch.address,
        city: branch.city,
        isActive: false,
        default: false,
        createdAt: new Date().getTime()
      });

      toast.success(this.props.t('Branch added'));
      this.addNewTimeline(timeline);
      this.setState({
        branchLoading: false
      })
      this.toggleBranch();

    } catch (error) {
      //console.error("Error Adding Branch: ", error.code);
      if (error.code === "permission-denied") {
        this.setState({ submitLoading: false, permissionModal: true });
        return;
      }
      toast.error(this.props.t("Error Adding Branch"));
      this.setState({ submitLoading: false });
    }

  }

  editProfile = async (profile) => {

    this.setState({ submitLoading: true })
    const usersCollection = firebase.firestore().collection("users")
    //const collection = firebase.firestore().collection("usernames")
    const timeline = {
      statusTitle: "Profile infos updated",
      iconClass: "bx bx-pencil h2 text-success",
      description: "Profile infos updated",
      data: "",
      time: new Date().getTime(),

    }
    usersCollection
      .get().then(result => {

        var userKayn = false
        var phoneKayn = false
        result.forEach(async doc => {
          if (doc.data().id !== profile.id) {
            /* if (doc.data().username === profile.username) {
              userKayn = true
            } */
            if (doc.data().phone === profile.phone) {
              phoneKayn = true
            }

          }
        })

        //console.log(userKayn, phoneKayn)
        /*  if (!userKayn) {
           if (!phoneKayn) { */
        usersCollection.doc(profile.id).update({
          // username: profile.username,
          company_name: profile.company_name,
          phone: profile.phone,
          address: profile.address,
          city: profile.city,
          state: profile.state,
          description: profile.description,
          website: profile.website,
          rc: profile.rc,
          patente: profile.patente,
          id_fiscal: profile.id_fiscal,
          ice: profile.ice,
          tp: profile.tp

        }).then(() => {

          //console.log("Document Updated!");
          toast.success(this.props.t("Profile  Updated!"))
          this.addNewTimeline(timeline)
          this.setState({ submitLoading: false })

        }).catch((error) => {
          ////console..error("Error updating document: ", error);
          toast.error(this.props.t("Error updating document"))
          this.setState({ submitLoading: false })
        });
        /* } else {
         //console..error("Phone number already exist ");
          toast.error("Phone number already exist ")
          this.setState({ submitLoading: false })
        }
      } else {
       //console..error("Username already exist ");
        toast.error("Username already exist ")
        this.setState({ submitLoading: false })
      } */
      })

  }

  changeLocation = async (lat, lng, address, city, state) => {

    this.setState({ submitLoading: true })
    const usersCollection = firebase.firestore().collection("users")
    //const collection = firebase.firestore().collection("usernames")
    const timeline = {
      statusTitle: "Location changed",
      iconClass: "bx bx-pencil h2 text-success",
      description: "Agency localisation changed",
      data: "",
      time: new Date().getTime(),

    }
    usersCollection
      .get().then(result => {


        if (lat != null || lng != null) {
          const hash = geohash.encode(lat, lng);
          if (address != null || city != null || state != null) {
            usersCollection.doc(this.state.user.id).update({

              address: address,
              city: city,
              state: state,
              lat: lat,
              lng: lng,
              position_hash: hash

            }).then(() => {

              //console.log("Document Updated!");
              toast.success(this.props.t("Location  Changed!"))
              this.addNewTimeline(timeline)
              this.setState({ submitLoading: false })
              this.toggleViewModal()

            }).catch((error) => {
              //console.error("Error updating document: ", error);
              toast.error(this.props.t("Error Changing location"))
              this.setState({ submitLoading: false })
              this.toggleViewModal()
            });
          } else {
            ////console..error("Cannot get location ");
            toast.error(this.props.t("Cannot get location"))
            this.setState({ submitLoading: false })
            this.toggleViewModal()
          }
        } else {
          //console.error("Cannot get Address ");
          toast.error(this.props.t("cannot get Address"))
          this.toggleViewModal()
          this.setState({ submitLoading: false })
        }
      })




  }

  async getCurrency() {
    try {
      // Step 1: Get the user's country from GeoPlugin
      const geoResponse = await axios.get("http://www.geoplugin.net/json.gp");
      const countryName = geoResponse.data.geoplugin_countryName;

      if (!countryName) {
       //console..error("Country not found in GeoPlugin response");
        return null;
      }

     //console..log("User's country:", countryName);

      // Step 2: Get the currency using RestCountries API
      const countryResponse = await axios.get(`https://restcountries.com/v3.1/name/${countryName}`);
      const countryData = countryResponse.data[0];
      const currency = Object.keys(countryData.currencies)[0]; // Get the first currency key

     //console..log("Currency:", currency);
      return { country: countryName, currency };
    } catch (error) {
     //console..error("Error fetching currency or country:", error);
      return null;
    }
  }
  async getAllCurrencies() {
    try {
      // Fetch all countries data
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const countries = response.data;

      // Create a mapping of country to its currency
      const countryCurrencyMap = countries.reduce((acc, country) => {
        if (country.name?.common && country.currencies) {
          const countryName = country.name.common;
          const currencyCodes = Object.keys(country.currencies).join(", "); // Handle multiple currencies
          acc[countryName] = currencyCodes;
        }
        return acc;
      }, {});

      ////console..log("Country-Currency Map:", countryCurrencyMap);
      return countryCurrencyMap;
    } catch (error) {
     //console..error("Error fetching currencies by country:", error);
      return {};
    }
  }

  componentDidMount() {
    // this.getCurrency()
    //this.getAllCurrencies()
    const { user } = this.state
    if (user && !user.length) {
      //onGetStaff()
      this.getUser()

    }
    this.getBranch()
    this.setState({ user })
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        })
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid })
      }
    }
  }
  getBranch = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeBbranches) {
      this.unsubscribeBranches();
    }

    this.unsubscribeBranches = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userBranchesCollectionRef = usersCollection.doc(userId).collection("branches");

            this.unsubscribeBranches = userBranchesCollectionRef.onSnapshot(snapshot => {
              let f = [];
              snapshot.docs.forEach(doc => {
                f.push(doc.data());
              });

              this.setState({
                loading: false,
                brchs: f
              });
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              brchs: []
            });
          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          brchs: []
        });
      }
    });


  }
  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (prevState.user.verification_done !== this.state.user.verification_done && this.state.user.verification_done == true) {

      ////console..log("state changed ", this.state.user.verification_done)
      // toast.success("Image verification done")


    }

    if (this.props !== prevProps) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      //console.log(obj)
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        })
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid })
      }
      setTimeout(() => {
        this.props.resetProfileFlag();
      }, 3000);
    }
  }
  handleImageChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const image = event.target.files[0]
    ////console..log("file ", event.target.files[0])
    this.setState({ imageLoading: true })
    const user = this.state.user.id
    const iid = uuid()
    const img = firebase.storage().ref(firebase.auth().currentUser.uid + "/userspp/").child(iid + ".png")
    const doc = firebase.firestore().collection("users").doc(user)
    var metadata = {
      contentType: 'image/png',
    };


    if (image !== undefined) {
      var validSize = true
      ////console..log(image.size / 1024 / 1024 + "Mo")
      if (image.size / 1024 / 1024 > 2) {
        validSize = false
      } else {
        validSize = true
      }
    }

    const timeline = {
      statusTitle: "Profile picture changed",
      iconClass: "bx bx-image-add h2 text-success",
      description: "Profile picture changed",
      data: "",
      time: new Date().getTime(),

    }
    //send to server
    if (image != undefined) {
      if (validSize) {

        img.put(image, metadata).then((snapshot) => {

          snapshot.ref.getDownloadURL().then(url => {
            doc.update({ image_url: url, image_path: img.fullPath, verification_done: false })
            //console.log("Image uploaded ");
            toast.success(this.props.t("Profile image uploaded"))
            this.addNewTimeline(timeline)
            this.setState({ imageLoading: false })
          });
        }).catch((error) => {
          //console.log("Error uploading image " + error);
          toast.error(this.props.t("Error uploading image"))
          this.setState({ imageLoading: false })
        });


      } else {
        //console.log("Image size is too big");
        toast.error(this.props.t("Image size is too big"))
        this.setState({ imageLoading: false })
      }
    } else {
      ////console..log("Error uploading image");
      toast.error(this.props.t("Error uploading image"))
      this.setState({ imageLoading: false })
    }
  }
  handleEditDL = () => {
    const { documentsLogo } = this.state
    const image = documentsLogo
    ////console..log("file ", event.target.files[0])
    this.setState({ dlLoading: true })
    const user = this.state.user.id
    const iid = uuid()
    const img = firebase.storage().ref(firebase.auth().currentUser.uid + "/usersdl/").child(iid + ".png")
    const doc = firebase.firestore().collection("users").doc(user)
    var metadata = {
      contentType: 'image/png',
    };


    if (image !== undefined) {
      var validSize = true
      ////console..log(image.size / 1024 / 1024 + "Mo")
      if (image.size / 1024 / 1024 > 2) {
        validSize = false
      } else {
        validSize = true
      }
    }

    const timeline = {
      statusTitle: "Documents logo changed",
      iconClass: "bx bx-image-add h2 text-success",
      description: "Documents logo changed",
      data: "",
      time: new Date().getTime(),

    }
    //send to server
    if (image != undefined) {
      if (validSize) {

        img.put(image, metadata).then((snapshot) => {

          snapshot.ref.getDownloadURL().then(url => {
            doc.update({ documents_logo: url })
            //console.log("Image uploaded ");
            toast.success(this.props.t("Documents logo uploaded"))
            this.addNewTimeline(timeline)
            this.setState({ dlLoading: false, logo_url: url })
          });
        }).catch((error) => {
          //console.log("Error uploading image " + error);
          toast.error(this.props.t("Error uploading image"))
          this.setState({ dlLoading: false })
        });


      } else {
        //console.log("Image size is too big");
        toast.error(this.props.t("Image size is too big"))
        this.setState({ imageLoading: false })
      }
    } else {
      ////console..log("Error uploading image");
      toast.error(this.props.t("Error uploading image"))
      this.setState({ imageLoading: false })
    }
  }
  handleEditPicture = () => {
    var fileInput = document.getElementById("imageInput")
    //console.log(fileInput)
    fileInput.click()
  }

  handleBackup = async () => {
    // Assuming you have access to the userId, either from props or state
    const userId = this.state.user.id;

    if (!userId) {
     //console..error('User ID not available');
      return;
    }
    this.setState({ backingUp: true });

    try {
      const backup = firebase.app().functions('europe-west1').httpsCallable('backup');
      const result = await backup({ userId: userId })
      // Create a Blob from the base64 data
      const byteCharacters = atob(result.data.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: result.data.contentType });
      var check = moment(new Date()).format('DD-MM-YYYY');
      // Create a download link and click it
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `medios_backup(${check}).zip`;
      link.click();

      toast.success(this.props.t("Backup downloaded successfully"));
    } catch (error) {
     //console..error('Error:', error);
      toast.error(this.props.t("Failed to create the backup."));
    } finally {
      this.setState({ backingUp: false });
    }
    /*try {
      const functionUrl = 'https://europe-west1-meanz-3960c.cloudfunctions.net/backup';
      const response = await fetch(`${functionUrl}?userId=${userId}`, {
        method: 'GET',
      });

       if (response.ok) {
        var check = moment(new Date()).format('DD-MM-YYYY');
        //console.log(check)
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `medios_backup(${check}).zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        toast.error(this.props.t("Backup failed"))
       //console..error('Backup failed');
        // You might want to show an error message to the user here
      } 
    } catch (error) {
      toast.error(this.props.t("Backup failed"))
     //console..error('Error during backup:', error);
      // You might want to show an error message to the user here
    } finally {
      this.setState({ backingUp: false });
    }*/

  };

  handleDeleteClick = (branch) => {
    this.setState({ isDelete: true, db: branch });
  };


  handleBranchClick = arg => {
    const branch = arg
    this.setState({
      br: {
        id: branch.id,
        name: branch.name,
        address: branch.address,
        city: branch.city

      },
      isEdit: true,
      isShow: false,

    })

    this.toggleBranch()


  }
  handleBranchClicks = () => {

    this.setState({ br: [], isEdit: false, isShow: false }, () => {
      this.toggleBranch(); // Ensure toggle is called after state updates
    });


  }

  toggleBranch() {
    this.setState(prevState => ({
      modalBranch: !prevState.modalBranch,
    }))
  }

  deleteBranch = (branch) => {

    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("branches")
    const timeline = {
      statusTitle: "Branch deleted",
      iconClass: "bx bx-user h2 text-danger",
      description: "Branch information has been deleted for ",
      data: branch.name,
      time: new Date().getTime(),
    }
    collection.doc(branch.id).delete().then(() => {


      //console.log("Element  deleted!");
      toast.success(this.props.t("Branch infos deleted!"))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Branch infos has been deleted."),
      })
    }).catch((error) => {
     //console..error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"));

    });

  }
  handleDeleteBranch = (branch) => {

    this.deleteBranch(branch)

    this.toggleDelete()

  }



  toggleDelete() {

    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  getPlanLimits = (userData) => {
    const subscriptionPlanId = userData.subscription?.planId;
    const lifetimeAccess = userData.lifetimeAccess;

    // Check if the subscription plan is unlimited
    const isUnlimitedPlan =
      subscriptionPlanId === 'price_1QoNOqGFbCpQEhRTkiWwF3wZ' ||
      subscriptionPlanId === 'price_1QoNOqGFbCpQEhRTddXhmILJ';

    // If the subscription plan is unlimited, return unlimited limits
    if (isUnlimitedPlan) {
      return {
        invitations: null, // Unlimited
        vehicles: null,    // Unlimited
        branches: null,    // Unlimited
      };
    }

    // If lifetime access is true, return lifetime access limits
    if (lifetimeAccess) {
      return {
        invitations: 5,
        vehicles: 30,
        branches: 3,
      };
    }

    // Otherwise, check the subscription plan
    switch (subscriptionPlanId) {
      case 'price_1QoNLuGFbCpQEhRT16ld1V0Y':
      case 'price_1QoNLuGFbCpQEhRTo1w6doyB':
        return {
          invitations: 2,
          vehicles: 10,
          branches: 1,
        };
      case 'price_1QoNNVGFbCpQEhRTn1khi0aa':
      case 'price_1QoNNVGFbCpQEhRTvmg7sy5O':
        return {
          invitations: 5,
          vehicles: 30,
          branches: 3,
        };
      default:
        // Default to free trial limits
        return {
          invitations: 1,
          vehicles: 5,
          branches: 1,
        };
    }
  };

  getSubscriptionInterval = (planId) => {
    const planIntervalMapping = {
      starter: {
        monthly: 'price_1QoNLuGFbCpQEhRT16ld1V0Y',
        annual: 'price_1QoNLuGFbCpQEhRTo1w6doyB',
      },
      professional: {
        monthly: 'price_1QoNNVGFbCpQEhRTn1khi0aa',
        annual: 'price_1QoNNVGFbCpQEhRTvmg7sy5O',
      },
      unlimited: {
        monthly: 'price_1QoNOqGFbCpQEhRTkiWwF3wZ',
        annual: 'price_1QoNOqGFbCpQEhRTddXhmILJ',
      },
    };
    for (const planType in planIntervalMapping) {
      for (const interval in planIntervalMapping[planType]) {
        if (planIntervalMapping[planType][interval] === planId) {
          return interval; // 'monthly' or 'annual'
        }
      }
    }
    return null; // If no match is found
  };
  // Helper function to get plan name
  getPlanName = (planId) => {
    const { user } = this.state
    if (user.lifetimeAccess) {
      return 'Lifetime Access';
    }
    const plans = {
      'price_1QoNLuGFbCpQEhRT16ld1V0Y': 'Starter Plan',
      'price_1QoNLuGFbCpQEhRTo1w6doyB': 'Starter Plan',
      'price_1QoNNVGFbCpQEhRTn1khi0aa': 'Pro Plan',
      'price_1QoNNVGFbCpQEhRTvmg7sy5O': 'Pro Plan',
      'price_1QoNOqGFbCpQEhRTkiWwF3wZ': 'Unlimited Plan',
      'price_1QoNOqGFbCpQEhRTddXhmILJ': 'Unlimited Plan',
    };
    return plans[planId] || 'Free Trial';
  };
  redirectToPortal = async (customerId) => {
    try {
      this.setState({ redirecting: true })
      const createPortalLink = firebase.app().functions('europe-west1').httpsCallable('createPortalLink')
      const result = await createPortalLink({ customerId });
      //console.log(result.data.url)
      this.setState({ redirecting: false })
      window.location.href = result.data.url;
    } catch (error) {
      this.setState({ redirecting: false })
     //console..error('Error redirecting to portal:', error);
    }
  };
  togglePermission() {
    this.setState(prevState => ({
      permissionModal: !prevState.permissionModal,
    }))
  }
  render() {
    const branch = JSON.parse(localStorage.getItem("branch"));
    const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    const branches = JSON.parse(localStorage.getItem("branches")) || [];
    var av = ""
    const { signatureURL, user, image_url, logo_url } = this.state
    const { imageLoading } = this.state
    if (user.image_url === "" || image_url === "") {
      av = avatar;
    } else {
      av = user.image_url || image_url;
    }
    const planLimits = this.getPlanLimits(user);
    const planName = this.getPlanName(user.subscription?.planId);
    const isLifetimeAccess = user.lifetimeAccess;
    const subscriptionInterval = this.getSubscriptionInterval(user.subscription?.planId); // 'monthly' or 'annual'
    const nextPaymentDate = user.subscription?.currentPeriodEnd; // Next payment date
    const formattedDate = moment(nextPaymentDate).format("MMMM D, YYYY");
    ////console..log(user)
    //(image_url)
    return (
      <React.Fragment>
        <MetaTags>
          <title>{this.props.t("Profile | Medios - Car Rental Management System")}</title>
        </MetaTags>
        <Modal isOpen={this.state.signmodal} size="md" centered>
          <ModalHeader toggle={this.toggleSignModal} tag="h4">
            {this.props.t("Add your signature")}
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col md={12} className="d-flex flex-column align-items-center">
                <div
                  className="signature-wrapper"
                  style={{
                    width: '100%',
                    maxWidth: '450px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px',
                    margin: '0 auto'
                  }}
                >
                  {(!signatureURL ||
                    signatureURL === "" ||
                    this.state.isEditingSignature) ? (
                    <>
                      <SignaturePad
                        ref={this.signaturePadRef}
                        canvasProps={{
                          width: 430,
                          height: 200,
                          className: "signature-canvas",
                          style: {
                            width: '100%',
                            height: '100%',
                            border: '1px solidrgb(83, 81, 81)',
                            borderRadius: '2px',
                            backgroundColor: '#fff'
                          }
                        }}
                      />
                      {signatureURL && !this.state.isUploading && (
                        <Button
                          color="secondary"
                          className="mt-2"
                          onClick={() => this.setState({ isEditingSignature: false })}
                        >
                          {this.props.t("Cancel")}
                        </Button>
                      )}
                    </>
                  ) : (
                    <div className="existing-signature">
                      <img
                        src={signatureURL}
                        alt="Existing Signature"
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'contain',
                          border: '1px solid #e0e0e0',
                          borderRadius: '2px',
                          backgroundColor: '#fff'
                        }}
                      />
                      <Button
                        color="warning"
                        className="mt-2"
                        onClick={this.handleChangeSignature}
                      >
                        {this.props.t("Change Signature")}
                      </Button>
                    </div>
                  )}
                </div>
                <p className="mt-3 text-center px-3">
                  {(!signatureURL ||
                    signatureURL === "" ||
                    this.state.isEditingSignature) ?
                    this.props.t("Draw your signature in the box above. Use your mouse, touchpad, or touchscreen. Click 'Clear' to restart or 'Save' when finished.") :
                    this.props.t("Your existing signature is displayed above. Click 'Change Signature' to create a new one.")
                  }
                </p>
              </Col>
            </Row>

            <Row className="text-center mt-4">

              {(!signatureURL ||
                signatureURL === "" ||
                this.state.isEditingSignature) ?
                <Col>
                  <Button color="danger" className="mx-2" onClick={this.handleClear}>
                    {this.props.t("Clear")}
                  </Button>
                  <Button color="primary" className="mx-2" onClick={this.handleUploadSignature}>
                    {this.props.t("Save")}
                  </Button></Col>
                : null}


            </Row>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.mapmodal}
          size='md'
        >
          <ModalHeader toggle={this.toggleViewModal} tag="h4">
            {this.props.t("Backup your data")}
          </ModalHeader>
          <ModalBody>


            <h5 className="mt-1 text-center">{this.props.t("Manage your data backup locally or in the cloud.")}</h5>

            <br />
            <Row className="text-center">
              <Col >
                <h6 className="card-title-desc">
                  {this.props.t("Download all your data to your local device")}
                </h6>
              </Col>
            </Row>
            <Row className=" text-center mb-4 mt-0">
              <Col >
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={this.handleBackup}
                >
                  <i className="bx bx-download font-size-16 align-middle me-2"></i>
                  {this.props.t("Download to device")}
                </button>
              </Col >
            </Row>
            <hr />
            <Row className="text-center">
              <Col >
                <h6 className="card-title-desc">
                  {this.props.t("Keep your data safe in your Google Drive")}
                </h6>
              </Col>
            </Row>
            <Row className=" text-center mb-2 mt-0">
              <Col >
                <ConnectDrive userId={user.id} />
              </Col >
            </Row>

            <Row className="text-center">
              <p className="card-title-desc">
                {this.props.t("This is beneficial for working on your data offline and ensures you always have a reliable backup to safeguard your information.")}
              </p>
            </Row>


          </ModalBody>
        </Modal>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title={this.props.t("Medios")} breadcrumbItem={this.props.t("Profile")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row>
              <Col lg="12">
                {/* {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null} */}
                <Modal
                  isOpen={this.state.permissionModal}
                  role="alert"
                  autoFocus={true}
                  data-toggle="permissionModal"
                  centered
                  size="md"
                >
                  <ModalHeader toggle={this.togglePermission} tag="h4">     </ModalHeader>

                  <Row className="justify-content-center">
                    <Col >

                      <div className="p-2 mt-4 mb-0">
                        <div className="text-center">
                          <div className="avatar-md mx-auto">
                            <div className="avatar-title rounded-circle bg-light">
                            <i className="mdi mdi-alert-circle-outline h1 mt-2 text-primary mdi-48px"></i>
                            </div>
                          </div>
                          <div className="p-2 mt-3 mb-0">
                            <h4>{this.props.t("Upgrade Required !")}</h4>
                            <br />
                            <p>
                              {this.props.t("You have reached the limit of")} <strong>{planLimits.branches}</strong> {this.props.t("branches for your")} <strong>{planName}</strong> {this.props.t("plan.")} <br />
                              {this.props.t("Upgrade your plan to add more branches.")}
                            </p>
                            <Link  onClick={() =>
                                user.lifetimeAccess || !user.subscription?.planId
                                  ? (window.location.href = '/upgrade')
                                  : this.redirectToPortal(user.stripeId)
                              }
                            >
                             <strong> {user.lifetimeAccess || !user.subscription?.planId ? this.props.t("Upgrade Now") : this.props.t('Manage Subscription')}</strong>
                              
                            </Link>

                          </div>
                        </div>
                      </div>

                    </Col>
                  </Row>
                </Modal>
                <Modal isOpen={this.state.isUploading}>
                  <ModalBody>
                    <Col>
                      <div>
                        <div >
                          <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h6 className='text-center'>{this.props.t("Uploading signature ...")}</h6>
                    </Col>

                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.redirecting}>
                  <ModalBody>
                    <Col>
                      <div>
                        <div >
                          <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h6 className='text-center'>{this.props.t("Loading ...")}</h6>
                    </Col>

                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.dlLoading}>
                  <ModalBody>
                    <Col>
                      <div>
                        <div >
                          <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h6 className='text-center'>{this.props.t("Uploading documents logo ...")}</h6>
                    </Col>

                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.submitLoading}>
                  <ModalBody>
                    <Col>
                      <div>
                        <div >
                          <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                    </Col>

                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.branchLoading}>
                  <ModalBody>
                    <Col>
                      <div>
                        <div >
                          <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                    </Col>

                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.backingUp}>
                  <ModalBody>
                    <Col>
                      <div>
                        <div >
                          <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h6 className='text-center'>{this.props.t("Downloading backup...")}</h6>
                    </Col>

                  </ModalBody>
                </Modal>
                {this.state.isResetUser ? (
                  <SweetAlert
                    title={this.props.t("Are you sure?")}
                    warning
                    showCancel
                    confirmButtonText={this.props.t("Yes, reset password!")}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlePWSubmitUser}
                    onCancel={() =>
                      this.setState({
                        isResetUser: false,
                      })
                    }
                  >
                    {this.props.t("Reset your password!")}
                  </SweetAlert>
                ) : null}
                {this.state.isReset ? (
                  <SweetAlert
                    title={this.props.t("Are you sure?")}
                    warning
                    showCancel
                    confirmButtonText={this.props.t("Yes, reset password!")}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlePWSubmit}
                    onCancel={() =>
                      this.setState({
                        isReset: false,
                      })
                    }
                  >
                    {this.props.t("Reset your password!")}
                  </SweetAlert>
                ) : null}
                <Card>
                  <CardBody>

                    {user && user.isAdmin ?
                      <Row >
                        <Col md="2">
                          {imageLoading ?
                            <i className="bx bx-loader bx-spin bx-md" />
                            :
                            <img
                              src={av}
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail"
                              onClick={this.handleEditPicture}
                            />
                          }

                          <input
                            id="imageInput"
                            type="file"
                            accept="image/png, image/jpeg"
                            hidden={true}
                            onChange={this.handleImageChange.bind(this)}
                          />
                        </Col>
                        <Col md="6">
                          <h5>{user.company_name}</h5>
                          {/* <p className="mb-0">@{this.state.user.username}</p> */}
                          <p className="mb-0">{user.email}</p>
                          <Link to="#" onClick={this.handleEditPicture}>
                            {this.props.t("Edit profile picture")}
                          </Link>
                          {user.isAdmin ? <div>
                            <hr />
                            <h5 className='mb-0'>{planName}</h5>
                            <p className="mb-3"><Badge
                              className="font-size-12"
                              color={
                                isLifetimeAccess || (planName !== 'Free Trial' && user.subscription?.status === 'active')
                                  ? 'success'
                                  : user.subscription?.status !== 'active'
                                    ? 'warning'
                                    : 'secondary'
                              }
                              pill
                            >
                              {isLifetimeAccess || 
(planName === 'Free Trial' ? user.validTrial : user.subscription?.status === 'active')
  ? 'Active'
  : 'Inactive'
/* isLifetimeAccess || (planName !== 'Free Trial' && user.subscription?.status === 'active')
                                ? 'Active'
                                : 'Inactive' */}
                            </Badge>
                            </p>
                            <Row>
                              <Col>
                                { }
                                {!isLifetimeAccess && planName !== "Free Trial" && (
                                  <p >
                                    <strong>{this.props.t("Billing Cycle")}:</strong> {subscriptionInterval === 'annual' ? 'Yearly' : 'Monthly'}
                                  </p>
                                )}
                              </Col>
                              <Col>
                                {!isLifetimeAccess && planName !== "Free Trial" && (
                                  <p className="mb-1"><strong>{this.props.t("Next Payment")}:</strong> {formattedDate}</p>
                                )}
                              </Col>


                            </Row>

                            {/* <p className="mb-1"><strong>Invitations:</strong> {user.invitationCount}/{(planLimits.invitations)|| "unlimited"}</p>
                <p className="mb-1"><strong>Vehicles:</strong> {user.vehicleCount}/{planLimits.vehicles}</p>
                <p className="mb-1"><strong>Branches:</strong> {user.branchCount}/{planLimits.branches}</p> */}


                            <Button
                              color="primary"
                              className="upgrade-button"
                              onClick={() =>
                                user.lifetimeAccess || !user.subscription?.planId
                                  ? (window.location.href = '/upgrade')
                                  : this.redirectToPortal(user.stripeId)
                              }
                            >
                              {user.lifetimeAccess || !user.subscription?.planId ? this.props.t('Upgrade') : this.props.t('Manage Subscription')}
                            </Button>

                          </div> : null}

                        </Col>
                        {user.isAdmin ? <Col className="text-end mt-4">
                          <Button color="secondary" onClick={() => this.toggleViewModal()}>
                            <i className="bx bx-data font-size-16 align-middle me-2"></i>
                            {this.props.t("Backup your data")}
                          </Button>
                        </Col> : null}

                      </Row>
                      :

                      <Row >
                        <Col md="2">
                          {imageLoading ?
                            <i className="bx bx-loader bx-spin bx-md" />
                            :
                            <img
                              src={av}
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail"
                            /* onClick={this.handleEditPicture} */
                            />
                          }

                          {/* <input
                          id="imageInput"
                          type="file"
                          accept="image/png, image/jpeg"
                          hidden={true}
                          onChange={this.handleImageChange.bind(this)}
                        /> */}
                        </Col>
                        <Col md="6">
                          <h5>{user.first_name + " " + user.last_name}</h5>
                          {/* <p className="mb-0">@{this.state.user.username}</p> */}
                          <p className="mb-0">{user.email}</p>
                          {/* <Link to="#" onClick={this.handleEditPicture}>
                          Edit profile picture
                        </Link> */}
                        </Col>
                        <Col className="text-end mt-4">
                          {/* <Button color="light" onClick={() => this.toggleViewModal()}>
                          <i className="bx bx-map font-size-16 align-middle me-2"></i>
                          Change localisation
                        </Button> */}
                        </Col>
                      </Row>
                    }

                    {/*  <div className="me-3">
                        
                      </div>

                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          

                        </div>
                      </div>


                      
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>



            {user.isAdmin ? <>
              <h4 className="card-title mb-4">{this.props.t("Documents logo")}</h4>
              <Card>
                <CardBody>
                  <Label htmlFor="formFileLg" className="form-label ">{this.props.t("Choose Documents logo")}</Label>
                  <Row>
                    <Col md="5">

                      <Input className="form-control form-control-lg" id="formFileLg" type="file" accept="image/*" onChange={this.handleDLChange} />
                    </Col>
                    <Col md="3" className=" text-center mt-2 mb-2">
                      {this.state.documentsLogo ? <Button type="submit" color="success" onClick={this.handleEditDL}>
                        {this.props.t("Upload")}
                      </Button> : null}

                    </Col>
                    <Col md="4" className="text-center mt-2 mb-2">
                      <img src={logo_url || user.documents_logo} alt="logo-dark" className="logo-dark-element" height="60" />

                    </Col>
                  </Row>
                  <p> <>{this.props.t("This is the logo that will display in the documents (invoices, quotes and contracts)")}</></p>

                  <Row className="text-start">
                    <Col md="4"><Button color="secondary" onClick={() => this.toggleSignModal()}>
                      <i className="bx bx-pen  me-2"></i>
                      {this.props.t("Add your signature")}
                    </Button> </Col>


                  </Row>
                </CardBody>
              </Card>
            </> : null}
            {user.isAdmin ? <>
              <h4 className="card-title mb-4">{this.props.t("Branches")}</h4>
              <Card>
                <CardBody>
                  <Label htmlFor="formFileLg" className="form-label ">{this.props.t("Manage branches")}</Label>

                  <ToolkitProvider
                    keyField="id"
                    data={this.state.brchs}
                    columns={this.state.BranchColumns || []}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2" >
                          <Col sm="4">

                          </Col>

                          <Col sm="8" >
                            <div className="text-sm-end" >
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded mb-2 me-2"
                                onClick={this.handleBranchClicks}
                              >
                                <i className="mdi mdi-plus me-1" />{" "}
                                {this.props.t("New Branch")}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <div className="table-responsive">
                          <BootstrapTable
                            {...toolkitProps.baseProps}
                            defaultSorted={[
                              {
                                dataField: 'isActive', // Field to sort by
                                order: 'desc' // Sort order: 'asc' for ascending, 'desc' for descending
                              }
                            ]}
                            responsive
                            headerClasses="d-none" // Hide column headers
                            wrapperClasses="table-white" // Add custom class for white background
                            noDataIndication={this.props.t("No branches found")} // Message when no data is available
                          />
                          <Modal
                            isOpen={this.state.modalBranch}
                          /* className={this.props.className} */
                          >
                            <ModalHeader toggle={this.toggleBranch} tag="h4">
                              {!!this.state.isEdit ? this.props.t("Edit Branch") : null}
                              {!!this.state.isShow ? this.props.t("Branch infos") : null}
                              {!this.state.isEdit && !this.state.isShow ? this.props.t("Add Branch") : null}
                            </ModalHeader>
                            <ModalBody>
                              {this.state.submitLoading ?
                                <Col>
                                  <div>
                                    <div >
                                      <div className="spinner-chase">
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                </Col>
                                :
                                <AvForm
                                  onValidSubmit={
                                    this.handleValidBranchSubmit
                                  }
                                  disabled={this.state.isShow ? true : false}
                                >
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">
                                          {this.props.t("Branch name")}
                                        </Label>
                                        <AvField
                                          name="name"
                                          placeholder={this.props.t("Branch name")}
                                          type="text"
                                          errorMessage={this.props.t("Enter Branch Name")}
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          id="validationCustom01"
                                          value={this.state.br.name || ""}
                                          maxLength={25}

                                        />
                                      </FormGroup>
                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <AvField
                                          name="city"
                                          label={this.props.t("City")}
                                          type="text"
                                          placeholder={this.props.t("City")}
                                          value={this.state.br.city || ""}
                                          errorMessage={this.props.t("Enter City")}
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                        />
                                      </FormGroup>
                                    </Col>


                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <AvField
                                          name="address"
                                          label={this.props.t("Address")}
                                          type="textarea"
                                          errorMessage={this.props.t("Invalid Address")}
                                          rows="1"
                                          placeholder={this.props.t("Address")}
                                          value={this.state.br.address || ""}
                                        />
                                      </FormGroup>
                                    </Col>

                                  </Row>


                                  {this.state.isShow ? null :
                                    <Row>
                                      <Col>
                                        <div className="text-end">

                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            {this.props.t("Save")}
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  }

                                </AvForm>
                              }
                            </ModalBody>
                          </Modal>
                          {this.state.isDelete ? (
                            <SweetAlert
                              title={this.props.t("Are you sure?")}
                              warning
                              showCancel
                              confirmButtonText={this.props.t("Yes, delete it!")}
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() =>
                                this.handleDeleteBranch(this.state.db)
                              }
                              onCancel={() =>
                                this.setState({
                                  isDelete: false,
                                })
                              }
                            >
                              {this.props.t("Deleting this branch will permanently remove all associated data.")}<br />
                              {this.props.t("This action cannot be undone and may result in data loss.")}  <br />
                              {this.props.t("Are you sure you want to proceed?")}
                              {/* {this.props.t("You won't be able to revert this!")} */}
                            </SweetAlert>
                          ) : null}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                  <p> <>{this.props.t("Access and switch between branches seamlessly on your dashboard.")}</></p>



                </CardBody>
              </Card>
            </> : null}


            <h4 className="card-title mb-4">{this.props.t("Account informations")}</h4>

            <Card>
              <CardBody>
                {user.isAdmin ?
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      this.handleValidSubmit(e, v)
                    }}
                  >
                    <Row>

                      {/* <Col md="5">
                      <FormGroup className="mb-3">
                        <Label>{this.props.t("Username")}</Label>

                        <AvField
                          name="username"
                          placeholder={this.props.t("Username")}
                          type="text"
                          errorMessage={this.props.t("Enter Username")}
                          validate={{ required: { value: true } }}
                          value={this.state.user.username}
                          disabled={true}
                        />


                      </FormGroup>
                    </Col> */}
                      <Col md="5">
                        <FormGroup className="mb-3">
                          <Label >
                            {this.props.t("Email")}
                          </Label>
                          <AvField
                            name="email"
                            placeholder={this.props.t("E-mail")}
                            type="text"
                            errorMessage={this.props.t("Enter a valid E-mail")}
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            disabled={true}
                            value={this.state.user.email || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label>
                            {this.props.t("Company name")}
                          </Label>
                          <AvField
                            name="company_name"
                            placeholder={this.props.t("Company name")}
                            type="text"
                            errorMessage={this.props.t("Enter company name")}
                            className="form-control"
                            validate={{
                              required: { value: true }
                            }}
                            value={this.state.user.company_name || ""}
                          />

                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label> {this.props.t("Phone number")}</Label>
                          <AvField
                            name="phone"
                            placeholder={this.props.t("Phone Number")}
                            type="text"
                            errorMessage={this.props.t("Enter a valid phone number")}
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: '^(0|\\+)\\d{9,14}$',
                                errorMessage: this.props.t("Enter a valid phone number")
                              },
                            }}
                            disabled={true}
                            value={this.state.user.phone || ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>


                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <AvField
                            name="address"
                            label={this.props.t("Address")}
                            type="textarea"
                            errorMessage={this.props.t("Invalid Address")}
                            rows="1"
                            placeholder={this.props.t("Address")}
                            value={this.state.user.address || this.state.userAddress || ""}
                          />
                        </FormGroup>
                      </Col>

                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <AvField
                            name="city"
                            label={this.props.t("City")}
                            type="text"
                            errorMessage="Invalid City"
                            placeholder={this.props.t("City")}
                            value={this.state.user.city || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <AvField
                            name="state"
                            label={this.props.t("Region")}
                            type="text"

                            placeholder={this.props.t("region")}
                            value={this.state.user.state || ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="rc"
                            label={this.props.t("Trade register number")}
                            type="text"
                            placeholder={this.props.t("Trade register number")}
                            value={this.state.user.rc || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="patente"
                            label={this.props.t("License")}
                            type="text"
                            placeholder={this.props.t("License")}
                            value={this.state.user.patente || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="id_fiscal"
                            label={this.props.t("Tax identification number")}
                            type="text"
                            placeholder={this.props.t("Tax identification number")}
                            value={this.state.user.id_fiscal || ""}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="ice"
                            label={this.props.t("ICE")}
                            type="text"
                            placeholder={this.props.t("N ICE")}
                            value={this.state.user.ice || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="tp"
                            label={this.props.t("Business tax")}
                            type="text"
                            placeholder={this.props.t("N T.P")}
                            value={this.state.user.tp || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <AvField
                            name="website"
                            label={this.props.t("Website")}
                            type="text"
                            placeholder={this.props.t("Website")}
                            value={this.state.user.website || ""}
                          />
                        </FormGroup>
                      </Col>


                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <AvField
                            name="description"
                            label={this.props.t("Description")}
                            type="textarea"
                            rows="2"
                            placeholder={this.props.t("Description")}
                            value={this.state.user.description || ""}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row >
                      <Col className="text-start mt-4">
                        <Button color="danger" onClick={() => this.setState({ isReset: true })}>
                          {this.props.t("Change password")}
                        </Button>
                      </Col>
                      {user.isAdmin ?
                        <Col className="text-center mt-4">
                          {/* <Button color="info" onClick={this.handleBackup}>
                            {this.props.t("backup")}
                          </Button> */}
                          {/* <ConnectDrive userId={user.id} /> */}
                        </Col> : null}
                      <Col className="text-end mt-4">
                        <Button type="submit" color="success">
                          {this.props.t("Update Profile")}
                        </Button>
                        {/* <Link to={"/agency/"+this.state.user.username} color="success">
                        test
                      </Link> */}
                      </Col>

                    </Row>
                  </AvForm>
                  :

                  <AvForm
                    className="form-horizontal"
                  /* onValidSubmit={(e, v) => {
                    this.handleValidSubmitUser(e, v)
                  }} */
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label >
                            {this.props.t("Email")}
                          </Label>
                          <AvField
                            name="email"
                            placeholder={this.props.t("E-mail")}
                            type="text"
                            errorMessage={this.props.t("Enter a valid E-mail")}
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            disabled={true}
                            value={user.email || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label>{this.props.t("First name")}</Label>

                          <AvField
                            name="first_name"
                            placeholder={this.props.t("First name")}
                            type="text"
                            errorMessage={this.props.t("Enter first name")}
                            validate={{ required: { value: true } }}
                            value={user.first_name}
                            disabled={true}
                          />


                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label>{this.props.t("Last name")}</Label>

                          <AvField
                            name="last_name"
                            placeholder={this.props.t("Last name")}
                            type="text"
                            errorMessage={this.props.t("Enter last name")}
                            validate={{ required: { value: true } }}
                            value={user.last_name}
                            disabled={true}
                          />


                        </FormGroup>
                      </Col>
                    </Row>


                    <Row >
                      <Col className="text-start mt-4">
                        <Button color="danger" onClick={() => this.setState({ isResetUser: true })}>
                          {this.props.t("Change password")}
                        </Button>
                      </Col>


                    </Row>
                  </AvForm>

                }

              </CardBody>
            </Card>
            {/* <h4 className="card-title mb-4">{this.props.t("Backup your data")}</h4>
            <Card>
                  <CardBody>
                  <h5 className="card-title-desc">
                      Manage your data backup locally or in the cloud.
                    </h5>
                    <li className="label">
                                              Donec sodales sagittis
                                            </li>
                  </CardBody>
                  </Card> */}
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  t: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
  google: PropTypes.object
}

const mapStateToProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(
    withTranslation()(UserProfile)
  )
)

