import React, { Component } from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
import { Link } from "react-router-dom"

import PropTypes from 'prop-types'
import { withTranslation } from "react-i18next"
import * as moment from 'moment';

class TopCities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      res: [],
      veh: [],
    }
  }
  componentDidMount() {

    this.getReservation()
    this.getVehicle()
  }
  getReservation = async () => {
    this.setState({ loading: true });
    const branch = JSON.parse(localStorage.getItem("branch"));
  
    if (!branch) {
     //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
    
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations").where("branchId","==",branch)

            this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
              let reservations = [];
              snapshot.docs.forEach(doc => {
                reservations.push(doc.data());
              });

              this.setState({
                loading: false,
                res: reservations
              });
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              res: []
            });
          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          res: []
        });
      }
    });
  }
  getVehicle = async () => {
    this.setState({ loading: true });
    const branch = JSON.parse(localStorage.getItem("branch"));
  
    if (!branch) {
     //toast.error("Error: Branch not found");
      this.setState({ loading: false });
      return;
    }
    
    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeVehicles) {
      this.unsubscribeVehicles();
    }

    this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles").where("branchId","==",branch)

            this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
              let vehicles = [];
              snapshot.docs.forEach(doc => {
                vehicles.push(doc.data());
              });

              this.setState({
                loading: false,
                veh: vehicles
              });
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              veh: []
            });
          }
        } catch (error) {
         //console..error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          veh: []
        });
      }
    });
  }
  render() {
    const { res, veh } = this.state
    var yearts = moment(this.props.year , 'YYYY').valueOf()
    //console.log(yearts)


    var k = []
    //console.log(res.length)
    veh.map(function (v) {
      var nd = 0
      res.map(function (re) {
        if (moment(yearts).isSame(moment(re.pd), 'year')) {
          if (re.v_id === v.id) {
            nd = nd + re.days
            //console.log(re.v_id, nd)
          }
        }

      })
      k.push({ p: v.plate_number, make: v.make, model: v.model, days: nd })
    })
    //console.log(k)
    k.sort(function (a, b) {
      return b.days - a.days;
    });
    var all = 0
    var top = {}


    k.map(function (e, i) {
      all = all + e.days
      if (i == 0) {
        top = {
          p: e.p,
          make: e.make,
          model: e.model,
          days: e.days,

        }
      }

    });
    //console.log(k)
   //console..log(top.days)
    var size = 5;
    var k = k.slice(0, size)

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">{this.props.t("Top Rented Vehicles")}</CardTitle>
            {top.days == undefined || top.days===0 ? <div className="text-center">
            <div className="mb-4">
                <i className="bx bx-car text-primary display-4" style={{ lineHeight: '1' }} />
              </div>
              <h4>{this.props.t("No Data")}</h4>
              <p  >{this.props.t("Top-performing vehicles are displayed here.")}</p> 
              {/* <Link to="/vehicles" >{this.props.t("Add new Vehicle")}</Link> */}
            </div> : <div className="text-center">
              <div className="mb-4">
                <i className="bx bx-car text-primary display-4" style={{ lineHeight: '1' }} />
              </div>
              <h4>{top.make + " " + top.model + " ( " + top.p + " )"}</h4>
              <p>{top.days} {this.props.t("Days")}</p>
            </div>}

            <div className="table-responsive mt-4">
              <table className="table align-middle table-nowrap">
                <tbody>
                  {k.map((item, i) => (
                    <tr key={i}>

                      <td style={{ width: "30%" }}>
                        <p className="mb-0">{item.make + " : " + item.p + " "}</p>
                      </td>
                      <td style={{ width: "25%" }}>
                        <h5 className="mb-0">{item.days}</h5>
                      </td>
                      <td>
                        <Progress
                          value={(item.days * 100) / all}
                          color={i == 0 ? "success" : "primary"}
                          className="progress bg-transparent progress-sm"
                          size="sm"
                        />
                      </td>
                    </tr>
                  ))}


                </tbody>
              </table>
            </div>
            <div className="text-center ">
              <Link
                to="/vehicles"
                className="btn btn-primary btn-sm"
              >
               {this.props.t("Vehicles")}  <i className="mdi mdi-arrow-right ms-1" />
              </Link>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
TopCities.propTypes = {
  year: PropTypes.any,
  t: PropTypes.any
}
export default withTranslation()(TopCities);
